import gql from 'graphql-tag';
import {
  CUSTOM_FIELD_FRAGMENT,
  CUSTOM_FIELD_FRAGMENT_NAME
} from '../../Fragments/CustomFieldsFragment';

export const TENANT_FIELDS_FRAGMENT_NAME = 'TenantFields';
export const TENANT_FIELDS_FRAGMENT = gql`
    ${CUSTOM_FIELD_FRAGMENT}

  fragment ${TENANT_FIELDS_FRAGMENT_NAME} on Tenant {
    id
    key
    displayName
    environmentKey
    subjectName
    locationName
    showRoles
    avatarIsEnabled
    features {
      featureKey,
      isEnabled
    }
    customFields {
      primaryField {
        field {
          ...${CUSTOM_FIELD_FRAGMENT_NAME}
        }
        positionIndex
      }
      groups {
        key
        name
        showByDefault
        bannerPositionIndex
        customFields {
          field {
            ...${CUSTOM_FIELD_FRAGMENT_NAME}
          }
          positionIndex
        }
      }
      hiddenFields {
        field {
          ...${CUSTOM_FIELD_FRAGMENT_NAME}
        }
        positionIndex
      }
    }
    subjectNotLinkedErrorMessage
  }
`;
