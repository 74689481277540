import gql from 'graphql-tag';
import { DataSourceConfigurationOption } from './DataSource';
import { NodesConnection } from '../Paging';

export interface AddableDataSource {
  __typename: 'BundledDataSource' | 'CustomDataSource';
  key: string;
  configurationOptions: Array<DataSourceConfigurationOption> | null;
}

export interface DataSourcesQueryVariables {
  onlyAddable: boolean;
}

export interface DataSourcesQueryData {
  tenant: {
    __typename: string;
    id: string;
    dataSources: NodesConnection<AddableDataSource, 'AddableDataSources'>;
  };
}

export const DATA_SOURCES_QUERY_NAME = 'AddableDataSources';
export const ADDABLE_DATA_SOURCES_QUERY = gql`
query ${DATA_SOURCES_QUERY_NAME}($onlyAddable: Boolean!)  {
  tenant {
    id
    dataSources(onlyAddable: $onlyAddable) {
      nodes {
        key

        configurationOptions {
          key 
          isRequired
          isSensitive
        }
      }
    }
  }
}`;
