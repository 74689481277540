import { gql } from '@apollo/client';

export type ConfigImportedSubscriptionData = {
  configImported: {
    id: string;
    configImported: boolean;
    message: string;
  };
};

export const CONFIG_IMPORTED_SUBSCRIPTION = gql`
  subscription ConfigImported {
    configImported {
      id
      configImported
      message
    }
  }
`;
