import { gql } from '@apollo/client';

export const DATA_TYPE_VALUE_FRAGMENT_NAME = 'DataTypeValue';

export const DATA_TYPE_VALUE_FRAGMENT = gql`
  fragment ${DATA_TYPE_VALUE_FRAGMENT_NAME} on IDataTypeValue {
    dataType
    ... on StringDataTypeValue {
        stringValue: value
    }
    ... on IntegerDataTypeValue {
        integerValue: value
    }
    ... on DecimalDataTypeValue {
        decimalValue: value
    }
    ... on BooleanDataTypeValue {
        booleanValue: value
    }
    ... on DateTimeDataTypeValue {
        dateTimeValue: value
    }
    ... on DateDataTypeValue {
        dateValue: value
    }
    ... on TimeDataTypeValue {
        timeValue: value
    }
    ...on UserIdentifierDataTypeValue {
        userIdentifier: value {
            identifier
            userIdentifierType
        }
    }
  }
`;
