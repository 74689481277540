import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Popover,
  PopoverOrigin,
  PopoverProps,
  Typography,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

interface Props {
  onClose: () => void;
  title: string;
  showTitle?: boolean;
  children?: React.ReactNode;
  transformOrigin?: PopoverOrigin;
  popoverProps?: Omit<PopoverProps, 'open' | 'onClose'>;
}

const PopupMenu: React.FC<Props> = ({
  onClose,
  title,
  children,
  popoverProps,
  showTitle = true
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  if (isSmallScreen) {
    return (
      <Dialog open onClose={onClose} aria-labelledby="form-dialog-title" data-testid="dialog">
        {showTitle && <DialogTitle id="form-dialog-title">{title}</DialogTitle>}
        <DialogContent aria-label={`${title} Dialog`}>{children}</DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else {
    return (
      <Popover open onClose={onClose} data-testid={`${title} popover`} {...popoverProps}>
        <Grid container direction="column">
          {showTitle && (
            <Grid item xs={12}>
              <Typography style={{ textAlign: 'center', fontWeight: 'bold' }} sx={{ pt: 2, px: 2 }}>
                {title}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            {children}
          </Grid>
        </Grid>
      </Popover>
    );
  }
};

export default PopupMenu;
