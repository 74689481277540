import { ApiConfigurationResult } from '@aireframe/shared-types';

export const AppService = {
  getClientConfiguration: async (): Promise<ApiConfigurationResult> => {
    const response = await fetch(`/bff/clientconfiguration`, {
      method: 'GET',
      credentials: 'omit'
    });
    return (await response.json()) as ApiConfigurationResult;
  }
};
