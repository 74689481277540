import { useState } from 'react';
import { VisualisationRenderType } from '@aireframe/shared-types';
import { gql, useSubscription } from '@apollo/client';
import { WidgetContextInput } from '../Widgets/Types';

type VisualisationPendingUpdateSubscriptionVariables = {
  visualisationId: string;
  context: WidgetContextInput;
};

export type visualisationPendingUpdate = {
  isPending: boolean;
};

type VisualisationPendingUpdateSubscriptionData = {
  visualisationPendingUpdate: visualisationPendingUpdate;
};

const VISUALISATION_PENDING_UPDATE_SUBSCRIPTION = gql`
  subscription VisualisationPendingUpdate($visualisationId: ID!, $context: WidgetContextInput!) {
    visualisationPendingUpdate(visualisationId: $visualisationId, context: $context) {
      isPending
    }
  }
`;

export const useVisualisationPendingUpdateSubscription = (
  visualisation: VisualisationRenderType,
  widgetContext: WidgetContextInput
) => {
  const [updatePending, setUpdatePending] = useState(false);

  useSubscription<
    VisualisationPendingUpdateSubscriptionData,
    VisualisationPendingUpdateSubscriptionVariables
  >(VISUALISATION_PENDING_UPDATE_SUBSCRIPTION, {
    onData: ({ data: { data } }) => {
      if (data?.visualisationPendingUpdate.isPending) {
        setUpdatePending(true);
      }
    },
    variables: {
      visualisationId: visualisation.id,
      context: widgetContext
    }
  });

  return { updatePending, setUpdatePending };
};
