import { VisualisationRenderType, VisualisationType } from '@aireframe/shared-types';
import { SortingOption } from '../../../Pagination';
import { isSeriesSortable } from '../Utils';

export const getSortingOptions = (
  definition: Pick<VisualisationRenderType, 'type' | 'series' | 'defaultOrdering'>
): Array<SortingOption> => {
  if (definition.type === VisualisationType.GRAPH) {
    return [
      {
        key: definition.defaultOrdering.key,
        displayName:
          definition.series.find(x => x.key === definition.defaultOrdering.key)?.renderedName ||
          'Default'
      }
    ];
  }

  return getVisualisationSortingOptions(definition);
};

export const getVisualisationSortingOptions = (
  definition: Pick<VisualisationRenderType, 'series'>
): Array<SortingOption> => {
  return definition.series.filter(isSeriesSortable).map(series => ({
    key: series.key,
    displayName: series.renderedName
  }));
};
