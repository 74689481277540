import {
  GraphChartDisplayOptions,
  LiquidDisplayOptions,
  PieChartDisplayOptions,
  TableDisplayOptions,
  Visualisation
} from '@aireframe/shared-types';
import { gql } from '@apollo/client';
import { Dashboard } from '../Dashboard';
import { DataPointFilterInput } from '../DataPoint';
import { VISUALISATION_FRAGMENT, VISUALISATION_FRAGMENT_NAME } from '../Fragments';

export type CreateVisualisationMutationVars = {
  input: Omit<
    Visualisation,
    '__typename' | 'id' | 'displayOptions' | 'defaultFilter' | 'referencedContexts'
  > & {
    displayOptions: {
      tableDisplayOptions?: Omit<TableDisplayOptions, '__typename'> | null;
      liquidDisplayOptions?: Omit<LiquidDisplayOptions, '__typename'> | null;
      pieChartDisplayOptions?: Omit<PieChartDisplayOptions, '__typename'> | null;
      graphChartDisplayOptions?: Omit<GraphChartDisplayOptions, '__typename'> | null;
    } | null;
    defaultFilter: DataPointFilterInput | null;
  };
};

export type CreateVisualisationMutationData = {
  createVisualisation: Pick<Visualisation, '__typename' | 'id' | 'key' | 'title'>;
};

export const CREATE_VISUALISATION_MUTATION_NAME = 'CreateVisualisation';
export const CREATE_VISUALISATION_MUTATION = gql`
    mutation ${CREATE_VISUALISATION_MUTATION_NAME}($input: VisualisationInput!) {
        createVisualisation(input: $input) {
            id
            key
            title
        }
    }
`;

export type UpdateVisualisationMutationVars = CreateVisualisationMutationVars & {
  id: string;
};

export type UpdateVisualisationMutationData = {
  updateVisualisation: Dashboard['items'][number]['widget'];
};

export const UPDATE_VISUALISATION_MUTATION_NAME = 'UpdateVisualisation';
export const UPDATE_VISUALISATION_MUTATION = gql`
  ${VISUALISATION_FRAGMENT}
  mutation ${UPDATE_VISUALISATION_MUTATION_NAME}($id: ID!, $input: VisualisationInput!) {
      updateVisualisation(visualisationId: $id, input: $input) {
        ...${VISUALISATION_FRAGMENT_NAME}
      }
  }
`;
