import FilterListIcon from '@mui/icons-material/FilterAlt';
import { Chip, IconButton, IconButtonProps, Tooltip } from '@mui/material';
import React, { AriaAttributes, CSSProperties } from 'react';

const OpenFilterButton = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => (
  <Tooltip title={props['aria-label'] ?? 'Filter'}>
    <IconButton aria-label="Filter" ref={ref} {...props} size="small">
      <FilterListIcon />
    </IconButton>
  </Tooltip>
));
OpenFilterButton.displayName = 'OpenFilterButton';

const FilterIconButton = React.forwardRef<
  HTMLButtonElement,
  { filterActive: boolean; style?: CSSProperties; onClick: () => void } & AriaAttributes
>(({ filterActive, style, onClick, ...ariaProps }, ref) => {
  if (!filterActive) {
    return (
      <OpenFilterButton {...ariaProps} color="default" style={style} onClick={onClick} ref={ref} />
    );
  }

  return (
    <Chip
      icon={
        <OpenFilterButton
          {...ariaProps}
          sx={{ color: t => t.palette.primary.contrastText }}
          ref={ref}
        />
      }
      label="Filter Active"
      clickable
      onClick={() => onClick()}
      color="primary"
      style={style}
      sx={{ fontWeight: t => t.typography.fontWeightBold }}
    />
  );
});

FilterIconButton.displayName = 'FilterIconButton';
export { FilterIconButton };
