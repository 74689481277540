import gql from 'graphql-tag';
import Subject from '../Subject';

interface CreateSubjectInput {
  customFields: { fieldKey: string; value: string | null }[];
  structuralEntityKeys: string[];
}

export interface AddSubjectMutationVars {
  subject: CreateSubjectInput;
}

export interface AddSubjectMutationData {
  createSubject: Pick<
    Subject,
    '__typename' | 'id' | 'customFieldValues' | 'subjectAccessInviteStatus'
  >;
}

export const CREATE_SUBJECT_MUTATION_NAME = 'CreateSubject';
export const CREATE_SUBJECT_MUTATION = gql`
  mutation ${CREATE_SUBJECT_MUTATION_NAME}($subject: CreateSubjectInput!) {
    createSubject(input: $subject) {
      id
      customFieldValues {
        customField {
          id
          key
          name
        }
        value
      },
      subjectAccessInviteStatus {
        flag,
        inviteUrl
      }
    }
  }
`;
