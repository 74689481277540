import { FieldType, InputField, MultipleChoiceInputFieldType } from '@aireframe/graphql';
import { assertUnreachable, UserIdentifierType } from '@aireframe/shared-types';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { isPossibleNumber } from 'libphonenumber-js';
import * as zod from 'zod';

dayjs.extend(customParseFormat);

const VALID_DATE_FORMATS = ['YYYY-MM-DD'];
const VALID_TIME_FORMATS = ['HH:mm', 'HH:mmZ', 'HH:mm[Z]', 'HH:mm:ss', 'HH:mm:ssZ', 'HH:mm:ss[Z]'];
const VALID_DATETIME_FORMATS = [
  'YYYY-MM-DDTHH:mm:ss',
  'YYYY-MM-DDTHH:mm:ss[Z]',
  'YYYY-MM-DDTHH:mm:ssZ[Z]',
  'YYYY-MM-DDTHH:mm'
];

const dateFieldZodSchema = zod
  .string()
  .trim()
  .min(1, { message: 'Required' })
  .refine(
    (value): boolean => VALID_DATE_FORMATS.some(format => dayjs(value, format, true).isValid()),
    {
      message: 'Invalid date format'
    }
  );

const timeFieldZodSchema = zod
  .string()
  .trim()
  .min(1, { message: 'Required' })
  .refine(
    (value): boolean => VALID_TIME_FORMATS.some(format => dayjs(value, format, true).isValid()),
    {
      message: 'Invalid time format'
    }
  );

const dateTimeFieldZodSchema = zod
  .string()
  .trim()
  .min(1, { message: 'Required' })
  .refine(
    (value): boolean => VALID_DATETIME_FORMATS.some(format => dayjs(value, format, true).isValid()),
    { message: 'Invalid date time format' }
  );

const phoneNumberFieldZodSchema = zod
  .string()
  .trim()
  .min(1, { message: 'Required' })
  .refine((value: string): boolean => !/\s/.test(value) && isPossibleNumber(value), {
    message: 'Invalid phone number'
  });

const nonRequiredType = zod.string().max(0).nullable();

export function getInputFieldZodSchema(field: Omit<InputField, '__typename' | 'key' | 'name'>) {
  switch (field.type.key) {
    case FieldType.TEXT:
      return zod.union([
        zod.string().trim().min(1, { message: 'Required' }),
        field.required ? zod.never() : zod.string().trim().max(0).nullable()
      ]);
    case FieldType.NUMBER:
      return zod.union([
        zod
          .string()
          .trim()
          .min(1, { message: 'Required' })
          .refine(value => !isNaN(Number(value)), { message: 'Invalid number' }),
        field.required ? zod.never() : nonRequiredType
      ]);
    case FieldType.DATE:
      return zod.union([dateFieldZodSchema, field.required ? zod.never() : nonRequiredType]);
    case FieldType.DATETIME:
      return zod.union([dateTimeFieldZodSchema, field.required ? zod.never() : nonRequiredType]);
    case FieldType.TIME:
      return zod.union([timeFieldZodSchema, field.required ? zod.never() : nonRequiredType]);
    case FieldType.EMAIL:
      return zod.union([
        zod.string().email({ message: 'Invalid Email' }),
        field.required ? zod.never() : nonRequiredType
      ]);
    case FieldType.PHONE_NUMBER:
      return zod.union([phoneNumberFieldZodSchema, field.required ? zod.never() : nonRequiredType]);
    case FieldType.SEX:
    case FieldType.CONSTRAINED_VALUES: {
      const validator = zod
        .string()
        .refine(value => (field.type as MultipleChoiceInputFieldType).options.includes(value), {
          message: 'Invalid value'
        });
      return zod.union([validator, field.required ? zod.never() : nonRequiredType]);
    }
    case FieldType.USER_IDENTIFIER: {
      const validator = zod.object({
        identifier: zod.string().trim().min(1),
        userIdentifierType: zod.nativeEnum(UserIdentifierType)
      });
      return zod.union([validator, field.required ? zod.never() : zod.null()]);
    }
    default:
      return assertUnreachable(field.type);
  }
}

export const buildInputFieldsSchema = (
  fields: Array<Omit<InputField, '__typename'>>
): Zod.ZodSchema => {
  let zodSchema = zod.object({});

  fields.forEach(field => {
    zodSchema = zodSchema.merge(zod.object({ [field.key]: getInputFieldZodSchema(field) }));
  });

  return zodSchema;
};

export type CustomFieldFormValue = zod.infer<ReturnType<typeof getInputFieldZodSchema>>;
export type CustomFieldFormValues = {
  [key: string]: CustomFieldFormValue;
};
