import gql from 'graphql-tag';
import { Group } from '@aireframe/shared-types';
import { NodesConnection } from '../Paging';

export interface UpdateUserGroupsMutationVars {
  input: UpdateUserGroupsInput;
}

export interface UpdateUserGroupsMutationResponse {
  updateUserGroups: {
    id: string;
    groups: NodesConnection<Group>;
  };
}

export interface UpdateUserGroupsInput {
  userId: string;
  groupKeysToAdd: string[];
  groupKeysToRemove: string[];
}

export const UPDATE_USER_GROUPS_MUTATION = gql`
  mutation UpdateUserGroups($input: UpdateUserGroupsInput!) {
    updateUserGroups(input: $input) {
      id
      groups {
        nodes {
          key
          name
        }
      }
    }
  }
`;
