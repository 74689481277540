import { AppBar, styled } from '@mui/material';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  color: theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.secondary.light,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));

export const Grow = styled('div')(() => ({
  flexGrow: 1
}));

export const Central = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'row',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-40%, -50%)'
}));
