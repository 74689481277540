import { Visualisation } from '@aireframe/shared-types';
import gql from 'graphql-tag';
import { DataPoint, DataPointFilterInput } from '../DataPoint';
import { DATA_POINT_FIELDS_FRAGMENT, DATA_POINT_FIELDS_FRAGMENT_NAME } from '../Fragments';
import { WidgetContextInput } from '../Widgets';
import { EdgesConnection, NodesConnection, OrderableConnectionQueryVars } from '../Paging';
import { ListSortDirection } from '../../api.gen';

export type VisualisationDataPointsQueryVars = OrderableConnectionQueryVars & {
  dataVisualisationId: string;
  getDataPointsInput: {
    context: WidgetContextInput;
    filter?: DataPointFilterInput | null;
    after?: string | null;
    first?: number | null;
    before?: string | null;
    last?: number | null;
    orderBy?: string | null;
    orderDirection?: ListSortDirection | null;
  };
};

export type VisualisationDataPointEdgesQueryData = {
  visualisation: Pick<Visualisation, '__typename' | 'id'> & {
    dataPoints: {
      dataPoints: EdgesConnection<DataPoint, 'VisualisationDataPoints', DataPoint['__typename']>;
      updatePending: boolean;
      lastPossibleUpdate: string;
    };
  };
};

export type VisualisationDataPointNodesQueryData = {
  visualisation: Pick<Visualisation, '__typename' | 'id'> & {
    dataPoints: {
      dataPoints: NodesConnection<DataPoint, DataPoint['__typename']>;
      updatePending: boolean;
      lastPossibleUpdate: string;
    };
  };
};

export const VISUALISATION_DATA_POINT_EDGES_QUERY_NAME = 'VisualisationDataPointEdges';
export const VISUALISATION_DATA_POINT_EDGES_QUERY = gql`
  ${DATA_POINT_FIELDS_FRAGMENT}
  query ${VISUALISATION_DATA_POINT_EDGES_QUERY_NAME}(
    $dataVisualisationId: ID!
    $getDataPointsInput: GetDataPointsInput!
  ) {
    visualisation(id: $dataVisualisationId) {
      id
      dataPoints(input: $getDataPointsInput) {
        updatePending
        lastPossibleUpdate
        dataPoints {
          edges {
            node {
              ...${DATA_POINT_FIELDS_FRAGMENT_NAME}
            }
          }
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
`;

export const VISUALISATION_DATA_POINT_NODES_QUERY_NAME = 'VisualisationDataPointNodes';
export const VISUALISATION_DATA_POINT_NODES_QUERY = gql`
  ${DATA_POINT_FIELDS_FRAGMENT}
  query ${VISUALISATION_DATA_POINT_NODES_QUERY_NAME}(
    $dataVisualisationId: ID!
    $getDataPointsInput: GetDataPointsInput!
  ) {
    visualisation(id: $dataVisualisationId) {
      id
      dataPoints(input: $getDataPointsInput) {
        updatePending
        lastPossibleUpdate
        dataPoints {
          nodes {
            ...${DATA_POINT_FIELDS_FRAGMENT_NAME}
          }
          totalCount
        }
      }
    }
  }
`;
