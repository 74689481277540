import { useEffect, useState } from 'react';
import MonacoEditor, { loader, useMonaco, OnChange } from '@monaco-editor/react';
import * as monaco from 'monaco-editor';
import editorWorker from 'monaco-editor/esm/vs/editor/editor.worker?worker';
import jsonWorker from 'monaco-editor/esm/vs/language/json/json.worker?worker';

window.MonacoEnvironment = {
  getWorker(_: string, label: string) {
    if (label === 'json') {
      return new jsonWorker();
    }
    return new editorWorker();
  }
};

loader.config({ monaco });
loader.init();

export const JsonEditor: React.FC<{
  id?: string;
  onChange?: OnChange;
  value: string;
  schema?: object;
  disabled?: boolean;
  'data-testid'?: string;
  height?: string;
}> = ({
  id,
  schema,
  onChange,
  value,
  disabled = false,
  'data-testid': dataTestId,
  height = '500px'
}) => {
  const monaco = useMonaco();
  const [monacoEditor, setMonacoEditor] = useState<monaco.editor.IStandaloneCodeEditor | null>(
    null
  );

  const onKeyDown = (e: monaco.IKeyboardEvent) => {
    if (e.code === 'Quote' && monacoEditor) {
      monacoEditor.trigger('', 'editor.action.triggerSuggest', {});
    }
  };

  useEffect(() => {
    if (schema && monacoEditor && monaco) {
      const modelUri = monaco.Uri.parse(`structuredData://extractJson/${id}`);
      monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
        validate: true,
        schemas: [
          {
            uri: `http://server/structuredDataSchema/${id}`,
            fileMatch: [modelUri.toString()],
            schema
          }
        ]
      });

      let model = monaco.editor.getModel(modelUri);
      if (!model) {
        model = monaco.editor.createModel('', 'json', modelUri);
      }
      monacoEditor.setModel(model);
    }
  }, [schema, id, monaco, monacoEditor]);

  return (
    <MonacoEditor
      height={height}
      language="json"
      onChange={onChange}
      value={value}
      data-testid={dataTestId}
      options={{
        minimap: {
          enabled: false
        },
        readOnly: disabled
      }}
      onMount={editor => {
        if (!value) {
          setMonacoEditor(editor);
          editor.onKeyDown(onKeyDown);
        }
      }}
    />
  );
};
