import { CustomFields, SubjectCustomField, SubjectInputField } from '@aireframe/graphql';
import { DictionaryOfArrays, DictionaryOfArraysKeys } from '@aireframe/shared-types';
import { orderBy } from 'lodash-es';

type CustomFieldsByDisplayDictionaryType<
  ExtraCustomFieldProperties extends object = Record<string, never>
> = {
  primaryField: Array<SubjectCustomField<ExtraCustomFieldProperties>>;
  groupedFields: Array<SubjectCustomField<ExtraCustomFieldProperties>>;
  hiddenFields: Array<SubjectInputField<ExtraCustomFieldProperties>>;
};

export type CustomFieldsByDisplayTypeKey<
  ExtraCustomFieldProperties extends object = Record<string, never>
> = DictionaryOfArraysKeys<CustomFieldsByDisplayDictionaryType<ExtraCustomFieldProperties>>;

export class CustomFieldsByDisplayType<
  ExtraCustomFieldProperties extends object = Record<string, never>
> extends DictionaryOfArrays<
  SubjectCustomField<ExtraCustomFieldProperties>,
  CustomFieldsByDisplayDictionaryType<ExtraCustomFieldProperties>
> {
  constructor(
    primaryField:
      | CustomFieldsByDisplayDictionaryType<ExtraCustomFieldProperties>['primaryField'][0]
      | undefined = undefined,
    groupedFields: CustomFieldsByDisplayDictionaryType<ExtraCustomFieldProperties>['groupedFields'] = [],
    hiddenFields: CustomFieldsByDisplayDictionaryType<ExtraCustomFieldProperties>['hiddenFields'] = []
  ) {
    super({
      primaryField: primaryField ? [primaryField] : [],
      groupedFields,
      hiddenFields
    });
  }
}

export function CreateCustomFieldsByDisplayType<
  ExtraCustomFieldProperties extends object = Record<string, never>
>(
  tenantCustomFields: CustomFields<ExtraCustomFieldProperties>
): CustomFieldsByDisplayType<ExtraCustomFieldProperties> {
  return new CustomFieldsByDisplayType<ExtraCustomFieldProperties>(
    tenantCustomFields.primaryField?.field,
    tenantCustomFields.groups.flatMap(group =>
      orderBy(group.customFields, 'positionIndex', 'asc').map(cf => cf.field)
    ),
    tenantCustomFields.hiddenFields.map(hf => hf.field)
  );
}
