import { SubjectBannerField, SubjectInputField } from '../CustomField';

export const TenantCustomFieldGroupTypename = 'TenantCustomFieldGroup';
export type TenantCustomFieldGroupBase = {
  __typename: typeof TenantCustomFieldGroupTypename;
  name: string;
  key: string;
  bannerPositionIndex: number;
  showByDefault: boolean;
};

export type TenantCustomFieldGroup<
  ExtraCustomFieldProperties extends object = Record<string, never>
> = TenantCustomFieldGroupBase & {
  customFields: Array<
    Omit<SubjectBannerField<ExtraCustomFieldProperties>, 'positionIndex'> & {
      positionIndex: number;
    }
  >;
};

export type CustomFields<ExtraCustomFieldProperties extends object = Record<string, never>> = {
  primaryField: SubjectBannerField<ExtraCustomFieldProperties> | null;
  groups: Array<TenantCustomFieldGroup<ExtraCustomFieldProperties>>;
  hiddenFields: Array<
    Omit<SubjectBannerField, 'field'> & { field: SubjectInputField<ExtraCustomFieldProperties> }
  >;
};

export const getAllCustomFields = (customFields?: CustomFields): Array<SubjectBannerField> => {
  if (!customFields) {
    return [];
  }

  const primaryField = customFields.primaryField;
  const fields = primaryField ? [primaryField] : [];

  return [
    ...fields,
    ...customFields.groups.flatMap(group => group.customFields),
    ...customFields.hiddenFields
  ];
};
