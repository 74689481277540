import {
  AppContextProvider,
  AuthenticationContextProvider,
  ErrorBoundary,
  useAppContext,
  useAuthentication
} from '@aireframe/components';
import { RouterProvider } from '@tanstack/react-router';
import React from 'react';
import { router } from './router';

const InnerWithAuthenticationContext = () => {
  const auth = useAuthentication();
  return <RouterProvider router={router} context={{ auth }} />;
};

const InnerWithAppContext: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { tenantKey, clientRootHost } = useAppContext();

  return (
    <AuthenticationContextProvider
      settings={{
        clientHost: clientRootHost,
        extraQueryParams: tenantKey ? { tenant: tenantKey.toLowerCase() } : {}
      }}>
      {children}
    </AuthenticationContextProvider>
  );
};

export const App = () => (
  <AppContextProvider>
    <ErrorBoundary
      context="Root"
      message="Sorry, something went wrong. We have been notified and will fix as soon as possible.">
      <InnerWithAppContext>
        <InnerWithAuthenticationContext />
      </InnerWithAppContext>
    </ErrorBoundary>
  </AppContextProvider>
);
