import { useMemo, useEffect } from 'react';
import { QueryResult, useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash-es';
import {
  USER_IDENTIFIER_SEARCH_QUERY,
  UserIdentifierSearchQueryVars,
  UserIdentifierSearchResponse,
  UserFilterInput
} from '@aireframe/graphql';
import { UserIdentifier, UserIdentifierType } from '@aireframe/shared-types';

type DebouncedGetUsers = (
  searchTerm: string
) => Promise<QueryResult<UserIdentifierSearchResponse, UserIdentifierSearchQueryVars>> | undefined;

export type SearchOptions = Omit<UserFilterInput, 'searchTerm'>;

export const useDebouncedUserSearch = (options?: SearchOptions) => {
  const [getUsers, { data, loading }] = useLazyQuery<
    UserIdentifierSearchResponse,
    UserIdentifierSearchQueryVars
  >(USER_IDENTIFIER_SEARCH_QUERY);

  const debouncedGetUsers = useMemo(
    () =>
      debounce<DebouncedGetUsers>(
        searchTerm => getUsers({ variables: { filter: { searchTerm, ...options } } }),
        500
      ),
    [getUsers, options]
  );

  useEffect(() => {
    return () => {
      debouncedGetUsers.cancel();
    };
  }, [debouncedGetUsers]);

  const unwrappedData = useMemo<Array<UserIdentifier & { displayName: string }> | undefined>(() => {
    if (data) {
      return data.tenant.users.nodes.map(u => ({
        displayName: u.displayName,
        identifier: u.id,
        userIdentifierType: UserIdentifierType.User
      }));
    }
  }, [data]);

  return {
    getUsers: debouncedGetUsers,
    loading,
    options: unwrappedData
  };
};
