import React from 'react';

const CustomDateField = React.forwardRef<HTMLInputElement, React.HTMLProps<HTMLInputElement>>(
  (props, ref) => {
    return <input ref={ref} {...props} min={'1800-01-01'} max={'9999-12-31'} />;
  }
);
CustomDateField.displayName = 'CustomDateField';

export default CustomDateField;
