import { gql } from '@apollo/client';
import { constants } from '../Constants';
import { InputFieldTypename, MultipleChoiceInputFieldTypeTypename } from '../CustomField';

export const INPUT_FIELD_TYPE_FRAGMENT_NAME = 'InputFieldTypeFragment';
export const INPUT_FIELD_TYPE_FRAGMENT = gql`
    fragment ${INPUT_FIELD_TYPE_FRAGMENT_NAME} on ${constants.FieldTypeInterfaceTypename} {
        key
        name
        dataType
        ... on ${MultipleChoiceInputFieldTypeTypename} {
            options
        }
    }
`;

export const INPUT_FIELD_FRAGMENT_NAME = 'InputFieldFragment';
export const INPUT_FIELD_FRAGMENT = gql`
  ${INPUT_FIELD_TYPE_FRAGMENT}
    fragment ${INPUT_FIELD_FRAGMENT_NAME} on ${InputFieldTypename} {
        key
        name
        required
        type {
          ...${INPUT_FIELD_TYPE_FRAGMENT_NAME}
        }
    }
`;
