import gql from 'graphql-tag';
import { ITenant } from '../Tenant';
import { UserDefaults } from '../User';
import { SectionConfiguration } from '../Common';
import { Dashboard, Section } from '../Dashboard';
import { User } from '@aireframe/shared-types';

export type DashboardSelectorData = {
  me: Pick<User, 'id'> & {
    userDefaults: Pick<UserDefaults, 'id' | 'sectionDashboards'> | null;
  };
  tenant: Pick<ITenant, 'id'> & {
    sectionConfiguration: Pick<SectionConfiguration, 'defaultDashboard' | 'section'> & {
      dashboards: Array<Pick<Dashboard, 'id' | 'title'>>;
    };
  };
};

export type DashboardSelectorVars = {
  section: keyof typeof Section;
};

export const DASHBOARD_SELECTOR_QUERY_NAME = 'DashboardSelectorQuery';
export const DASHBOARD_SELECTOR_QUERY = gql`
  query ${DASHBOARD_SELECTOR_QUERY_NAME}($section: Section!) {
    tenant {
      id
      sectionConfiguration(section: $section) {
        section
        dashboards {
          id
          title
        }
        defaultDashboard {
          id
        }
      }
    }
    me {
      id
      userDefaults {
        id
        sectionDashboards {
          section
          dashboardId
        }
      }
    }
  }
`;
