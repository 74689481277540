import React, { MutableRefObject, useState } from 'react';
import { IconButton, IconButtonProps, Tooltip, ClickAwayListener } from '@mui/material';

const PopupIconButton: React.FC<{
  iconButtonProps?: Omit<IconButtonProps, 'ref' | 'aria-haspopup' | 'onClick'>;
  buttonRef: MutableRefObject<HTMLButtonElement | null>;
  popupRef: MutableRefObject<HTMLElement | null>;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  ariaLabel: string;
  tooltip?: string;
  children: React.ReactElement;
}> = ({ iconButtonProps, buttonRef, popupRef, onClick, ariaLabel, tooltip, children }) => {
  const [activeButtonShading, setActiveButtonShading] = useState(false);
  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (
      (event.target instanceof HTMLElement &&
        popupRef.current &&
        !popupRef.current.contains(event.target)) ||
      popupRef.current === null ||
      popupRef.current === undefined
    ) {
      setActiveButtonShading(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Tooltip title={tooltip}>
        <IconButton
          {...iconButtonProps}
          ref={buttonRef}
          onClick={e => {
            onClick(e);
            setActiveButtonShading(true);
          }}
          aria-haspopup={true}
          aria-label={ariaLabel}
          sx={
            activeButtonShading
              ? { ...iconButtonProps?.sx, background: '#CBDAFC !important' }
              : { ...iconButtonProps?.sx }
          }>
          {children}
        </IconButton>
      </Tooltip>
    </ClickAwayListener>
  );
};

export default PopupIconButton;
