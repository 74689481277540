import gql from 'graphql-tag';
import { Dashboard } from './Dashboard';
import { DASHBOARD_FRAGMENT, DASHBOARD_FRAGMENT_NAME } from './DashboardFragment';

type DashboardInput = {
  key: string;
  title: string;
  forPortal: boolean;
  items: Array<{
    id: string;
    positionIndex: number;
  }>;
};

export interface CreateDashboardMutationVars {
  input: DashboardInput;
}

export interface CreateDashboardMutationData {
  createDashboard: Dashboard;
}

export const CREATE_DASHBOARD_MUTATION_NAME = 'createDashboard';

export const CREATE_DASHBOARD_MUTATION = gql`
${DASHBOARD_FRAGMENT}
  mutation ${CREATE_DASHBOARD_MUTATION_NAME}($input: DashboardInput!) {
    createDashboard(input: $input) {
      ...${DASHBOARD_FRAGMENT_NAME}
    }
  }
`;

export interface UpdateDashboardMutationVars {
  dashboardId: string;
  input: DashboardInput;
}

export interface UpdateDashboardMutationData {
  updateDashboard: Omit<Dashboard, 'itemSettings'>;
}

export const UPDATE_DASHBOARD_MUTATION_NAME = 'updateDashboard';

export const UPDATE_DASHBOARD_MUTATION = gql`
${DASHBOARD_FRAGMENT}
  mutation ${UPDATE_DASHBOARD_MUTATION_NAME}($dashboardId: ID!, $input: DashboardInput!) {
    updateDashboard(dashboardId: $dashboardId, input: $input) {
      ...${DASHBOARD_FRAGMENT_NAME}
    }
  }
`;

export interface DeleteDashboardMutationVars {
  dashboardId: string;
}

export interface DeleteDashboardMutationData {
  deleteDashboard: boolean;
}

export const DELETE_DASHBOARD_MUTATION_NAME = 'deleteDashboard';

export const REMOVE_DASHBOARD_MUTATION = gql`
  mutation ${DELETE_DASHBOARD_MUTATION_NAME}($dashboardId: ID!) {
    deleteDashboard(dashboardId: $dashboardId)
  }
`;
