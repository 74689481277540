import { languages, editor, Position, CancellationToken } from 'monaco-editor';
import { language as liquidLanguage } from 'monaco-editor/esm/vs/basic-languages/liquid/liquid.js';

const fixedLanguage = {
  ...liquidLanguage,
  builtinTags: [...liquidLanguage.builtinTags.filter(tag => tag !== 'elseif'), 'elsif']
};

export type LiquidOptions = {
  possibleArguments?: Set<string>;
  extraFilters?: Array<string>;
  excludeFilters?: Array<string>;
  extraTags?: Array<string>;
  excludeTags?: Array<string>;
};

const DefaultLiquidOptions: LiquidOptions = {
  extraFilters: ['plus_time', 'round_to_next', 'age', 'cui_date_time', 'cui_date', 'cui_time'],
  excludeTags: [
    'tablerow',
    'endtablerow',
    'comment',
    'endcomment',
    'liquid',
    'raw',
    'endraw',
    'render',
    'layout',
    'include'
  ]
};

export class LiquidCodeCompletionProvider implements languages.CompletionItemProvider {
  private readonly argumentsSuggestionRegex: RegExp;
  private readonly ifArgumentsSuggestionRegex: RegExp;
  private readonly arguments?: Set<string>;
  private readonly tags: Array<string>;
  private readonly filters: Array<string>;
  private readonly ifArguments;

  public readonly triggerCharacters: Array<string>;

  public constructor(options?: LiquidOptions) {
    const normalisedOptions = {
      ...DefaultLiquidOptions,
      ...(options ?? {})
    };

    this.arguments = normalisedOptions.possibleArguments;
    this.tags = this.getTags(normalisedOptions);
    this.filters = this.getFilters(normalisedOptions);

    this.triggerCharacters = [
      ...this.tags,
      '{',
      '%',
      ' ',
      '|',
      '=',
      '==',
      '<',
      '>',
      '>=',
      '<=',
      '!=',
      '*',
      '/',
      ':'
    ];

    this.argumentsSuggestionRegex = new RegExp(
      `(${[...this.tags, '{{', '=', '==', '!=', '<', '<=', '>', '>=', '*', '/', ':', 'contains']
        .map(this.escapeRegExp)
        .join('|')})$`
    );

    this.ifArgumentsSuggestionRegex = /^\{%\s*if\s*[a-z0-9"]+$/;
    this.ifArguments = ['==', '!=', '<', '<=', '>', '>=', 'contains'];
  }

  public provideCompletionItems(
    model: editor.ITextModel,
    position: Position,
    _: languages.CompletionContext,
    __: CancellationToken
  ): languages.ProviderResult<languages.CompletionList> {
    const suggestions: Array<languages.CompletionItem> = [];

    const lineContent = model.getLineContent(position.lineNumber);
    const textToPosition = lineContent.substring(0, position.column - 1).trim();

    if (!this.isInLiquidTag(textToPosition)) {
      return { suggestions };
    }

    // calculate range to pass to suggestions
    const wordUntilPosition = model.getWordUntilPosition(position);
    const range = {
      startLineNumber: position.lineNumber,
      startColumn: wordUntilPosition.startColumn,
      endLineNumber: position.lineNumber,
      endColumn: wordUntilPosition.endColumn
    };

    if (textToPosition.endsWith('{%')) {
      this.tags.forEach(tag => {
        suggestions.push({
          label: tag,
          kind: languages.CompletionItemKind.Keyword,
          insertText: tag,
          range
        });
      });

      return { suggestions };
    }

    if (textToPosition.endsWith('|')) {
      this.filters.forEach(filter => {
        suggestions.push({
          label: filter,
          kind: languages.CompletionItemKind.Keyword,
          insertText: filter,
          range
        });
      });
      return { suggestions };
    }

    if (this.arguments && textToPosition.match(this.argumentsSuggestionRegex)) {
      this.arguments.forEach(possibleArgument => {
        suggestions.push({
          label: possibleArgument,
          kind: languages.CompletionItemKind.Keyword,
          insertText: possibleArgument,
          range
        });
      });

      return { suggestions };
    }

    if (textToPosition.match(this.ifArgumentsSuggestionRegex)) {
      this.ifArguments.forEach(possibleArgument => {
        suggestions.push({
          label: possibleArgument,
          kind: languages.CompletionItemKind.Operator,
          insertText: possibleArgument,
          range
        });
      });
    }

    return { suggestions };
  }

  private getFilters = (options: LiquidOptions): Array<string> => {
    return [...fixedLanguage.builtinFilters, ...(options.extraFilters ?? [])].filter(
      filter => !options.excludeFilters || !options.excludeFilters.includes(filter)
    );
  };

  private getTags = (options: LiquidOptions): Array<string> => {
    return [...fixedLanguage.builtinTags, ...(options.extraTags ?? [])].filter(
      filter => !options.excludeTags || !options.excludeTags.includes(filter)
    );
  };

  private escapeRegExp = (value: string) => {
    return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  private isInLiquidTag(textToPosition: string): boolean {
    const lastStartControlFlow = textToPosition.lastIndexOf('{%');
    const lastEndControlFlow = textToPosition.lastIndexOf('%}');
    const lastStartVariable = textToPosition.lastIndexOf('{{');
    const lastEndVariable = textToPosition.lastIndexOf('}}');

    return lastStartControlFlow > lastEndControlFlow || lastStartVariable > lastEndVariable;
  }
}
