export const appendPath = (base: string, path: string): string => {
  const url = new URL(base);
  const trimmedPath = path.replace(/^\//, '');
  return url.href.replace(/\/$/, '') + '/' + trimmedPath;
};

export const isAbsoluteURI = (uri: string): boolean => {
  try {
    new URL(uri);
    return true;
  } catch {
    return false;
  }
};
