import gql from 'graphql-tag';
import { DATA_TYPE_VALUE_FRAGMENT, DATA_TYPE_VALUE_FRAGMENT_NAME } from './DataTypeValueFragment';
import { INPUT_FIELD_FRAGMENT, INPUT_FIELD_FRAGMENT_NAME } from './InputFieldFragment';

export const CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME = 'CoreStructuralEntityFields';
export const CORE_STRUCTURAL_ENTITY_FIELDS = gql`
  ${DATA_TYPE_VALUE_FRAGMENT}
  ${INPUT_FIELD_FRAGMENT}
  fragment ${CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME} on StructuralEntity {
    displayName
    depth
    parentKey
    key
    structureType {
      key
      subjectAssignable
    }
    customFieldValues {
      field {
        ...${INPUT_FIELD_FRAGMENT_NAME}
      }
      value {
        ...${DATA_TYPE_VALUE_FRAGMENT_NAME}
      }
    }
  }
`;
