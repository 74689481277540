import { Permission } from '@aireframe/shared-types';
import { AbilityBuilder, PureAbility, RawRuleOf } from '@casl/ability';
import { AppAbility } from './AppAbilities';
import { IFeatureSetController } from './FeatureToggle';

// This is because we don't want to couple the permissions to the ui, so if the user can do any of these things they can see the settings page
function isArray<T>(obj: T | Array<T>): obj is Array<T> {
  return (obj as Array<T>).length !== undefined;
}

// excludes "list:User"
const configAbilities: Array<RawRuleOf<AppAbility>> = [
  {
    subject: 'SubjectConfiguration',
    action: ['view', 'edit']
  },
  {
    subject: 'User',
    action: ['view', 'edit']
  },
  {
    subject: 'DataSource',
    action: ['list', 'view', 'create', 'edit', 'delete']
  },
  {
    subject: 'DataSet',
    action: ['list', 'view', 'create', 'delete']
  },
  {
    subject: 'Widget',
    action: ['create', 'edit', 'delete']
  },
  {
    subject: 'Dashboard',
    action: ['create', 'edit', 'delete']
  },
  {
    subject: 'Group',
    action: ['list', 'view', 'create', 'edit', 'delete']
  },
  {
    subject: 'StructuralEntity',
    action: ['list', 'view', 'create', 'edit']
  }
];

export function defineAbilitiesFor(
  permissions: Permission[],
  _: IFeatureSetController
): AppAbility {
  const { can, build } = new AbilityBuilder<AppAbility>(PureAbility);

  for (const permission of permissions) {
    can(permission.action, permission.subject);

    const item = configAbilities.find(x => x.subject === permission.subject);
    if (item) {
      if (isArray(item.action) && (item.action as string[]).includes(permission.action)) {
        can('access', 'ConfigurationPage');
      } else if (item.action === permission.action) {
        can('access', 'ConfigurationPage');
      }
    }
  }

  return build();
}
