import { DataType, FieldFilter } from '@aireframe/shared-types';
import { Grid, TextFieldProps } from '@mui/material';
import UserIdentifierLookUpField from '../../CustomFields/UserIdentifier/UserIdentifierLookupField';
import FilterHeader from '../FilterHeader';

export const UserIdentifierFilter = ({
  title,
  value: filter,
  onChange,
  onRemove,
  textFieldProps
}: {
  title: string;
  value: FieldFilter<DataType.USERIDENTIFIER>;
  onChange: (value: FieldFilter<DataType.USERIDENTIFIER>) => void;
  onRemove: () => void;
  textFieldProps?: Omit<TextFieldProps, 'type' | 'defaultValue' | 'onBlur'>;
}) => {
  return (
    <Grid container direction="column" spacing={1} sx={{ p: 1 }}>
      <Grid item>
        <FilterHeader title={title} onClear={onRemove} />
      </Grid>
      <Grid item>
        <UserIdentifierLookUpField
          autoCompleteProps={{
            fullWidth: true,
            label: title,
            ...textFieldProps
          }}
          onChange={userIdentifier =>
            onChange({
              ...filter,
              value: {
                dataType: DataType.USERIDENTIFIER,
                userIdentifierValue: userIdentifier ?? null
              }
            })
          }
          value={filter.value.userIdentifierValue}
        />
      </Grid>
    </Grid>
  );
};

export default UserIdentifierFilter;
