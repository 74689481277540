import { styled } from '@mui/material';

export type ArrowPlacementProps = ({ top?: number | string } | { bottom?: number | string }) &
  ({ left?: number | string } | { right?: number | string }) & {
    point?: keyof typeof ArrowPointCss;
    size?: number;
  };

const ArrowPointCss = {
  up: 'rotate(0deg)',
  right: 'rotate(90deg)',
  down: 'rotate(180deg)',
  left: 'rotate(-90deg)'
};

export const PopperArrow = styled('span')<ArrowPlacementProps>(
  ({ theme, point, size, ...arrowPlacement }) => ({
    top: -5,
    ...arrowPlacement,
    height: 0,
    width: 0,
    position: 'absolute',
    borderLeft: `${size ?? 5}px solid transparent`,
    borderRight: `${size ?? 5}px solid transparent`,
    borderBottom: `${size ?? 5}px solid ${theme.palette.primary.main}`,
    transform: ArrowPointCss[point ?? 'up']
  })
);
