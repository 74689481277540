import gql from 'graphql-tag';

export interface DeleteStructureTypeMutationVars {
  structureTypeKey: string;
}

export interface DeleteStructureTypeMutationData {
  deleteStructureType: boolean;
}

export const DELETE_STRUCTURE_TYPE_MUTATION = gql`
  mutation DeleteStructureType($structureTypeKey: ID!) {
    deleteStructureType(structureTypeKey: $structureTypeKey)
  }
`;
