import {
  ECharts,
  EChartsOption,
  getInstanceByDom,
  init,
  SetOptionOpts,
  XAXisComponentOption
} from 'echarts';
import { CSSProperties, useEffect, useRef } from 'react';

type EventParams = {
  // The component name clicked,
  // component type, could be 'series'、'markLine'、'markPoint'、'timeLine', etc..
  componentType: string;
  // series type, could be 'line'、'bar'、'pie', etc.. Works when componentType is 'series'.
  seriesType?: string;
  // the index in option.series. Works when componentType is 'series'.
  seriesIndex?: number;
  // series name, works when componentType is 'series'.
  seriesName?: string;
  // name of data (categories).
  name: string;
  // the index in 'data' array.
  dataIndex: number;
  // incoming raw data item
  data: unknown;
  // charts like 'sankey' and 'graph' included nodeData and edgeData as the same time.
  // dataType can be 'node' or 'edge', indicates whether the current click is on node or edge.
  // most of charts have one kind of data, the dataType is meaningless
  dataType?: string;
  // incoming data value
  value: string | number | Date | unknown;
  // color of the shape, works when componentType is 'series'.
  color?: string | unknown;
};

export interface ReactEChartsProps {
  width?: string;
  height: number;
  option: EChartsOption;
  style?: CSSProperties;
  settings?: SetOptionOpts;
  loading?: boolean;
  theme?: 'light' | 'dark';
  truncateLabels?: boolean;
  onClick?: (params: EventParams) => void;
  onHover?: (params: EventParams, chart: ECharts) => void;
  getLabelWidth?: (width: number) => number | undefined;
}

export function ReactECharts({
  width = '100%',
  height,
  option: options,
  style,
  settings,
  loading,
  theme,
  truncateLabels,
  onClick,
  onHover,
  getLabelWidth
}: ReactEChartsProps): JSX.Element {
  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    let chart: ECharts | undefined;
    if (chartRef.current !== null) {
      chart = init(chartRef.current, theme);
    }

    function resizeChart() {
      chart?.resize();
    }
    window.addEventListener('resize', resizeChart);

    return () => {
      chart?.dispose();
      window.removeEventListener('resize', resizeChart);
    };
  }, [theme]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      const xAxis = options.xAxis
        ? {
            ...options.xAxis,
            axisLabel: {
              ...(options.xAxis as XAXisComponentOption)?.axisLabel,
              width: getLabelWidth && getLabelWidth(chartRef.current.offsetWidth),
              overflow: truncateLabels ? 'truncate' : 'none'
            }
          }
        : undefined;

      const updatedOptions = {
        ...options,
        grid: options?.grid ?? {
          left: '5%',
          top: '11%',
          right: '2%',
          bottom: '5%',
          containLabel: true
        },
        xAxis
      };
      chart?.setOption(updatedOptions, settings);
    }
  }, [options, settings, theme, truncateLabels, getLabelWidth]);

  useEffect(() => {
    if (chartRef.current !== null) {
      const chart = getInstanceByDom(chartRef.current);
      if (loading) {
        chart?.showLoading();
      } else {
        chart?.hideLoading();
      }
    }
  }, [loading, theme]);

  useEffect(() => {
    if (onClick && chartRef.current) {
      const chart = getInstanceByDom(chartRef.current);

      if (chart) {
        chart.on('click', onClick);
      }
    }
  }, [onClick]);

  useEffect(() => {
    if (chartRef.current) {
      const chart = getInstanceByDom(chartRef.current);

      if (chart && onHover) {
        chart.on('mousemove', event => onHover(event, chart));
      }
    }
  }, [onHover]);

  return (
    <div ref={chartRef} style={{ width: width, height: height, overflow: 'hidden', ...style }} />
  );
}
