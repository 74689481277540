import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/en-gb';

export const DateTimeFilter = ({
  value,
  onChange,
  ...props
}: {
  value: string | null;
  onChange: (value: string | null) => void;
} & Omit<DateTimePickerProps<Dayjs>, 'defaultValue' | 'value' | 'onChange'>) => {
  const handleChangeAbsolute = (newValue: Dayjs | null) => {
    if (newValue !== null && newValue.isValid()) {
      onChange(newValue.format('YYYY-MM-DD HH:mm:ss'));
    } else {
      onChange(null);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <DateTimePicker
        {...props}
        sx={{ width: '100%' }}
        defaultValue={
          value
            ? dayjs(
                value,
                [
                  'YYYY-MM-DD HH:mm:ss',
                  'YYYY-MM-DD H:mm:ss',
                  'YYYY-MM-DD HH:mm',
                  'YYYY-MM-DD H:mm',
                  'YYYY-MM-DD HH',
                  'YYYY-MM-DD H'
                ],
                true
              )
            : null
        }
        onChange={handleChangeAbsolute}
      />
    </LocalizationProvider>
  );
};
