import gql from 'graphql-tag';
import { SubTenantStructuralEntity } from '../../Structure/StructuralEntity';
import { NodesConnection } from '../../Paging';

export interface ChangeSubjectLocationMutationVars {
  subjectId: string;
  structuralEntityKey: string;
}

export interface AddSubjectToLocationMutationData {
  addSubjectToStructuralEntity: {
    __typename: 'Subject';
    id: string;
    locations: NodesConnection<Pick<SubTenantStructuralEntity, 'key' | 'displayName'>>;
  };
}

export interface RemoveSubjectFromLocationMutationData {
  removeSubjectFromStructuralEntity: {
    __typename: 'Subject';
    id: string;
    locations: NodesConnection<Pick<SubTenantStructuralEntity, 'key' | 'displayName'>>;
  };
}

export const ADD_SUBJECT_TO_LOCATION_MUTATION_NAME = 'AddSubjectToLocation';
export const ADD_SUBJECT_TO_LOCATION_MUTATION = gql`
  mutation ${ADD_SUBJECT_TO_LOCATION_MUTATION_NAME}($subjectId: ID!, $structuralEntityKey: ID!) {
    addSubjectToStructuralEntity(subjectId: $subjectId, structuralEntityKey: $structuralEntityKey) {
      id
      locations {
        nodes {
          key
          displayName
        }
      }
    }
  }
`;

export const REMOVE_SUBJECT_FROM_LOCATION_MUTATION_NAME = 'RemoveSubjectFromLocation';
export const REMOVE_SUBJECT_FROM_LOCATION_MUTATION = gql`
  mutation ${REMOVE_SUBJECT_FROM_LOCATION_MUTATION_NAME}($subjectId: ID!, $structuralEntityKey: ID!) {
    removeSubjectFromStructuralEntity(
      subjectId: $subjectId
      structuralEntityKey: $structuralEntityKey
    ) {
      id
      locations {
        nodes {
          key
          displayName
        }
      }
    }
  }
`;
