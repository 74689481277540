import gql from 'graphql-tag';
import {
  FieldType,
  MultipleChoiceInputFieldType,
  SimpleInputFieldType,
  SimpleInputFieldTypes
} from './Types';
import { NodesConnection } from '../Paging';

export interface CustomFieldTypesQueryData {
  appConfig: {
    __typename: 'AppConfig';
    customFieldTypes: NodesConnection<
      | (Omit<SimpleInputFieldType, 'key'> & {
          key: Exclude<SimpleInputFieldTypes, FieldType.USER_IDENTIFIER>;
        })
      | MultipleChoiceInputFieldType,
      'InputFieldType'
    >;
  };
}

export const CUSTOM_FIELD_TYPES_QUERY_NAME = 'CustomFieldTypes';
export const CUSTOM_FIELD_TYPES_QUERY = gql`
  query ${CUSTOM_FIELD_TYPES_QUERY_NAME} {
    appConfig {
      customFieldTypes {
        nodes {
          key
          name
        }
      }
    }
  }
`;
