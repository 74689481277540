import { Grid, Typography, styled } from '@mui/material';
import { Fragment } from 'react';
import ExpandIconButton from '../../../../ExpandIconButton/ExpandIconButton';
import { shouldShowFiltering } from '../../Utils';
import { useVisualisationContext } from '../../VisualisationContext';
import { VisualisationFilter } from './VisualisationFilter';

const StyledDiv = styled('div')(({ theme }) => ({
  display: 'inline-flex',
  float: 'right',
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  padding: theme.spacing(0.5, 1.5),
  borderRadius: 10
}));

type Props = {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
};

const DataVisualisationHeaderContent: React.FC<Props> = ({ expanded, setExpanded }) => {
  const { definition, totalCount } = useVisualisationContext();

  return (
    <Fragment>
      <Grid container item md={6} xs={6} justifyContent="flex-end" spacing={1} alignItems="center">
        {shouldShowFiltering(definition) && (
          <Grid item>
            <VisualisationFilter />
          </Grid>
        )}

        <Grid item>
          <StyledDiv aria-label="Total Count">
            <Typography style={{ fontWeight: 600 }}>{totalCount || '-'}</Typography>
          </StyledDiv>
        </Grid>
        <Grid item>
          <ExpandIconButton expanded={expanded} setExpanded={setExpanded} />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DataVisualisationHeaderContent;
