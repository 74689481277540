import {
  DASHBOARD_SELECTOR_QUERY,
  DashboardSelectorData,
  DashboardSelectorVars,
  Section
} from '@aireframe/graphql';
import { getEnumKeyByValue } from '@aireframe/shared-types';
import { useQuery } from '@apollo/client';
import { useAbility } from '../Authorisation';

const useDashboardSelectorQuery = (
  section: Section,
  handleChange: (dashboardId?: string) => void,
  existingValue?: string
) => {
  const ability = useAbility();
  const canListDashboard = ability.can('list', 'Dashboard');
  return useQuery<DashboardSelectorData, DashboardSelectorVars>(DASHBOARD_SELECTOR_QUERY, {
    variables: {
      section: getEnumKeyByValue(Section, section)
    },
    skip: !canListDashboard,
    onCompleted: data => {
      if (
        existingValue &&
        data.tenant.sectionConfiguration.dashboards.some(d => d.id === existingValue)
      ) {
        return;
      }

      const userDefaultDashboardId = data.me.userDefaults?.sectionDashboards.find(
        sd => sd.section === getEnumKeyByValue(Section, section)
      )?.dashboardId;

      const tenantDefaultDashboardId = data.tenant.sectionConfiguration.defaultDashboard?.id;

      if (userDefaultDashboardId) {
        handleChange(userDefaultDashboardId);
      } else if (tenantDefaultDashboardId) {
        handleChange(tenantDefaultDashboardId);
      } else {
        const dashboards = data.tenant.sectionConfiguration.dashboards;
        if (dashboards.length === 1) {
          handleChange(dashboards[0].id);
        }
      }
    }
  });
};

export default useDashboardSelectorQuery;
