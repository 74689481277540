import gql from 'graphql-tag';

export interface CreateDataExtractInput {
  title: string;
  key: string;
  dataSourceId: string;
  description: string | null;
  providerJSON: string | null;
  subjectIdentifierConfiguration: {
    useInternalId: boolean;
    useExternalId: boolean;
    customFieldKey: string | null;
  } | null;
}

export interface AddDataExtractMutationVars {
  input: CreateDataExtractInput;
}

export interface AddDataExtractMutationData {
  id: string;
}

export const ADD_DATA_EXTRACT_MUTATION_NAME = 'AddDataExtract';

export const ADD_DATA_EXTRACT_MUTATION = gql`
  mutation ${ADD_DATA_EXTRACT_MUTATION_NAME}($input: DataExtractInput!) {
    addDataExtract(input: $input) {
      id
    }
  }
`;
