import gql from 'graphql-tag';
import { DataSourceInstanceInputBase } from './Types';

export type BundledDataSourceInstanceInput = DataSourceInstanceInputBase & {
  dataSourceKey: string;
};

export interface AddBundledDataSourceInstanceMutationVars {
  input: BundledDataSourceInstanceInput;
}

export const ADD_BUNDLED_DATA_SOURCE_INSTANCE_MUTATION_NAME = 'AddBundledDataSourceInstance';
export const ADD_BUNDLED_DATA_SOURCE_INSTANCE_MUTATION = gql`
  mutation ${ADD_BUNDLED_DATA_SOURCE_INSTANCE_MUTATION_NAME}($input: BundledDataSourceInstanceInput!) {
    dataSourceInstance: addBundledDataSourceInstance(input: $input) {
      id
      name
      key
      dataSource {
        type
        key
      }
    }
  }
`;

export type CustomDataSourceInstanceInput = DataSourceInstanceInputBase & {
  key: string;
  endpoint: string;
  clientId: string;
  clientSecretReference: string;
  scope: string;
};

export interface AddCustomDataSourceInstanceMutationVars {
  input: CustomDataSourceInstanceInput;
}

export const ADD_CUSTOM_DATA_SOURCE_INSTANCE_MUTATION_NAME = 'AddCustomDataSourceInstance';
export const ADD_CUSTOM_DATA_SOURCE_INSTANCE_MUTATION = gql`
  mutation ${ADD_CUSTOM_DATA_SOURCE_INSTANCE_MUTATION_NAME}($input: CustomDataSourceInstanceInput!) {
    dataSourceInstance: addCustomDataSourceInstance(input: $input) {
      id
      name
      dataSource {
        type
      }
    }
  }
`;
