import { IFrameRenderType } from '@aireframe/shared-types';
import { Grid } from '@mui/material';
import ExpandIconButton from '../../ExpandIconButton/ExpandIconButton';
import WidgetHeader from '../WidgetHeader';

type Props = {
  definition: IFrameRenderType;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
};

const IFrameHeader: React.FC<Props> = ({ definition, expanded, setExpanded }) => (
  <WidgetHeader title={definition.title} justifyContent="flex-start">
    <Grid container item md={6} xs={6} justifyContent="flex-end" spacing={1} alignItems="center">
      <Grid item>
        <ExpandIconButton expanded={expanded} setExpanded={setExpanded} />
      </Grid>
    </Grid>
  </WidgetHeader>
);

export default IFrameHeader;
