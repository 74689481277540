import { ActionableDataPoint, PerformAction } from '@aireframe/graphql';
import { zodResolver } from '@hookform/resolvers/zod';
import { Alert, Grid, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import ConfirmationDialog from '../../../ConfirmationDialog/ConfirmationDialog';
import {
  buildInputFieldsSchema,
  CustomFieldFormValues,
  CustomFieldInput,
  MapCustomFieldFormValueToValueInput
} from '../../../CustomFields';
import { useVisualisationContext } from '../VisualisationContext';
import usePerformAction from './Hooks/usePerformAction';

interface PerformActionContainerProps {
  action: PerformAction;
  dataPoint: ActionableDataPoint;
  onClose: () => void;
}

const PerformActionContainer: React.FC<PerformActionContainerProps> = ({
  action,
  dataPoint,
  onClose
}) => {
  const { definition } = useVisualisationContext();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const { result, performAction, loading, reset } = usePerformAction(definition, {
    onSuccess: () => {
      setSnackbarOpen(true);
    },
    onError: () => {
      setSnackbarOpen(true);
    }
  });

  const methods = useForm<CustomFieldFormValues>({
    resolver: zodResolver(buildInputFieldsSchema(action.inputFields)),
    defaultValues: action.inputFields.reduce((dict, cf) => {
      return {
        ...dict,
        [cf.key]: null
      };
    }, {})
  });
  const { handleSubmit, reset: formReset } = methods;

  const onPerformAction = (formValues: CustomFieldFormValues) => {
    const updatedAction = {
      description: action.description,
      key: action.key,
      name: action.name,
      type: action.type,
      inputFields: action.inputFields.map(field => ({
        key: field.key,
        name: field.name,
        required: field.required,
        type: field.type.key,
        value: MapCustomFieldFormValueToValueInput(formValues, field)
      }))
    };

    performAction(dataPoint, updatedAction);
  };

  useEffect(() => {
    reset();
    formReset();
  }, [action, reset, formReset]);

  if (result) {
    return (
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => {
          setSnackbarOpen(false);
          onClose();
        }}
        ClickAwayListenerProps={{
          onClickAway: () => {
            return;
          }
        }}>
        <Alert onClose={onClose} severity={result.success ? 'success' : 'error'}>
          {result.message}
        </Alert>
      </Snackbar>
    );
  }

  return (
    <FormProvider {...methods}>
      <ConfirmationDialog
        title={`Perform action '${action.name}'?`}
        contentText={`Are you sure you want to '${action.description}'?`}
        content={
          <>
            {action.inputFields && action.inputFields.length > 0 && (
              <form autoComplete="off">
                <Grid container spacing={1}>
                  {action.inputFields.map(field => (
                    <Grid item xs={12} key={field.key}>
                      <CustomFieldInput
                        customField={field}
                        disabled={loading}
                        subjectId={dataPoint.subjectId}
                      />
                    </Grid>
                  ))}
                </Grid>
              </form>
            )}
          </>
        }
        onClose={onClose}
        onConfirm={handleSubmit(onPerformAction)}
        disableActions={loading}
      />
    </FormProvider>
  );
};

export default PerformActionContainer;
