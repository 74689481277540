import { Alert } from '@mui/material';
import * as React from 'react';
import { ErrorInfo } from 'react';
import { LogLevel } from '@aireframe/shared-types';
import { Logger, withLogger } from '../Hooks';

interface IState {
  hasError: boolean;
}

interface Props {
  children?: React.ReactNode;
  context: string;
  message: string;
  level?: LogLevel;
  shouldLog?: boolean;
  logger: Logger;
}

class ErrorBoundary extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: Error, _: ErrorInfo) {
    this.setState({ hasError: true });

    if (this.props.shouldLog !== false) {
      this.props.logger.log(
        `An error occoured within '${this.context}': ${error.message}`,
        this.props.level ?? LogLevel.Error
      );
    }
  }

  render() {
    if (this.state.hasError) {
      return <Alert severity="error">{this.props.message}</Alert>;
    }
    return this.props.children;
  }
}

export default withLogger(ErrorBoundary);
