import { IAuthenticationContext, Loading } from '@aireframe/components';
import { createRouter, ParsedLocation, redirect } from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export const router = createRouter({
  routeTree,

  context: { auth: undefined! },
  defaultPendingComponent: () => <Loading />
});

export interface RouterContext {
  auth: IAuthenticationContext;
}

export const validateAuthenticated = ({
  context,
  location
}: {
  context: RouterContext;
  location: ParsedLocation;
}) => {
  if (context.auth.isLoggedIn) {
    return;
  }

  throw redirect({
    to: '/login',
    search: {
      targetUrl: location.href
    }
  });
};
