import { User } from '@aireframe/shared-types';
import gql from 'graphql-tag';

export interface CurrentUserQueryData {
  me: User;
}

export const CURRENT_USER_QUERY_NAME = 'CurrentUser';
export const CURRENT_USER_QUERY = gql`
  query ${CURRENT_USER_QUERY_NAME} {
    me {
      id
      displayName
      linkedSubjectId
      permissions {
        action
        subject
      }
    }
  }
`;

export type ActiveUser = CurrentUserQueryData['me'];
