import {
  ConnectionPageInfo,
  OrderableConnectionQueryVars,
  OrderDirection
} from '@aireframe/graphql';
import * as zod from 'zod';

export const cursorPaginationStateSchema = zod.object({
  orderBy: zod.string().nullable().default(null),
  orderDirection: zod.nativeEnum(OrderDirection).default(OrderDirection.DESCENDING),
  pageSize: zod.number().min(1).default(20).nullable(),
  before: zod.string().nullable().default(null),
  after: zod.string().nullable().default(null)
});

export type CursorPaginationState = zod.infer<typeof cursorPaginationStateSchema>;

const DEFAULT_CURSOR_PAGINATION_STATE: CursorPaginationState = {
  orderBy: null,
  orderDirection: OrderDirection.ASCENDING,
  pageSize: 20,
  after: null,
  before: null
};

export class CursorPagination implements CursorPaginationState {
  public readonly after: string | null;
  public readonly before: string | null;
  public readonly pageSize: number | null;
  public readonly orderBy: string | null;
  public readonly orderDirection: OrderDirection;

  public constructor(opts: Partial<CursorPaginationState> = DEFAULT_CURSOR_PAGINATION_STATE) {
    this.pageSize =
      opts.pageSize === null ? null : (opts.pageSize ?? DEFAULT_CURSOR_PAGINATION_STATE.pageSize);
    this.orderBy = opts.orderBy ?? DEFAULT_CURSOR_PAGINATION_STATE.orderBy;
    this.orderDirection = opts.orderDirection ?? DEFAULT_CURSOR_PAGINATION_STATE.orderDirection;
    this.after = opts.after ?? DEFAULT_CURSOR_PAGINATION_STATE.after;
    this.before = opts.before ?? DEFAULT_CURSOR_PAGINATION_STATE.before;
  }

  public pageForwards(
    pageInfo: Pick<ConnectionPageInfo, 'hasNextPage' | 'endCursor'>
  ): CursorPagination {
    if (!pageInfo.hasNextPage) {
      throw new Error('Cannot page forwards reached the last page');
    }

    return new CursorPagination({
      ...this,
      after: pageInfo.endCursor,
      before: null
    });
  }

  public pageBackwards(
    pageInfo: Pick<ConnectionPageInfo, 'hasPreviousPage' | 'startCursor'>
  ): CursorPagination {
    if (!pageInfo.hasPreviousPage) {
      throw new Error('Cannot page backwards, reached the first page');
    }

    return new CursorPagination({
      ...this,
      after: null,
      before: pageInfo.startCursor
    });
  }

  public get connectionVariables(): OrderableConnectionQueryVars {
    return {
      first: this.before ? null : this.pageSize,
      last: this.before ? this.pageSize : null,
      after: this.after,
      before: this.before,
      orderBy: this.orderBy,
      orderDirection: this.orderDirection
    };
  }
}
