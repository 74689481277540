import {
  IFrameRenderType,
  VisualisationRenderType,
  assertUnreachable
} from '@aireframe/shared-types';

export type Dashboard = {
  __typename: 'Dashboard';
  id: string;
  key: string;
  title: string;
  forPortal: boolean;
  assignedSections: Section[];
  items: Array<{
    widget: VisualisationRenderType | IFrameRenderType;
    positionIndex: number;
  }>;
};

export interface Column {
  [key: string]: {
    title: string;
    items: Array<Dashboard['items'][number]['widget']>;
  };
}

export enum Section {
  LOCATION = 'LOCATION',
  SUBJECT_LIST = 'SUBJECT_LIST',
  SUBJECT_PREVIEW = 'SUBJECT_PREVIEW',
  SUBJECT_RECORD = 'SUBJECT_RECORD',
  SUBJECT_SELF_ACCESS = 'SUBJECT_SELF_ACCESS'
}

export const sectionDisplayName = (section: Section) => {
  switch (section) {
    case Section.LOCATION:
      return 'Location';
    case Section.SUBJECT_LIST:
      return 'Subject List';
    case Section.SUBJECT_PREVIEW:
      return 'Subject Preview';
    case Section.SUBJECT_RECORD:
      return 'Subject Record';
    case Section.SUBJECT_SELF_ACCESS:
      return 'Subject Portal';

    default:
      assertUnreachable(section);
  }
};
