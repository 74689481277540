import { LogLevel, appendPath } from '@aireframe/shared-types';
import { useAppContext } from '../AppContext';

export type Logger = {
  log: (message: string, level: LogLevel) => Promise<void>;
  trace: (message: string) => Promise<void>;
  debug: (message: string) => Promise<void>;
  info: (message: string) => Promise<void>;
  warn: (message: string) => Promise<void>;
  error: (message: string) => Promise<void>;
  fatal: (message: string) => Promise<void>;
};

export const useLogger = (): Logger => {
  const { apiHost } = useAppContext();

  const log = async (message: string, level: LogLevel) => {
    await fetch(appendPath(apiHost, 'api/v1/log'), {
      method: 'POST',
      headers: new Headers({ 'content-type': 'application/json', 'x-csrf': '1' }),
      body: JSON.stringify({
        message,
        level,
        service: 'client'
      })
    });
  };

  return {
    log,
    trace: message => log(message, LogLevel.Trace),
    debug: message => log(message, LogLevel.Debug),
    info: message => log(message, LogLevel.Info),
    warn: message => log(message, LogLevel.Warn),
    error: message => log(message, LogLevel.Error),
    fatal: message => log(message, LogLevel.Fatal)
  };
};

type WithLoggerProps = {
  logger: Logger;
};

export function withLogger<T extends WithLoggerProps = WithLoggerProps>(
  WrappedComponent: React.ComponentType<T>
) {
  const ComponentWithLogger = (props: Omit<T, keyof WithLoggerProps>) => {
    const logger = useLogger();

    return <WrappedComponent {...(props as T)} logger={logger} />;
  };

  const displayName = WrappedComponent.displayName ?? WrappedComponent.name;
  ComponentWithLogger.displayName = `withLogger(${displayName})`;
  return ComponentWithLogger;
}
