import {
  ActionableDataPoint,
  DataPointAction,
  isDisplayAction,
  isPerformAction
} from '@aireframe/graphql';
import DisplayActionContainer from './DisplayActionContainer';
import PerformActionContainer from './PerformActionContainer';

interface ActionContainerProps {
  dataPoint: ActionableDataPoint;
  action: DataPointAction;
  onClose: () => void;
}

export const ActionContainer: React.FC<ActionContainerProps> = ({ dataPoint, action, onClose }) => {
  if (isDisplayAction(action)) {
    return (
      <DisplayActionContainer subjectId={dataPoint.subjectId} action={action} onClose={onClose} />
    );
  }

  if (isPerformAction(action)) {
    return <PerformActionContainer dataPoint={dataPoint} action={action} onClose={onClose} />;
  }

  // Anything else is currently unsupported so close the container
  onClose();
  return null;
};
