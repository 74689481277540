import { FieldType, InputField, ValueInput } from '@aireframe/graphql';
import { assertUnreachable, UserIdentifier, valueOrNullIfEmpty } from '@aireframe/shared-types';
import { CustomFieldFormValue, CustomFieldFormValues } from './CustomFieldValidators';

const isStringOrNull = (value: CustomFieldFormValue): value is string | null => {
  return value === null || typeof value === 'string';
};

const isUserIdentifier = (value: CustomFieldFormValue): value is UserIdentifier | null => {
  return (value as UserIdentifier).userIdentifierType !== undefined;
};

export const MapCustomFieldFormValueToValueInput = (
  formValues: CustomFieldFormValues,
  inputField: InputField
): ValueInput => {
  const value = formValues[inputField.key];

  switch (inputField.type.key) {
    case FieldType.DATETIME: {
      if (!isStringOrNull(value)) {
        throw new Error('Value is not a string or null');
      }
      return {
        dateTime: { value: valueOrNullIfEmpty(value) }
      };
    }
    case FieldType.DATE: {
      if (!isStringOrNull(value)) {
        throw new Error('Value is not a string or null');
      }
      return {
        date: { value: valueOrNullIfEmpty(value) }
      };
    }
    case FieldType.TIME: {
      if (!isStringOrNull(value)) {
        throw new Error('Value is not a string or null');
      }
      return {
        time: { value: valueOrNullIfEmpty(value) }
      };
    }
    case FieldType.TEXT:
    case FieldType.EMAIL:
    case FieldType.PHONE_NUMBER:
    case FieldType.SEX:
    case FieldType.CONSTRAINED_VALUES: {
      if (!isStringOrNull(value)) {
        throw new Error('Value is not a string or null');
      }
      return {
        string: { value: valueOrNullIfEmpty(value) }
      };
    }
    case FieldType.NUMBER: {
      if (!isStringOrNull(value)) {
        throw new Error('Value is not a string or null');
      }

      const normalisedValue = valueOrNullIfEmpty(value);
      if (normalisedValue !== null && isNaN(Number(value))) {
        throw new Error('Value is not a string representation of a number');
      }

      return {
        decimal: { value: normalisedValue === null ? null : Number(normalisedValue) }
      };
    }
    case FieldType.USER_IDENTIFIER: {
      if (!isUserIdentifier(value)) {
        throw new Error('Value is not a UserIdentifier or null');
      }
      return {
        userIdentifier: { value }
      };
    }
    default:
      return assertUnreachable(inputField.type);
  }
};
