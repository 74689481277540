export const toCapitalCase = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const isUndefinedOrEmptyOrWhitespace = (string?: string | null) =>
  string === undefined || string === null || /^\s*$/.test(string);

export const isStringValidJson = (jsonString: string) => {
  try {
    const o = JSON.parse(jsonString);
    if (o && typeof o === 'object') {
      return true;
    }
  } catch {
    return false;
  }

  return false;
};

export const valueOrNullIfEmpty = (string?: string | null): string | null => {
  return !string || string.trim() === '' ? null : string;
};

export const stringHasNoTrailingOrLeadingWhitespace = (string: string) =>
  /^[^\s]+(\s+[^\s]+)*$/gi.test(string);

export const KeyRegex = /^[a-zA-Z0-9_-]+$/;

export const stringToKey = (value: string | undefined): string => {
  if (value === undefined) return '';
  return value.replace(/[^a-zA-Z0-9_-]/g, '_').toLowerCase();
};
