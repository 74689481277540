import { Typography } from '@mui/material';
import { useVisualisationContext } from './VisualisationContext';

export const NoDataMessage = () => {
  const { filter } = useVisualisationContext();

  if (filter.hasFilter()) {
    return (
      <Typography variant="subtitle1" sx={{ p: 1 }}>
        No data matches the current filter. Try adjusting the filter settings.
      </Typography>
    );
  }

  return (
    <Typography variant="subtitle1" sx={{ p: 1 }}>
      No data has been collected for this visualisation.
    </Typography>
  );
};
