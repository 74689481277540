export const TUTORIAL_KEY_PREFIX = 'tutorial:';

export const clearLocalStorage = () => {
  // Don't remove tutorial:* items from localstorage as they don't need to be shown to the user again
  const removeableItems = queryLocalStorageWithRegex(`^(?!${TUTORIAL_KEY_PREFIX}).*$`);
  Object.keys(removeableItems).forEach(key => localStorage.removeItem(key));
};

function queryLocalStorageWithRegex(query: string): { [key: string]: unknown } {
  const results: { [key: string]: unknown } = {};
  for (const i in localStorage) {
    if (Object.prototype.hasOwnProperty.call(localStorage, i)) {
      if (i.match(query)) {
        results[i] = localStorage.getItem(i);
      }
    }
  }

  return results;
}
