import gql from 'graphql-tag';
import { Section } from '../Dashboard';
import { UserDefaults } from './UserDefaults';

export interface UpdateUserDefaultSectionDashboardMutationVars {
  userId: string;
  dashboardId: string | null;
  section: keyof typeof Section;
}

export interface UpdateUserDefaultDashboardMutationData {
  updateUserDefaults: Pick<UserDefaults, '__typename' | 'id' | 'sectionDashboards'>;
}

export const UPDATE_USER_DEFAULTS_MUTATION_NAME = 'updateUserDefaultDashboard';

export const UPDATE_USER_DEFAULT_DASHBOARD = gql`
  mutation ${UPDATE_USER_DEFAULTS_MUTATION_NAME}($userId: ID!, $dashboardId: ID, $section: Section!) {
    updateUserDefaultDashboard (userId: $userId, dashboardId: $dashboardId, section: $section){
      id
      sectionDashboards {
        section
        dashboardId
      }
    }
  }
`;
