import gql from 'graphql-tag';
import { ITenant } from '../Tenant';
import { SubTenantStructuralEntity } from '../Structure';
import {
  CORE_STRUCTURAL_ENTITY_FIELDS,
  CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME
} from '../Fragments';
import { NodesConnection } from '../Paging';

export interface StructuralEntitiesQueryVars {
  depth: number;
  parentKey?: string;
}

export interface StructuralEntitiesQueryData {
  tenant: Pick<ITenant, '__typename' | 'id'> & {
    structuralEntities: NodesConnection<SubTenantStructuralEntity, 'StructuralEntities'>;
  };
}

export const STRUCTURAL_ENTITIES_QUERY = gql`
  ${CORE_STRUCTURAL_ENTITY_FIELDS}

  query StructuralEntities($depth: Int!, $parentKey: String) {
    tenant {
      id
      structuralEntities(
        filter: { depth: $depth, parentKey: $parentKey }
        orderDirection: ASCENDING
        orderBy: "displayName"
      ) {
        nodes {
          ...${CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME}
        }
      }
    }
  }
`;
