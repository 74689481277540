import { gql } from '@apollo/client';

export type ConfigExportedSubscriptionData = {
  configExported: {
    id: string;
    configExported: boolean;
    message: string;
  };
};

export const CONFIG_EXPORTED_SUBSCRIPTION = gql`
  subscription ConfigExported {
    configExported {
      id
      configExported
      message
    }
  }
`;
