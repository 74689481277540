import gql from 'graphql-tag';

export interface DeleteWidgetDefinitionMutationVars {
  widgetId: string;
}

export interface DeleteWidgetDefinitionMutationData {
  deleteWidget: boolean;
}

export const DELETE_WIDGET_DEFINITION_MUTATION_NAME = 'DeleteWidget';
export const DELETE_WIDGET_DEFINITION_MUTATION = gql`
  mutation ${DELETE_WIDGET_DEFINITION_MUTATION_NAME}($widgetId: ID!) {
    deleteWidget(id: $widgetId)
  }
`;
