import { Box, CircularProgress, Grid, Typography, TypographyProps, styled } from '@mui/material';
import { useEffect, useState } from 'react';

interface IProps {
  fullscreen?: boolean;
  showImage?: boolean;
  showText?: boolean;
  variant?: TypographyProps['variant'];
  text?: string;
  delay?: number;
}

const LoadingContainer = styled(Grid, {
  shouldForwardProp: prop => prop !== 'fullscreen'
})<{ fullscreen: boolean }>(({ fullscreen, theme }) => ({
  padding: theme.spacing(0.5),
  display: 'flex',
  top: 0,
  left: 0,
  height: '100%',
  width: '100%',
  opacity: 0.4,
  position: fullscreen ? 'fixed' : undefined
}));

LoadingContainer.defaultProps = {
  alignItems: 'center'
};

const LoadingContent = styled('div')({
  margin: 'auto',
  textAlign: 'center'
});

const Loading = ({
  fullscreen = false,
  showImage = true,
  showText = true,
  variant = 'h6',
  text = 'Please wait...',
  delay = 500
}: IProps) => {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowLoading(true);
    }, delay);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [delay]);

  if (!showLoading)
    return (
      <LoadingContainer fullscreen={fullscreen} data-testid="loading">
        <Box style={{ height: 20 }} />
      </LoadingContainer>
    );

  return (
    <LoadingContainer fullscreen={fullscreen} data-testid="loading">
      <LoadingContent aria-label="Loading">
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          {showImage && (
            <Grid item>
              <CircularProgress color="primary" size={25} />
            </Grid>
          )}
          {showText && (
            <Grid item>
              <Typography variant={variant} color="primary">
                {text}
              </Typography>
            </Grid>
          )}
        </Grid>
      </LoadingContent>
    </LoadingContainer>
  );
};

export default Loading;
