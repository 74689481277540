import gql from 'graphql-tag';
import { PerformActionResponse } from '../DataPoint';

export interface PerformActionMutationVars {
  dataVisualisationId: string;
  dataPointId: string;
  subjectId: string;
  action: PerformActionResponse;
}

export interface PerformActionResult {
  success: boolean;
  message: string;
}

export interface PerformActionMutationData {
  performDataPointAction: PerformActionResult;
}

export const PERFORM_ACTION_MUTATION_METHOD_NAME = 'PerformDataPointAction';
export const PERFORM_ACTION_MUTATION = gql`
  mutation ${PERFORM_ACTION_MUTATION_METHOD_NAME}(
    $dataVisualisationId: ID!,
    $dataPointId: ID!,
    $subjectId: ID!,
    $action: PerformActionInput!
  ) {
    performDataPointAction(
      dataVisualisationId: $dataVisualisationId,
      dataPointId: $dataPointId,
      subjectId: $subjectId,
      action: $action
    ) {
      success
      message
    }
  }
`;
