import {
  DashboardBar,
  ErrorMessage,
  Loading,
  SubjectBanner,
  SubjectContextProvider,
  WidgetGrid,
  useCurrentUser,
  useStructureContext,
  useSubjectContext
} from '@aireframe/components';
import { Section } from '@aireframe/graphql';
import { Grid } from '@mui/material';
import { createFileRoute } from '@tanstack/react-router';
import { useState } from 'react';
import { validateAuthenticated } from '../router';

const Record = () => {
  const { activeUser, loading: activeUserLoading } = useCurrentUser();
  const { activeTenant, loading } = useStructureContext();

  if (loading || activeUserLoading) {
    return <Loading fullscreen />;
  }

  if (!activeUser?.linkedSubjectId) {
    return <ErrorMessage message={activeTenant?.subjectNotLinkedErrorMessage} />;
  }

  return (
    <SubjectContextProvider id={activeUser.linkedSubjectId}>
      <RecordInner />
    </SubjectContextProvider>
  );
};

const RecordInner = () => {
  const [collectionId, setCollectionId] = useState<string | undefined>(undefined);
  const subjectContext = useSubjectContext();

  return (
    <>
      <DashboardBar
        section={Section.SUBJECT_SELF_ACCESS}
        dashboardId={collectionId}
        handleDashboardChange={setCollectionId}
      />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <SubjectBanner rowItems={3} initiallyExpanded={false} />
        </Grid>
        {collectionId ? (
          <Grid item xs={12}>
            <WidgetGrid
              numColumns={1}
              collectionId={collectionId}
              view={Section.SUBJECT_SELF_ACCESS}
              context={subjectContext}
            />
          </Grid>
        ) : null}
      </Grid>
    </>
  );
};

export const Route = createFileRoute('/')({
  component: Record,
  beforeLoad: async ({ context, location }) => validateAuthenticated({ context, location })
});
