import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import React from 'react';
import { booleanToString, stringToBoolean } from './BooleanFilter.func';
import FilterHeader from '../FilterHeader';
import { DataType, DataTypeValue } from '@aireframe/shared-types';

interface Props {
  title: string;
  values: Array<DataTypeValue<DataType.BOOLEAN>['booleanValue']>;
  onChange: (values: Array<DataTypeValue<DataType.BOOLEAN>['booleanValue']>) => void;
}

const options: Array<DataTypeValue<DataType.BOOLEAN>['booleanValue']> = [true, false, null];

export const BooleanFilter = ({ title, values, onChange }: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const value = stringToBoolean(event.target.name);

    if (checked) {
      onChange([...values, checked]);
    } else {
      onChange(values.filter(v => v !== value));
    }
  };

  const handleClear = () => {
    onChange([]);
  };

  return (
    <>
      <FilterHeader title={title} onClear={handleClear} />
      <Grid container sx={{ pl: 1 }} direction="column">
        {options.map(option => (
          <Grid item key={booleanToString(option)} xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  name={booleanToString(option)}
                  checked={values.includes(option)}
                  onChange={handleChange}
                  size="small"
                />
              }
              label={booleanToString(option)}
              slotProps={{
                typography: {
                  width: '100%'
                }
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
