import gql from 'graphql-tag';

export interface RefreshDataExtractMutationVars {
  dataExtractId: string;
}

export interface RefreshDataExtractMutationData {
  refreshDataExtract: boolean;
}

export const REFRESH_DATA_EXTRACT_MUTATION_NAME = 'RefreshDataExtractCache';
export const REFRESH_DATA_EXTRACT_MUTATION = gql`
  mutation ${REFRESH_DATA_EXTRACT_MUTATION_NAME}($dataExtractId: ID!) {
    refreshCache(dataExtractId: $dataExtractId)
  }
`;
