import {
  assertUnreachable,
  DataPointsFilter,
  DataTypeValue,
  ExtractByKey,
  FieldFilterInput
} from '@aireframe/shared-types';
import { StoreObject } from '@apollo/client';
import { removeTypename, ValueInput } from './Common';
import { InputField } from './CustomField';

export interface DataPointItem {
  __typename: 'FormattedDataPointItem';
  key: string;
  value: DataTypeValue;
  displayValue: string | null;
}

export type DataPoint = {
  __typename: 'FormattedDataPoint';
  id: string;
  dataPointItems: DataPointItem[];
  subjectId: string | null;
  sourceDataPointId: string | null;
  actions: DataPointAction[] | null;
};

export type MappedDataPoint = Omit<DataPoint, '__typename'> & {
  data: {
    [seriesId: string]: DataPointItem | undefined;
  };
};

export type ActionableDataPoint = MappedDataPoint & {
  subjectId: string;
  sourceDataPointId: string;
  actions: DataPointAction[];
};

export enum ActionType {
  READ = 'READ',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE'
}

export interface DataPointAction extends StoreObject {
  name: string;
  key: string;
  type: ActionType;
}

export interface DisplayAction extends DataPointAction {
  __typename: 'DisplayAction';
  isPrimary: boolean;
  url: string;
  sendToken: boolean;
}

export function isDisplayAction(action: DataPointAction): action is DisplayAction {
  return (action as DisplayAction).__typename === 'DisplayAction';
}

export interface PerformAction extends DataPointAction {
  __typename: 'PerformAction';
  description: string;
  inputFields: InputField[];
}

export interface PerformActionResponse extends Omit<PerformAction, '__typename' | 'inputFields'> {
  inputFields: (Omit<InputField, '__typename' | 'type'> & {
    type: string;
    value: ValueInput;
  })[];
}

export function isPerformAction(action: DataPointAction): action is PerformAction {
  return (action as PerformAction).__typename === 'PerformAction';
}

export type DataPointFilterInput = {
  and: Array<{
    or: Array<
      Pick<FieldFilterInput, 'visualisationId' | 'fieldKey'> &
        (
          | {
              boolean: ExtractByKey<FieldFilterInput['filter'], 'booleanValue'>;
            }
          | {
              string: ExtractByKey<FieldFilterInput['filter'], 'stringValue'>;
            }
          | {
              integer: ExtractByKey<FieldFilterInput['filter'], 'integerValue'>;
            }
          | {
              decimal: ExtractByKey<FieldFilterInput['filter'], 'decimalValue'>;
            }
          | {
              date: ExtractByKey<FieldFilterInput['filter'], 'dateValue'>;
            }
          | {
              time: ExtractByKey<FieldFilterInput['filter'], 'timeValue'>;
            }
          | {
              dateTime: ExtractByKey<FieldFilterInput['filter'], 'dateTimeValue'>;
            }
          | {
              userIdentifier: ExtractByKey<FieldFilterInput['filter'], 'userIdentifierValue'>;
            }
        )
    >;
  }>;
};

export function convertDataPointsFilterToInput(
  filter: DataPointsFilter | null | undefined
): DataPointFilterInput | null {
  if (!filter) {
    return null;
  }

  const cleanedFilter = removeTypename(filter);

  return {
    and: cleanedFilter.and.map(and => ({
      or: and.or.map(or => {
        const { filter, ...base } = or;

        if ('booleanValue' in filter) {
          return {
            ...base,
            boolean: filter
          };
        } else if ('stringValue' in filter) {
          return {
            ...base,
            string: filter
          };
        } else if ('integerValue' in filter) {
          return {
            ...base,
            integer: filter
          };
        } else if ('decimalValue' in filter) {
          return {
            ...base,
            decimal: filter
          };
        } else if ('timeValue' in filter) {
          return {
            ...base,
            time: filter
          };
        } else if ('dateValue' in filter) {
          return {
            ...base,
            date: filter
          };
        } else if ('dateTimeValue' in filter) {
          return {
            ...base,
            dateTime: filter
          };
        } else if ('userIdentifierValue' in filter) {
          return {
            ...base,
            userIdentifier: filter
          };
        } else {
          return assertUnreachable(filter);
        }
      })
    }))
  };
}
