import { IFrameRenderType, VisualisationRenderType } from '@aireframe/shared-types';

export function isIFrameType(
  widget: VisualisationRenderType | IFrameRenderType
): widget is IFrameRenderType {
  return widget.__typename === 'IFrameWidget';
}

export function isVisualisationRenderType(
  widget: VisualisationRenderType | IFrameRenderType
): widget is VisualisationRenderType {
  return widget.__typename === 'Visualisation';
}
