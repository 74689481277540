import { gql } from '@apollo/client';
import { StructureTypeTypename } from '../Structure/StructureType';

export const STRUCTURE_TYPE_FIELDS_FRAGMENT_NAME = 'StructureTypeFields';
export const STRUCTURE_TYPE_FIELDS = gql`
  fragment ${STRUCTURE_TYPE_FIELDS_FRAGMENT_NAME} on ${StructureTypeTypename} {
    key
    name
    subjectAssignable
    customFields {
      key
      name
      required
      type {
        key
        name
      }
    }
  }
`;
