import gql from 'graphql-tag';
import { DataSourceInstance } from './DataSource';
import { NodesConnection } from '../Paging';

export interface DataSourceInstancesQueryData {
  tenant: {
    __typename: string;
    id: string;
    dataSourceInstances: NodesConnection<
      Omit<DataSourceInstance, 'configuration'>,
      'DataSourceInstances'
    >;
  };
}

export const DATA_SOURCE_INSTANCES_QUERY_NAME = 'DataSourceInstances';
export const DATA_SOURCE_INSTANCES_QUERY = gql`
query ${DATA_SOURCE_INSTANCES_QUERY_NAME}  {
  tenant {
    id
    dataSourceInstances {
      nodes {
        id
        name
        key
        dataSource {
          type
        }
      }
    }
  }
}`;
