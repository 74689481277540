import { DataType, DataTypeValue } from './DataType';

export interface FilterableField {
  title: string;
  key: string;
  dataType: DataType;
}

export enum NumericComparator {
  Equals = 'EQUALS',
  LessThan = 'LESS_THAN',
  LessThanOrEqual = 'LESS_THAN_OR_EQUAL',
  GreaterThan = 'GREATER_THAN',
  GreaterThanOrEqual = 'GREATER_THAN_OR_EQUAL'
}

export enum StringComparator {
  Equals = 'EQUALS',
  NotEquals = 'NOT_EQUALS',
  Contains = 'CONTAINS',
  NotContains = 'NOT_CONTAINS',
  StartsWith = 'STARTS_WITH',
  NotStartsWith = 'NOT_STARTS_WITH',
  EndsWith = 'ENDS_WITH',
  NotEndsWith = 'NOT_ENDS_WITH'
}

type FieldFilterMap = {
  [DataType.STRING]: { value: DataTypeValue<DataType.STRING>; comparator: StringComparator };
  [DataType.INTEGER]: { value: DataTypeValue<DataType.INTEGER>; comparator: NumericComparator };
  [DataType.DECIMAL]: { value: DataTypeValue<DataType.DECIMAL>; comparator: NumericComparator };
  [DataType.DATE]: { value: DataTypeValue<DataType.DATE>; comparator: NumericComparator };
  [DataType.DATETIME]: { value: DataTypeValue<DataType.DATETIME>; comparator: NumericComparator };
  [DataType.TIME]: { value: DataTypeValue<DataType.TIME>; comparator: NumericComparator };
  [DataType.BOOLEAN]: { value: DataTypeValue<DataType.BOOLEAN> };
  [DataType.USERIDENTIFIER]: { value: DataTypeValue<DataType.USERIDENTIFIER> };
};

export type FieldFilter<DT extends DataType = DataType> = {
  id: number;
  key: string;
} & FieldFilterMap[DT];

export function isStringFieldFilter(value: FieldFilter): value is FieldFilter<DataType.STRING> {
  return value.value.dataType === DataType.STRING;
}

export function isIntegerFieldFilter(value: FieldFilter): value is FieldFilter<DataType.INTEGER> {
  return value.value.dataType === DataType.INTEGER;
}

export function isDecimalFieldFilter(value: FieldFilter): value is FieldFilter<DataType.DECIMAL> {
  return value.value.dataType === DataType.DECIMAL;
}

export function isBooleanFieldFilter(value: FieldFilter): value is FieldFilter<DataType.BOOLEAN> {
  return value.value.dataType === DataType.BOOLEAN;
}

export function isDateTimeFieldFilter(value: FieldFilter): value is FieldFilter<DataType.DATETIME> {
  return value.value.dataType === DataType.DATETIME;
}

export function isDateFieldFilter(value: FieldFilter): value is FieldFilter<DataType.DATE> {
  return value.value.dataType === DataType.DATE;
}

export function isTimeFieldFilter(value: FieldFilter): value is FieldFilter<DataType.TIME> {
  return value.value.dataType === DataType.TIME;
}

export function isUserIdentifierFieldFilter(
  value: FieldFilter
): value is FieldFilter<DataType.USERIDENTIFIER> {
  return value.value.dataType === DataType.USERIDENTIFIER;
}
