import { DataPointAction, ErrorParser, MappedDataPoint } from '@aireframe/graphql';
import { AxisType } from '@aireframe/shared-types';
import React from 'react';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import Loading from '../../../Loading/Loading';
import { NoDataMessage } from '../NoDataMessage';
import { useChartDataExtract } from './ChartDataExtract.hook';
import Graph from './Graph';
import PieChart from './PieChart';
import { WidgetErrorMessage } from './WidgetErrorMessage';
import { useVisualisationContext } from '../VisualisationContext';

interface BaseChartProps {
  height?: number;
  onActionClicked: (dataPoint: MappedDataPoint, action: DataPointAction) => void;
}

const Chart: React.FC<BaseChartProps> = ({ height, onActionClicked }) => {
  const {
    definition: { axes }
  } = useVisualisationContext();
  const { data, loading, error } = useChartDataExtract();

  if (loading && !data) return <Loading />;
  if (error) return <WidgetErrorMessage errors={ErrorParser.parse(error)} />;

  if (!data || data.length === 0) {
    return <NoDataMessage />;
  }

  if (axes.every(axis => axis.type === AxisType.RADIAL)) {
    return <PieChart data={data} height={height} />;
  } else if (axes.every(axis => [AxisType.X, AxisType.Y].includes(axis.type))) {
    return <Graph data={data} height={height} onActionClicked={onActionClicked} />;
  } else {
    return <ErrorMessage message="Sorry, this chart is not yet impleted" />;
  }
};

export default Chart;
