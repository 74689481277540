import { gql } from '@apollo/client';
import { VISUALISATION_FRAGMENT, VISUALISATION_FRAGMENT_NAME } from '../Fragments';
import { IFrame, Visualisation } from '@aireframe/shared-types';

export type GetWidgetByIdVars = {
  id: string;
};

export type GetWidgetByIdData = {
  widget: IFrame | Visualisation | null;
};

export const GET_WIDGET_BY_ID_QUERY_NAME = 'GetWidgetById';
export const GET_WIDGET_BY_ID_QUERY = gql`
    ${VISUALISATION_FRAGMENT}
    query ${GET_WIDGET_BY_ID_QUERY_NAME}($id: ID!) {
        widget(id: $id) {
            title
            key
            forPortal
            ... on IFrameWidget {
                uri,
                sendBearerAsAQueryParameter
            }
            ... on Visualisation {
            ...${VISUALISATION_FRAGMENT_NAME}
                inputDimensions {
                    key
                    dataType
                    collatedDimensions {
                        key
                        dataExtractId
                    }
                }
                pipelineDefinition {
                    filters {
                        id
                        expression
                    }
                    mappers {
                        id
                        expression
                        outputKey
                    }
                    group {
                        groupByKeys
                        aggregators {
                            id
                            inputKeys
                            type
                            outputKey
                        }
                    }
                    aggregators {
                        id
                        inputKeys
                        type
                        outputKey
                    }
                }
            }
        }
    }
`;
