import { Link, LinkProps } from '@mui/material';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  LinkComponent
} from '@tanstack/react-router';
import React from 'react';

const WrappedLink = React.forwardRef<
  HTMLAnchorElement,
  { routerLinkProps: RouterLinkProps } & LinkProps
>(({ routerLinkProps, ...linkProps }, outerRef) => (
  <Link
    {...linkProps}
    // eslint-disable-next-line react/display-name
    component={React.forwardRef<HTMLAnchorElement, Exclude<LinkComponent<'a'>, RouterLinkProps>>(
      (props, ref) => (
        <RouterLink {...props} {...routerLinkProps} ref={ref} />
      )
    )}
    ref={outerRef}
  />
));
WrappedLink.displayName = 'WrappedLink';

export { WrappedLink };
