export type DictionaryArrayType<V> = { [key: string | number | symbol]: Array<V> };
export type DictionaryOfArraysKeys<T extends DictionaryArrayType<unknown>> = keyof T;

type Location<T extends DictionaryArrayType<unknown>> = { key: keyof T; index: number };
class DictionaryOfArrays<V, T extends DictionaryArrayType<V> = DictionaryArrayType<V>> {
  private source: Array<V>;
  readonly data: T;

  constructor(data: T) {
    this.data = data;
    this.source = Object.values(this.data).flat();
  }

  private updateAll = () => (this.source = Object.values(this.data).flat());

  public get all() {
    return this.source;
  }

  get = (key: keyof T): Array<V> => this.data[key];

  getItem(location: Location<T>): V | undefined {
    const sourceFields = this.data[location.key];

    if (sourceFields === undefined) return undefined;
    return sourceFields[location.index];
  }

  addItem(destination: Location<T>, item: V): void {
    const destinationItems = this.data[destination.key];
    if (destinationItems === undefined)
      throw new Error(`${String(destination.key)} is not a key of dictionary`);

    destinationItems.splice(destination.index, 0, item);

    this.updateAll();
  }

  removeItem(location: Location<T>): V {
    const removedItem = this.data[location.key].splice(location.index, 1)[0];
    this.updateAll();
    return removedItem;
  }

  moveItem(source: Location<T>, destination: Location<T>): void {
    const item = this.removeItem(source);
    this.addItem(destination, item);
  }

  replaceItem(destination: Location<T>, item: V): void {
    this.data[destination.key][destination.index] = item;
    this.updateAll();
  }

  locationOf(item: V, comparer?: (a: V, b: V) => boolean): Location<T> | undefined {
    let location: Location<T> | undefined = undefined;
    Object.keys(this.data).some(key => {
      let index: number;
      if (comparer) {
        index = this.data[key].findIndex(val => comparer(val, item));
      } else {
        index = this.data[key].indexOf(item);
      }

      if (index !== -1) {
        location = { key, index };
        return true;
      }
      return false;
    });

    return location;
  }
}

export default DictionaryOfArrays;
