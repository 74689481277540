import gql from 'graphql-tag';
import { DataExtractMinimal } from './DataExtract';
import { EdgesConnection, OrderableConnectionQueryVars } from '../Paging';

export type DataExtractsQueryVars = OrderableConnectionQueryVars;

export interface DataExtractsQueryData {
  tenant: {
    __typename: string;
    id: string;
    dataExtracts: EdgesConnection<DataExtractMinimal, 'DataExtracts', 'DataExtract'>;
  };
}

export const DATA_EXTRACTS_QUERY_NAME = 'DataExtracts';
export const DATA_EXTRACTS_QUERY = gql`
  query ${DATA_EXTRACTS_QUERY_NAME}(
    $after: String
    $first: Int
    $before: String
    $last: Int
    $orderBy: String
    $orderDirection: ListSortDirection
  ) {
    tenant {
      id
      dataExtracts(
        after: $after
        first: $first
        before: $before
        last: $last
        orderBy: $orderBy
        orderDirection: $orderDirection
      ) {
        edges {
          node {
            id
            title
            createdAt
            tenantDataSource {
              id
              name
            }
          }
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
