import { DataPointAction, ErrorParser, MappedDataPoint } from '@aireframe/graphql';
import { isTableDisplayOptions } from '@aireframe/shared-types';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';
import Loading from '../../../Loading/Loading';
import SanitizedHTML from '../../../SanitizedHTML/SanitizedHTML';
import { WidgetErrorMessage } from '../Chart/WidgetErrorMessage';
import { NoDataMessage } from '../NoDataMessage';
import { useVisualisationContext } from '../VisualisationContext';
import DataTableRow from './DataTableRow';
import { useTableDataExtract } from './TableDataExtract.hook';

export interface DataTableProps {
  onActionClicked: (dataPoint: MappedDataPoint, action: DataPointAction) => void;
}

const DataTable: React.FC<DataTableProps> = ({ onActionClicked }) => {
  const { definition } = useVisualisationContext();
  const { data, loading, error } = useTableDataExtract();

  if (loading && !data) return <Loading />;
  if (error) return <WidgetErrorMessage errors={ErrorParser.parse(error)} />;

  if (!data || data.length === 0) {
    return <NoDataMessage />;
  }

  const anyActions = data.some(dataPoint => dataPoint.actions && dataPoint.actions.length > 0);
  const showColumnHeadings =
    isTableDisplayOptions(definition.displayOptions) &&
    definition.displayOptions.showColumnHeadings;

  return (
    <TableContainer>
      <Table size="small">
        {showColumnHeadings && (
          <TableHead>
            <TableRow>
              {definition.series
                .filter(s => !s.hidden)
                .map((series, idx) => {
                  return (
                    <TableCell
                      scope="col"
                      align={idx > 0 ? 'center' : 'left'}
                      key={series.key}
                      style={{ fontWeight: 'bold' }}>
                      <SanitizedHTML html={series.renderedName} />
                    </TableCell>
                  );
                })}
              {anyActions && (
                <TableCell scope="col" align="right" style={{ fontWeight: 'bold' }}>
                  Actions
                </TableCell>
              )}
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {data.map(dataPoint => {
            return (
              <DataTableRow
                series={definition.series}
                key={dataPoint.id}
                dataPoint={dataPoint}
                onActionSelect={onActionClicked}
                renderEmptyActionsCell={anyActions}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataTable;
