import gql from 'graphql-tag';
import Subject from '../Subject';
import { CUSTOM_FIELD_FRAGMENT, CUSTOM_FIELD_FRAGMENT_NAME } from '../../Fragments';

interface UpdateSubjectInput {
  customFields: { fieldKey: string; value: string | null }[];
}

export interface UpdateSubjectMutationVars {
  id: string;
  subject: UpdateSubjectInput;
}

export interface UpdateSubjectMutationData {
  updateSubject: Pick<Subject, 'id' | 'customFieldValues' | 'subjectAccessInviteStatus'>;
}

export const UPDATE_SUBJECT_MUTATION_NAME = 'UpdateSubject';
export const UPDATE_SUBJECT_MUTATION = gql`
  ${CUSTOM_FIELD_FRAGMENT}

  mutation ${UPDATE_SUBJECT_MUTATION_NAME}($id: ID!, $subject: SubjectInput!) {
    updateSubject(id: $id, input: $subject) {
      id
      customFieldValues {
        value
        customField {
          ...${CUSTOM_FIELD_FRAGMENT_NAME}
        }
      }
      subjectAccessInviteStatus {
        flag,
        inviteUrl
      }
    }
  }
`;
