import { gql } from '@apollo/client';

export type DeleteDataSourceInstanceMutationVars = {
  id: string;
};

export type DeleteDataSourceInstanceMutationData = {
  deleteDataSourceInstance: boolean;
};

export const DELETE_DATA_SOURCE_INSTANCE_MUTATION_NAME = 'DeleteDataSourceInstance';
export const DELETE_DATA_SOURCE_INSTANCE_MUTATION = gql`
  mutation ${DELETE_DATA_SOURCE_INSTANCE_MUTATION_NAME}($id: ID!) {
    deleteDataSourceInstance(id: $id)
  }
`;
