import { sortBy } from 'lodash-es';
import { useRef, useState } from 'react';
import { FilterIconButton, FiltersList } from '../../../../Filter';
import { PopupMenu } from '../../../../PopupMenu';
import { useVisualisationContext } from '../../VisualisationContext';

export const VisualisationFilter = () => {
  const { definition, filter, setFilter } = useVisualisationContext();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      <FilterIconButton
        onClick={() => setOpen(o => !o)}
        filterActive={filter.hasFilter()}
        ref={anchorRef}
      />
      {open && (
        <PopupMenu
          onClose={() => setOpen(false)}
          title="Filter"
          showTitle={false}
          popoverProps={{
            anchorEl: anchorRef.current,
            transformOrigin: { vertical: 'top', horizontal: 'center' },
            anchorOrigin: { vertical: 'center', horizontal: 'center' },
            slotProps: {
              paper: {
                sx: {
                  pt: 1
                },
                style: {
                  width: '40vw',
                  maxHeight: '60vh',
                  overflowX: 'hidden',
                  overflowY: 'auto'
                }
              }
            }
          }}>
          <FiltersList
            fields={sortBy(definition.series, s => s.order).map(series => ({
              title: series.name,
              key: series.key,
              dataType: series.dataType
            }))}
            value={filter}
            onChange={newFilter => {
              setFilter(newFilter);
              setOpen(false);
            }}
            cancelText="Cancel"
            onCancel={() => setOpen(false)}
          />
        </PopupMenu>
      )}
    </>
  );
};
