import { DataPointAction, MappedDataPoint, isDisplayAction } from '@aireframe/graphql';
import { RenderedSeries } from '@aireframe/shared-types';
import ActionsMenuIcon from '@mui/icons-material/MoreHoriz';
import { TableCell, TableRow, Tooltip } from '@mui/material';
import React, { useRef, useState } from 'react';
import PopupIconButton from '../../../PopupIconButton/PopupIconButton';
import SanitizedHTML from '../../../SanitizedHTML/SanitizedHTML';
import DataPointActionsMenu from '../Actions/DataPointActionsMenu';

interface DataTableRowProps {
  dataPoint: MappedDataPoint;
  onActionSelect: (dataPoint: MappedDataPoint, action: DataPointAction) => void;
  series: RenderedSeries[];
  renderEmptyActionsCell?: boolean;
}

const DataTableRow: React.FC<DataTableRowProps> = ({
  dataPoint,
  onActionSelect,
  series,
  renderEmptyActionsCell = false
}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const onOpenActionMenuClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const primaryAction = dataPoint.actions?.find(
    action => isDisplayAction(action) && action.isPrimary
  );
  const rowStyles = primaryAction
    ? { cursor: 'pointer', overflow: 'scroll' }
    : { overflow: 'scroll' };
  const hasActions = dataPoint.actions && dataPoint.actions.length > 0;

  const popupRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  return (
    <TableRow
      hover
      style={rowStyles}
      onContextMenu={e => onOpenActionMenuClick(e)}
      onClick={() => {
        if (primaryAction) onActionSelect(dataPoint, primaryAction);
      }}>
      {series
        .filter(s => !s.hidden)
        .map((s, idx) => (
          <Tooltip title={s.renderedName} key={s.key}>
            <TableCell scope="row" align={idx > 0 ? 'center' : 'left'}>
              <SanitizedHTML html={dataPoint.data[s.key]?.displayValue} />
            </TableCell>
          </Tooltip>
        ))}
      {hasActions ? (
        <TableCell align="right">
          <PopupIconButton
            iconButtonProps={{ size: 'small', disableRipple: true }}
            onClick={e => {
              onOpenActionMenuClick(e);
            }}
            ariaLabel="Open Actions Menu"
            tooltip="Open Actions Menu"
            popupRef={popupRef}
            buttonRef={buttonRef}>
            <ActionsMenuIcon />
          </PopupIconButton>
          {anchorEl && (
            <DataPointActionsMenu
              actions={dataPoint.actions ?? []}
              anchorEl={anchorEl}
              onClick={action => {
                onActionSelect(dataPoint, action);
                setAnchorEl(null);
              }}
              onClose={() => {
                setAnchorEl(null);
              }}
              popupRef={popupRef}
            />
          )}
        </TableCell>
      ) : renderEmptyActionsCell ? (
        <TableCell />
      ) : null}
    </TableRow>
  );
};

export default DataTableRow;
