import React, { useContext } from 'react';
import { Subject } from '@aireframe/graphql';
import { useSubject } from './useSubject';
import { SubjectErrorMessage } from './SubjectErrorMessage';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Loading from '../Loading/Loading';

export interface ISubjectContext {
  subject: Pick<Subject, 'id' | 'customFieldValues' | 'avatarHash' | '__typename'>;
}

export const SubjectContext = React.createContext<ISubjectContext | undefined>(undefined);

type InternalIdProps = {
  children: React.ReactNode;
  id: string;
};

type ExternalIdProps = {
  children: React.ReactNode;
  externalId: string;
};

export const SubjectContextProvider: React.FC<InternalIdProps | ExternalIdProps> = ({
  children,
  ...ids
}) => {
  const { loading, subject, error } = useSubject(
    (ids as InternalIdProps).id
      ? { id: (ids as InternalIdProps).id }
      : { externalId: (ids as ExternalIdProps).externalId }
  );

  if (loading) return <Loading />;
  if (error) return <SubjectErrorMessage error={error} />;
  if (!subject) return <ErrorMessage />;

  return <SubjectContext.Provider value={{ subject }}>{children}</SubjectContext.Provider>;
};

export function useSubjectContext(): ISubjectContext {
  const subjectContext = useContext(SubjectContext);
  if (!subjectContext) throw new Error('Subject context has not been registered');

  return subjectContext;
}
