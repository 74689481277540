import { DataType, DataTypeValue } from '@aireframe/shared-types';

export const booleanToString = (booleanValue: DataTypeValue<DataType.BOOLEAN>['booleanValue']) => {
  if (booleanValue === null) {
    return 'Not defined';
  }
  if (booleanValue) {
    return 'Yes';
  }
  if (!booleanValue) {
    return 'No';
  }
};

export const stringToBoolean = (value: string): DataTypeValue<DataType.BOOLEAN>['booleanValue'] => {
  if (value === 'Yes') {
    return true;
  }
  if (value === 'No') {
    return false;
  }
  return null;
};
