import { isEqual } from 'lodash-es';
import React, { useEffect, useMemo, useRef } from 'react';

export function useDeepEqualsMemoize<T>(value: T): T {
  const ref = useRef<T>(value);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepMemo<T>(callback: () => T, dependencies: React.DependencyList): T {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo(callback, dependencies.map(useDeepEqualsMemoize));
}

export function useDeepEqualsEffect(
  callback: React.EffectCallback,
  dependencies?: React.DependencyList
): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies?.map(useDeepEqualsMemoize));
}
