import gql from 'graphql-tag';
import { SubjectBannerItem } from 'graphql/src/lib/Subject/SubjectBannerItem';
import { FormFieldInput, SubjectComputedField } from '../CustomField';
import {
  TENANT_FIELDS_FRAGMENT,
  TENANT_FIELDS_FRAGMENT_NAME,
  TenantCustomFieldGroupBase
} from '../Tenant';

type CustomFieldGroupInputType = Omit<TenantCustomFieldGroupBase, '__typename'> & {
  customFields: Array<SubjectBannerItem>;
};

export interface UpdateSubjectConfigurationInput {
  subjectName: string;
  locationName: string;
  showRoles: boolean;
  externalIdMapping?: string;
  liquidLookupExpression?: string;
  inputFields: Array<FormFieldInput & { id: string }>;
  computedFields: Array<Omit<SubjectComputedField, '__typename'>>;
  subjectDataSourceId: string;
  primaryFieldKey: string;
  customFieldGroups: Array<CustomFieldGroupInputType>;
  avatarIsEnabled: boolean;
}

export interface UpdateSubjectConfigurationVars {
  input: UpdateSubjectConfigurationInput;
}

export const UPDATE_SUBJECT_CONFIG_MUTATION_NAME = 'UpdateSubjectConfiguration';
export const UPDATE_SUBJECT_CONFIG_MUTATION = gql`
  ${TENANT_FIELDS_FRAGMENT}

  mutation ${UPDATE_SUBJECT_CONFIG_MUTATION_NAME}($input: UpdateTenantInput!) {
    updateTenant(input: $input) {
      ...${TENANT_FIELDS_FRAGMENT_NAME}
      
      tenantSubjectProvider {
        dataSource {
          id
          name
        }
        configuration {
          subjectMapping {
            customFieldMappings {
              mapping
              customField {
                id
              }
            }
          }
        }
      }
    }
  }
`;
