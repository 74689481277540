import { FormHelperText, Input, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import parsePhoneNumber, { getCountries, getCountryCallingCode } from 'libphonenumber-js';
import { useEffect, useState } from 'react';
import { Controller, FieldError, useFormContext } from 'react-hook-form';

const regionNames = new Intl.DisplayNames(['en'], { type: 'region' });

const countryCallingCodes = getCountries()
  .map(countryCode => {
    const callingCode = getCountryCallingCode(countryCode);
    const regionName = regionNames.of(countryCode);

    return {
      regionName,
      countryCode,
      callingCode: callingCode.toString(),
      formattedCallingCode: `+${callingCode}`
    };
  })
  .sort((a, b) => parseInt(a.callingCode) - parseInt(b.callingCode));

const PhoneNumberControl = ({
  value,
  disabled,
  onChange,
  error,
  fieldName,
  required,
  label
}: {
  disabled?: boolean;
  value: string | undefined;
  onChange: (value: string) => void;
  error: FieldError | undefined;
  fieldName: string;
  required: boolean;
  label: string;
}) => {
  const [countryCode, setCountryCode] = useState<string>('');
  const [nationalNumber, setNationalNumber] = useState<string>();

  useEffect(() => {
    if (value) {
      const parsedPhoneNumber = parsePhoneNumber(value);

      if (parsedPhoneNumber) {
        setCountryCode(`+${parsedPhoneNumber.countryCallingCode}`);
        setNationalNumber(parsedPhoneNumber.nationalNumber.toString());
      }
    }
  }, [value]);

  const labelId = `${fieldName}-label`;
  return (
    <>
      <InputLabel
        id={labelId}
        error={error !== undefined}
        required={required}
        style={{ textAlign: 'left' }}
        shrink>
        {label}
      </InputLabel>
      <Input
        startAdornment={
          <Select
            displayEmpty
            renderValue={value => {
              if (value === '') {
                return <Typography sx={{ fontWeight: 'light' }}>Country Code</Typography>;
              }
              return value;
            }}
            sx={{ mr: 1 }}
            disabled={disabled}
            label="Country Code"
            value={countryCode}
            onChange={e => {
              onChange(`${e.target.value}${nationalNumber}`);
              setCountryCode(e.target.value);
            }}
            error={error !== undefined}
            slotProps={{ root: { 'aria-labelledby': labelId } }}>
            {countryCallingCodes.map(({ countryCode, formattedCallingCode, regionName }) => {
              return (
                <MenuItem key={countryCode} value={formattedCallingCode}>
                  {formattedCallingCode} ({regionName})
                </MenuItem>
              );
            })}
          </Select>
        }
        type="number"
        fullWidth
        placeholder="National Number"
        slotProps={{ input: { 'aria-labelledby': labelId } }}
        disabled={disabled}
        onChange={e => {
          onChange(`${countryCode}${e.target.value}`);
          setNationalNumber(e.target.value);
        }}
        value={nationalNumber}
        error={error !== undefined}
      />
      <FormHelperText error={error !== undefined} required={required}>
        {error?.message}
      </FormHelperText>
    </>
  );
};

const PhoneNumberField = ({
  required,
  label,
  fieldName,
  disabled
}: {
  required: boolean;
  label: string;
  fieldName: string;
  disabled?: boolean;
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      render={({ field: { value, onChange }, fieldState: { error } }) => (
        <PhoneNumberControl
          value={value}
          onChange={onChange}
          error={error}
          fieldName={fieldName}
          required={required}
          label={label}
          disabled={disabled}
        />
      )}
      control={control}
      name={fieldName}
    />
  );
};

export default PhoneNumberField;
