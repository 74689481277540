import { gql, useSubscription } from '@apollo/client';
import { Dashboard } from '../Dashboard';
import { StructuralEntity } from '../Structure';

type MultiSubjectViewDataUpdatedSubscriptionVariables = {
  structuralEntityKey: string | null;
  dashboardId: string;
};

type MultiSubjectViewDataUpdatedSubscriptionData = {
  multiSubjectViewDataUpdated: {
    visualisationId: string;
  };
};

const MULTI_SUBJECT_VIEW_DATA_CHANGED_SUBSCRIPTION = gql`
  subscription MultiSubjectViewDataChanged($structuralEntityKey: ID, $dashboardId: ID!) {
    multiSubjectViewDataUpdated(
      structuralEntityKey: $structuralEntityKey
      dashboardId: $dashboardId
    ) {
      visualisationId
    }
  }
`;

export const useMultiSubjectViewDataUpdatedSubscription = (
  dashboard: Dashboard | null | undefined,
  structuralEntity: StructuralEntity | null,
  onUpdate: (
    data: MultiSubjectViewDataUpdatedSubscriptionData['multiSubjectViewDataUpdated']
  ) => void,
  skip?: boolean
) => {
  useSubscription<
    MultiSubjectViewDataUpdatedSubscriptionData,
    MultiSubjectViewDataUpdatedSubscriptionVariables
  >(MULTI_SUBJECT_VIEW_DATA_CHANGED_SUBSCRIPTION, {
    onData: ({ data: { data } }) => {
      if (data?.multiSubjectViewDataUpdated) {
        onUpdate(data.multiSubjectViewDataUpdated);
      }
    },
    variables: {
      structuralEntityKey: structuralEntity?.key ?? null,
      dashboardId: dashboard?.id as string
    },
    skip: skip || !dashboard
  });
};
