import { DataType, FieldFilter, StringComparator } from '@aireframe/shared-types';
import { FormControl, Grid, MenuItem, Select, TextField, TextFieldProps } from '@mui/material';
import FilterHeader from '../FilterHeader';

const StringFilter = ({
  title,
  value: filter,
  onChange,
  onRemove,
  textFieldProps
}: {
  title: string;
  value: FieldFilter<DataType.STRING>;
  onChange: (value: FieldFilter<DataType.STRING>) => void;
  onRemove: () => void;
  textFieldProps?: Omit<TextFieldProps, 'type' | 'defaultValue' | 'onBlur'>;
}) => {
  return (
    <Grid container direction="column" spacing={1} sx={{ p: 1 }}>
      <Grid item>
        <FilterHeader title={title} onClear={onRemove} />
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <Select
            inputProps={{ 'aria-label': 'Filter type' }}
            value={filter.comparator}
            onChange={e => onChange({ ...filter, comparator: e.target.value as StringComparator })}
            style={{ textAlign: 'left' }}>
            <MenuItem value={StringComparator.Contains}>Contains</MenuItem>
            <MenuItem value={StringComparator.Equals}>Equals</MenuItem>
            <MenuItem value={StringComparator.StartsWith}>Starts with</MenuItem>
            <MenuItem value={StringComparator.EndsWith}>Ends With</MenuItem>
            <MenuItem value={StringComparator.NotContains}>Does Not Contain</MenuItem>
            <MenuItem value={StringComparator.NotEquals}>Does Not Equal</MenuItem>
            <MenuItem value={StringComparator.NotStartsWith}>Does Not Start With</MenuItem>
            <MenuItem value={StringComparator.NotEndsWith}>Does Not End With</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <TextField
          label={title}
          {...textFieldProps}
          type="text"
          defaultValue={filter.value.stringValue}
          onBlur={event =>
            event.target.value === ''
              ? onChange({ ...filter, value: { dataType: DataType.STRING, stringValue: null } })
              : onChange({
                  ...filter,
                  value: { dataType: DataType.STRING, stringValue: event.target.value }
                })
          }
        />
      </Grid>
    </Grid>
  );
};

export default StringFilter;
