import { DataPointsFilter } from './DataPointFilter';
import { DataType } from './DataType';
import { InputDimension, PipelineDefinition } from './Pipeline';
import { Widget } from './WidgetTypes';

export enum ChartType {
  LINE = 'LINE',
  BAR = 'BAR',
  STACKED_BAR = 'STACKED_BAR',
  PIE = 'PIE',
  SCATTER = 'SCATTER'
}

export enum AxisType {
  X = 'X',
  Y = 'Y',
  Z = 'Z',
  RADIAL = 'RADIAL'
}

export enum VisualisationType {
  LIQUID = 'LIQUID',
  TABLE = 'TABLE',
  GRAPH = 'GRAPH',
  PIE_CHART = 'PIE_CHART'
}

export enum PieChartStyle {
  FULL = 'FULL',
  DOUGHNUT = 'DOUGHNUT'
}

export interface ReferenceValue {
  value: string;
  label: string;
}

export interface Series {
  key: string;
  name: string;
  dataType: DataType;
  liquidFormatter: string | null;
  liquidColourFormatter: KeyValuePair[] | null;
  chartType: ChartType | null;
  colour: string | null;
  order: number;
  referenceValues: Array<ReferenceValue> | null;
  hidden: boolean;
}

export interface RenderedSeries extends Series {
  renderedName: string;
}

export interface Axis {
  id: string;
  type: AxisType;
  label: string;
  series: Array<Pick<Series, 'key'>>;
}

export interface SteppedAxis extends Axis {
  min: number | Date;
  max: number | Date;
  step: number | Date;
  __typename: 'ContinuousAxis' | 'DiscreteAxis';
}

export interface Visualisation extends Widget {
  __typename: 'Visualisation';
  inputDimensions: Array<InputDimension>;
  pipelineDefinition: PipelineDefinition;
  type: VisualisationType;
  series: Series[];
  axes: Axis[];
  defaultOrdering: {
    key: string;
    orderDirection: 'ASCENDING' | 'DESCENDING';
  };
  displayOptions: DisplayOptions | null;
  defaultFilter: DataPointsFilter | null;
}

export type VisualisationRenderType = Omit<
  Visualisation,
  'inputDimensions' | 'pipelineDefinition' | 'series'
> & {
  isCategorical: boolean;
  isCacheable: boolean;
  series: RenderedSeries[];
  dataExtractIds: string[];
};

export type DisplayOptions =
  | TableDisplayOptions
  | LiquidDisplayOptions
  | PieChartDisplayOptions
  | GraphChartDisplayOptions;

export type TableDisplayOptions = {
  __typename: 'TableDisplayOption';
  showColumnHeadings: boolean;
};

export type LiquidDisplayOptions = {
  __typename: 'LiquidDisplayOption';
  liquidHtml: string | null;
};

export type PieChartDisplayOptions = {
  __typename: 'PieChartDisplayOption';
  showLabel: boolean;
  showLegend: boolean;
  style: PieChartStyle;
  liquidColourFormatting: KeyValuePair[] | null;
};

export const DefaultPieChartDisplayOptions: PieChartDisplayOptions = {
  __typename: 'PieChartDisplayOption',
  style: PieChartStyle.FULL,
  showLabel: true,
  showLegend: true,
  liquidColourFormatting: []
};

export type GraphChartDisplayOptions = {
  __typename: 'GraphChartDisplayOption';
  liquidColourFormatting: KeyValuePair[] | null;
};

type KeyValuePair = {
  key: string;
  value: string;
};

export type ChartDisplayOptions = PieChartDisplayOptions | GraphChartDisplayOptions | null;

export function isTableDisplayOptions(
  displayOptions: DisplayOptions | null
): displayOptions is TableDisplayOptions {
  return displayOptions?.__typename === 'TableDisplayOption';
}

export function isLiquidDisplayOptions(
  displayOptions: DisplayOptions | null
): displayOptions is LiquidDisplayOptions {
  return displayOptions?.__typename === 'LiquidDisplayOption';
}

export function isPieChartDisplayOptions(
  displayOptions: DisplayOptions | null
): displayOptions is PieChartDisplayOptions {
  return displayOptions?.__typename === 'PieChartDisplayOption';
}

export function isGraphChartDisplayOptions(
  displayOptions: DisplayOptions | null
): displayOptions is GraphChartDisplayOptions {
  return displayOptions?.__typename === 'GraphChartDisplayOption';
}

export function isChartDisplayOptions(
  displayOptions: DisplayOptions | null
): displayOptions is ChartDisplayOptions {
  return isPieChartDisplayOptions(displayOptions) || isGraphChartDisplayOptions(displayOptions);
}
