import { gql } from '@apollo/client';
import { AireVaultValueReference } from '../AireVault';

export type ValueKeysQueryVariables = {
  scope: string;
};

export type ValueKeysQueryData = {
  valueKeys: Array<AireVaultValueReference>;
};

export const VARIABLE_KEYS_QUERY_NAME = 'variableKeys';
export const VARIABLE_KEYS_QUERY = gql`
    query ${VARIABLE_KEYS_QUERY_NAME}($scope: String!) {
      valueKeys: variableKeys(scope: $scope) {
            key
            urn
        }
    }`;

export const SECRET_KEYS_QUERY_NAME = 'secretKeys';
export const SECRET_KEYS_QUERY = gql`
    query ${SECRET_KEYS_QUERY_NAME}($scope: String!) {
      valueKeys: secretKeys(scope: $scope) {
            key
            urn
        }
    }`;
