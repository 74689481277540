import gql from 'graphql-tag';

export interface ClearStructureCacheMutationData {
  clearStructureCache: boolean;
}

export const CLEAR_STRUCTURE_CACHE_MUTATION_NAME = 'ClearStructureCache';
export const CLEAR_STRUCTURE_CACHE_MUTATION = gql`
  mutation ${CLEAR_STRUCTURE_CACHE_MUTATION_NAME} {
    clearStructureCache
  }
`;
