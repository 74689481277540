import gql from 'graphql-tag';
import { DataExtractMinimal } from './DataExtract';

export interface UpdateDataExtractMutationVars {
  dataExtractId: string;
  title: string;
  description: string | null;
}

export interface UpdateDataExtractMutationData {
  dataExtract: DataExtractMinimal & {
    description: string | null;
    extractJSON: string | null;
    subjectIdentifierConfiguration: {
      useInternalId: boolean;
      useExternalId: boolean;
      customFieldKey: string | null;
    };
  };
}

export const UPDATE_DATA_EXTRACT_MUTATION_NAME = 'UpdateDataExtract';

export const UPDATE_DATA_EXTRACT_MUTATION = gql`
  mutation ${UPDATE_DATA_EXTRACT_MUTATION_NAME}($dataExtractId: ID!, $title: String, $description: String) {
    updateDataExtract(dataExtractId: $dataExtractId, title: $title, description: $description) {
      title
      description
    }
  }
`;
