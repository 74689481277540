import { DataPointAction } from '@aireframe/graphql';
import { List, ListItem, ListItemButton } from '@mui/material';
import { PopperMenu } from '../../../PopperMenu';

const DataPointActionsMenu: React.FC<{
  actions: DataPointAction[];
  anchorEl: Element;
  onClick: (action: DataPointAction) => void;
  onClose: () => void;
  popupRef: React.LegacyRef<HTMLDivElement>;
}> = ({ actions, anchorEl, onClick, onClose, popupRef }) => {
  return (
    <PopperMenu open onClose={() => onClose()} anchorEl={anchorEl} ref={popupRef}>
      <List aria-label="Actions Menu">
        {actions.map(action => (
          <ListItem
            key={action.key}
            disablePadding
            onClick={e => {
              e.stopPropagation();
              onClick(action);
            }}>
            <ListItemButton>{action.name}</ListItemButton>
          </ListItem>
        ))}
      </List>
    </PopperMenu>
  );
};

export default DataPointActionsMenu;
