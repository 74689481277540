import { ITree } from '@aireframe/shared-types';
import { arrayToTree } from 'performant-array-to-tree';
import TreeModel from 'tree-model';
import { SubTenantStructuralEntity } from '../Structure/StructuralEntity';
import ITenant from '../Tenant/ITenant';

// Missing ts definiton
declare module 'tree-model' {
  interface Node<T> {
    model: T;
    parent?: Node<T>;
    children: Node<T>[];
  }
}

declare module 'performant-array-to-tree' {
  export function arrayToTree<T>(items: T[], config?: Partial<Config>): T[];
}

export type NodeType = SubTenantStructuralEntity | ITenant;
type PredicateNodeType = Pick<NodeType, '__typename' | 'key'>;

function removeChildren<T extends object>(entity: T): T {
  if (!('children' in entity)) return entity;

  const { ...rest } = entity;
  delete rest.children;

  return rest;
}

export function walkUp(
  root: TreeModel.Node<NodeType>,
  predicate: (entity: NodeType) => boolean,
  stopCondition?: (entity: NodeType) => boolean
): NodeType[] {
  const entities: NodeType[] = [];

  if (predicate(root.model)) entities.push(removeChildren(root.model));
  if ((stopCondition && stopCondition(root.model)) || !root.parent) return entities;
  return [...entities, ...walkUp(root.parent, predicate, stopCondition)];
}

export function searchTreeForEntities(
  structureTree: TreeModel.Node<NodeType>,
  predicate: (entity: NodeType) => boolean,
  stopCondition?: (entity: NodeType) => boolean,
  includeRoot = true
): NodeType[] {
  const entities: NodeType[] = [];
  structureTree.walk({ strategy: 'breadth' }, node => {
    if (!includeRoot && node === structureTree) return true;

    if (stopCondition && stopCondition(node.model)) {
      return false;
    }
    if (predicate(node.model)) {
      entities.push(removeChildren(node.model));
    }

    return true;
  });

  return entities;
}

export type StructureTree = ITree<NodeType, PredicateNodeType>;

export const createStructureTree = (
  tenant: ITenant,
  entities: SubTenantStructuralEntity[]
): StructureTree => {
  const structureArray = arrayToTree(entities, {
    id: 'key',
    parentId: 'parentKey',
    childrenField: 'children',
    dataField: null
  });

  const structureTree = new TreeModel().parse<NodeType>({
    ...tenant,
    children: structureArray
  });

  const getEntities = (
    predicate: (entity: NodeType) => boolean,
    stopCondition?: (entity: NodeType) => boolean
  ) => {
    return searchTreeForEntities(structureTree, predicate, stopCondition);
  };

  const getEntity = (predicate: (entity: NodeType) => boolean) => {
    const result = structureTree.first({ strategy: 'breadth' }, node => predicate(node.model));

    if (!result) return undefined;
    return removeChildren(result.model);
  };

  const walkFromEntity = (
    entity: PredicateNodeType,
    startPoint: 'ROOT' | 'NODE',
    direction: 'UP' | 'DOWN',
    predicate: (entity: NodeType) => boolean,
    stopCondition?: (entity: NodeType) => boolean,
    includeStartPoint = false
  ): NodeType[] => {
    const entityNode = structureTree.first(
      { strategy: 'breadth' },
      node => node.model.__typename === entity.__typename && node.model.key === entity.key
    );
    if (!entityNode) {
      return [];
    }

    if (direction === 'UP') {
      if (startPoint === 'ROOT') {
        return predicate(structureTree.model) ? [structureTree.model] : [];
      } else if (startPoint === 'NODE') {
        if (includeStartPoint) return walkUp(entityNode, predicate, stopCondition);
        else if (entityNode.parent) return walkUp(entityNode.parent, predicate, stopCondition);
        return [];
      }
    } else if (direction === 'DOWN') {
      if (startPoint === 'NODE')
        return searchTreeForEntities(entityNode, predicate, stopCondition, includeStartPoint);
      else if (startPoint === 'ROOT') return getEntities(predicate, stopCondition);
    }

    throw new Error('Invalid options supplied');
  };

  const getParent = (entity: PredicateNodeType) => {
    return structureTree.first(
      { strategy: 'breadth' },
      node => node.model.__typename === entity.__typename && node.model.key === entity.key
    )?.parent?.model;
  };

  const getChildren = (entity: PredicateNodeType) => {
    const node = structureTree.first(
      { strategy: 'breadth' },
      node => node.model.__typename === entity.__typename && node.model.key === entity.key
    );
    return node?.children.map(child => removeChildren(child.model)) || [];
  };

  return {
    getEntities,
    getEntity,
    walkFromEntity,
    getParent,
    getChildren
  };
};
