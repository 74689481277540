export const constants = {
  FieldTypeInterfaceTypename: 'IGQLFieldType',

  TenantCustomFieldInterfaceTypename: 'ISubjectCustomField',
  TenantComputedFieldTypename: 'SubjectComputedField',
  TenantInputFieldTypename: 'SubjectInputField',

  DataVisualisationInterfaceTypename: 'DataVisualisationInterface',
  TableTypename: 'Table',
  ChartTypename: 'Chart',

  VisualisationDataPointTypename: 'VisualisationDataPointsConnection',
  FormattedDataPointTypename: 'FormattedDataPoint',
  FormattedDataPointItemTypename: 'FormattedDataPointItem',

  TenantSubjectProviderTypename: 'TenantSubjectProvider'
} as const;
