import gql from 'graphql-tag';
import { StructureType } from '../Structure';
import { FormFieldInput } from '../CustomField';
import {
  STRUCTURE_TYPE_FIELDS,
  STRUCTURE_TYPE_FIELDS_FRAGMENT_NAME
} from './StructureTypeFragment';

export interface StructureTypeInput {
  key: string;
  name: string;
  subjectAssignable: boolean;
  customFields: Array<Omit<FormFieldInput, 'mapping' | 'unique'>>;
}

export interface CreateStructureTypeMutationVars {
  structureType: StructureTypeInput;
}

export interface CreateStructureTypeMutationData {
  createStructureType: StructureType & { isUsed: boolean };
}

export const CREATE_STRUCTURE_TYPE_MUTATION_NAME = 'CreateStructureType';
export const CREATE_STRUCTURE_TYPE_MUTATION = gql`
  ${STRUCTURE_TYPE_FIELDS}
  mutation ${CREATE_STRUCTURE_TYPE_MUTATION_NAME}($structureType: StructureTypeInput!) {
    createStructureType(input: $structureType) {
      ...${STRUCTURE_TYPE_FIELDS_FRAGMENT_NAME}
      isUsed
    }
  }
`;
