import gql from 'graphql-tag';
import { StructureType } from '../Structure';
import {
  STRUCTURE_TYPE_FIELDS,
  STRUCTURE_TYPE_FIELDS_FRAGMENT_NAME
} from './StructureTypeFragment';
import { INPUT_FIELD_FRAGMENT, INPUT_FIELD_FRAGMENT_NAME } from '../Fragments/InputFieldFragment';
import { InputField } from '../CustomField';

export interface StructureTypeQueryVars {
  structureTypeKey: string;
}

export interface StructureTypeQueryData {
  tenant: {
    __typename: 'Tenant';
    id: string;
    structureType: StructureType & { customFields: Array<InputField>; isUsed: boolean };
  };
}

export const STRUCTURE_TYPE_QUERY_NAME = 'StructureType';
export const STRUCTURE_TYPE_QUERY = gql`
  ${STRUCTURE_TYPE_FIELDS}
  ${INPUT_FIELD_FRAGMENT}
  query ${STRUCTURE_TYPE_QUERY_NAME}($structureTypeKey: ID!) {
    tenant {
      id
      structureType(key: $structureTypeKey) {
        ...${STRUCTURE_TYPE_FIELDS_FRAGMENT_NAME}
        isUsed
        customFields {
          ...${INPUT_FIELD_FRAGMENT_NAME}
        }
      }
    }
  }
`;
