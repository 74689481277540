import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  DialogTitle
} from '@mui/material';

export interface ConfirmationDialogProps {
  open?: boolean;
  title: string;
  content?: React.ReactNode;
  contentText?: string;
  cancelButtonText?: string;
  confirmButtonText?: string;
  onClose: () => void;
  onConfirm: () => void;
  disableActions?: boolean;
}

const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open = true,
  title,
  content,
  contentText,
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  onClose,
  onConfirm,
  disableActions = false
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ mb: 1 }}>{contentText}</DialogContentText>
        {content}
      </DialogContent>
      <DialogActions>
        <Grid container direction="row" justifyContent="space-between">
          <Grid item>
            <Button onClick={onClose} disabled={disableActions}>
              {cancelButtonText}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onConfirm} disabled={disableActions}>
              {confirmButtonText}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
