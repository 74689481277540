export enum TenantFeatureKey {
  SubjectAccess = 'SUBJECT_ACCESS',
  SubjectWrite = 'SUBJECT_WRITE',
  SubjectRead = 'SUBJECT_READ'
}

export type TenantFeature = {
  __typename: string;
  featureKey: TenantFeatureKey;
  isEnabled: boolean;
};
