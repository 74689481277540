import gql from 'graphql-tag';
import {
  CORE_STRUCTURAL_ENTITY_FIELDS,
  CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME
} from '../Fragments';
import { StructuralEntityInput } from './CreateStructuralEntityMutation';
import { StructureEntityQueryData } from './GetStructureEntityQuery';

export interface UpdateStructuralEntityMutationVars {
  structuralEntity: StructuralEntityInput;
}

export interface UpdateStructuralEntityMutationData {
  updateStructuralEntity: StructureEntityQueryData['structuralEntity'];
}

export const UPDATE_STRUCTURAL_ENTITY_MUTATION_NAME = 'UpdateStructuralEntity';
export const UPDATE_STRUCTURAL_ENTITY_MUTATION = gql`
  ${CORE_STRUCTURAL_ENTITY_FIELDS}

  mutation ${UPDATE_STRUCTURAL_ENTITY_MUTATION_NAME}(
    $structuralEntity: StructuralEntityInput!
  ) {
    updateStructuralEntity(input: $structuralEntity) {
      ...${CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME}
      parent {
        key
        displayName
      }
      hasChildren
      hasSubjectsAssigned
      isUsedByInboundSubjectConfiguration
    }
  }
`;
