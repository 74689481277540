import { gql } from '@apollo/client';

export const DASHBOARD_FRAGMENT_NAME = 'DashboardFragment';
export const DASHBOARD_FRAGMENT = gql`
  fragment ${DASHBOARD_FRAGMENT_NAME} on Dashboard {
    id
    key
    title
    forPortal
    items {
      widget {    
        id
      }
      positionIndex
    }
  }
`;
