import { filterXSS } from 'xss';

interface Props
  extends Omit<
    React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>,
    'dangerouslySetInnerHTML'
  > {
  html?: string | null;
  additionalWhiteList?: XSS.IWhiteList | undefined;
}

const SanitizedHTML: React.FC<Props> = ({ html, additionalWhiteList, ...rest }) => {
  const sanitizedHTML = html
    ? filterXSS(html, {
        whiteList: {
          em: [],
          br: [],
          b: [],
          u: [],
          s: [],
          i: [],
          small: [],
          strong: [],
          sub: [],
          sup: [],
          span: ['style', 'title'],
          a: ['style', 'target', 'href'],
          ...additionalWhiteList
        },
        css: {
          whiteList: {
            'background-color': true,
            border: true,
            'border-radius': true,
            color: true,
            display: /^inline|block|inline-block$/,
            'font-weight': true,
            'font-size': true,
            'letter-spacing': true,
            'min-width': true,
            padding: true,
            'text-align': true,
            'text-decoration': true,
            'text-shadow': true,
            'text-transform': true,
            'text-emphasis': true
          }
        }
      })
    : '';

  return <span {...rest} dangerouslySetInnerHTML={{ __html: sanitizedHTML }} />;
};

export default SanitizedHTML;
