import { Box, Container, Link, styled } from '@mui/material';

const StyledBox = styled('footer')(({ theme }) => ({
  backgroundColor: '#33444e',
  color: '#889aa5',
  padding: theme.spacing(1),
  textAlign: 'center'
}));

const StyledContainer = styled(Container)({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between'
});

const StyledGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  gap: '16px',
  [theme.breakpoints.between('sm', 'lg')]: {
    marginLeft: '69px',
    boxSizing: 'border-box'
  }
}));

const StyledGroupItem = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.8rem'
});

const StyledLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: '0.8rem',
  '&:hover': {
    color: theme.palette.common.white
  }
}));

export const Footer = () => {
  return (
    <StyledBox>
      <StyledContainer>
        <StyledGroup>
          <StyledGroupItem>
            <StyledLink
              href="https://docs.aireframe.com"
              color="inherit"
              target="_blank"
              rel="noopener">
              Usage Guide
            </StyledLink>
          </StyledGroupItem>
        </StyledGroup>

        <StyledGroup>
          <StyledGroupItem sx={{ color: 'accent.default' }}>
            &copy; {new Date().getFullYear()} AireInnovate Limited. All rights reserved
          </StyledGroupItem>
          <StyledGroupItem>
            <StyledLink
              href="https://aireinnovate.com/legal/product-privacy-policy"
              color="inherit"
              target="_blank"
              rel="noopener">
              Privacy Policy
            </StyledLink>
          </StyledGroupItem>

          <StyledGroupItem>
            <StyledLink
              href="https://aireinnovate.com/legal/terms-and-conditions"
              color="inherit"
              target="_blank"
              rel="noopener">
              Terms of Use
            </StyledLink>
          </StyledGroupItem>
        </StyledGroup>
      </StyledContainer>
    </StyledBox>
  );
};
