import gql from 'graphql-tag';
import { SubTenantStructuralEntity } from './StructuralEntity';
import {
  CORE_STRUCTURAL_ENTITY_FIELDS,
  CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME
} from '../Fragments';
import { NodesConnection } from '../Paging';

export interface AccessibleStructureQueryVariables {
  depth?: number;
  parentKey?: string;
  searchTerm?: string;
}

export const TenantUserTypename = 'TenantUser';
export interface AccessibleStructureQueryData {
  me: {
    __typename: typeof TenantUserTypename;
    id: string;
    accessibleStructuralEntities: NodesConnection<
      SubTenantStructuralEntity & {
        parent: Pick<SubTenantStructuralEntity, '__typename' | 'key' | 'parentKey'> | null;
      },
      'AccessibleStructuralEntities'
    > | null;
  };
}

export const ACCESSIBLE_STRUCTURE_QUERY_NAME = 'UserAccessibleStructuralEntities';
export const ACCESSIBLE_STRUCTURE_QUERY = gql`
  ${CORE_STRUCTURAL_ENTITY_FIELDS}

  query ${ACCESSIBLE_STRUCTURE_QUERY_NAME}(
    $depth: Int
    $parentKey: String
    $searchTerm: String
  ) {
    me {
      id
      accessibleStructuralEntities(
        filter: { depth: $depth, parentKey: $parentKey, searchTerm: $searchTerm }
        orderBy: "displayName"
        orderDirection: ASCENDING
      ) {
        nodes {
          ...${CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME}
          parent {
            key
            parentKey
          }
        }
      }
    }
  }
`;
