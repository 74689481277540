import gql from 'graphql-tag';

export type FeatureKey = never;
export type FeatureSet = Array<FeatureKey>;

export interface FeatureTogglesQueryData {
  appConfig: {
    featureSet: {
      enabledFeatures: FeatureSet;
      disabledFeatures: FeatureSet;
    };
  };
}

export const FEATURE_SET_QUERY_NAME = 'FeatureSet';
export const FEATURE_SET_QUERY = gql`
  query ${FEATURE_SET_QUERY_NAME} {
    appConfig {
      featureSet {
          enabledFeatures
          disabledFeatures
      }
    }
  }
`;
