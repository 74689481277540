import {
  ActionableDataPoint,
  DataPoint,
  DataPointAction,
  MappedDataPoint
} from '@aireframe/graphql';
import { DataType, getDataTypeValue, Series } from '@aireframe/shared-types';
import Decimal from 'decimal.js';
import { XAXisComponentOption } from 'echarts';
import { useMemo } from 'react';
import { isDateTimeSeries } from './Utils';

export interface ChartProps {
  height?: number;
  data: MappedDataPoint[];
  onActionClicked?: (dataPoint: MappedDataPoint, action: DataPointAction) => void;
}

export function isActionableDataPoint(
  dataPoint: MappedDataPoint
): dataPoint is ActionableDataPoint {
  return (dataPoint as ActionableDataPoint).actions !== undefined;
}

export function mapDataPoints(dataPoints: DataPoint[]): MappedDataPoint[] {
  return dataPoints.map(dp => ({
    ...dp,
    data: dp.dataPointItems.reduce(
      (acc, item) => ({
        ...acc,
        [item.key]: item
      }),
      {}
    )
  }));
}

export function parseValue(valueText: string | null, dataType: DataType) {
  if (valueText !== null) {
    switch (dataType) {
      case DataType.BOOLEAN:
        return valueText === 'true';
      case DataType.INTEGER:
        return parseInt(valueText);
      case DataType.DECIMAL:
        return parseFloat(valueText);
      default:
        return valueText;
    }
  }
  return null;
}

const isCloseToZero = (min: Decimal, max: Decimal) => {
  if (min.lessThan(0)) {
    return min;
  }

  const range = max.minus(min);
  const threshold = min.minus(range.times(0.1));

  return threshold.lessThan(0);
};

export const getXAxisDomain = (
  axisSeries: Pick<Series, 'dataType'>[]
): { min: XAXisComponentOption['min']; max: XAXisComponentOption['max'] } => {
  if (axisSeries.every(isDateTimeSeries)) {
    return { min: 'dataMin', max: 'dataMax' };
  }

  return {
    min: value => {
      const min = new Decimal(value.min);
      const max = new Decimal(value.max);
      const shouldRound = min.isInteger() && max.isInteger();

      const result = max.equals(min)
        ? min.minus(min.times(0.25))
        : min.minus(max.minus(min).times(0.1));

      if (isCloseToZero(result, max)) {
        return 0;
      }

      return shouldRound ? result.floor().valueOf() : result.valueOf();
    },
    max: value => {
      const min = new Decimal(value.min);
      const max = new Decimal(value.max);
      const shouldRound = min.isInteger() && max.isInteger();

      const result = max.equals(min)
        ? max.add(max.times(0.25))
        : max.add(max.minus(min).times(0.1));

      return shouldRound ? result.ceil().valueOf() : result.valueOf();
    }
  };
};

export const getFormatterForSeries = (
  series: Pick<Series, 'dataType'>
): ((tickItem: number | string) => string) | string => {
  switch (series.dataType) {
    case DataType.DATE:
      return '{yyyy}-{MM}-{dd}';
    case DataType.DATETIME:
      return '{yyyy}-{MM}-{dd} {hh}:{mm}';
    case DataType.TIME:
      return '{hh}:{mm}';
    default:
      return tickItem => `${tickItem}`;
  }
};

export type EchartsDataSet = {
  source: {
    [key: string]: null | string | number | boolean | Date;
  }[];
};

export const useEchartsDataset = (data: MappedDataPoint[]): EchartsDataSet => {
  return useMemo(
    () => ({
      source: data.map(dataPoint =>
        Object.entries(dataPoint.data).reduce(
          (dict, [key, dpi]) => ({
            ...dict,
            [key]:
              dpi && dpi.value.dataType !== DataType.USERIDENTIFIER
                ? getDataTypeValue(dpi.value)
                : null
          }),
          {} as {
            [key: keyof MappedDataPoint['data']]: null | string | number | boolean | Date;
          }
        )
      )
    }),
    [data]
  );
};
