import { Liquid } from 'liquidjs';

const LiquidEngine = new Liquid({
  cache: true,
  strictFilters: true,
  strictVariables: true
});
LiquidEngine.registerFilter('age', (_, __: string) => 'age');
LiquidEngine.registerFilter('round_to_next', (_, __: string) => 'round_to_next');
LiquidEngine.registerFilter('plus_time', (_, __: string) => 'round_to_next');
LiquidEngine.registerFilter('cui_date_time', _ => 'cui_date_time');
LiquidEngine.registerFilter('cui_date', _ => 'cui_date');
LiquidEngine.registerFilter('cui_time', _ => 'cui_time');

export { LiquidEngine };
