import gql from 'graphql-tag';
import { SubTenantStructuralEntity } from './StructuralEntity';
import {
  CORE_STRUCTURAL_ENTITY_FIELDS,
  CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME
} from '../Fragments';

export interface StructureEntityQueryVars {
  structureEntityKey: string;
}

export interface StructureEntityQueryData {
  structuralEntity:
    | (SubTenantStructuralEntity & {
        parent: Pick<SubTenantStructuralEntity, '__typename' | 'key' | 'displayName'> | null;
        hasChildren: boolean;
        hasSubjectsAssigned: boolean;
        isUsedByInboundSubjectConfiguration: boolean;
      })
    | null;
}

export const STRUCTURE_ENTITY_QUERY_NAME = 'StructuralEntity';
export const STRUCTURE_ENTITY_QUERY = gql`
  ${CORE_STRUCTURAL_ENTITY_FIELDS}
  query ${STRUCTURE_ENTITY_QUERY_NAME}($structureEntityKey: ID!) {
    structuralEntity(key: $structureEntityKey) {
      ...${CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME}
      parent {
        key
        displayName
      }
      hasChildren
      hasSubjectsAssigned
      isUsedByInboundSubjectConfiguration
    }
  }
`;
