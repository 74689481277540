import { User } from '@aireframe/shared-types';
import gql from 'graphql-tag';
import { UserFilterInput } from './UserFilterInput';
import { ITenant } from '../Tenant';
import { NodesConnection } from '../Paging';

export type UserIdentifierSearchUser = Pick<User, '__typename' | 'displayName' | 'id'>;

export type UserIdentifierSearchResponse = {
  tenant: Pick<ITenant, '__typename' | 'id'> & {
    users: NodesConnection<UserIdentifierSearchUser>;
  };
};

export type UserIdentifierSearchQueryVars = {
  filter: UserFilterInput;
};

export const USER_IDENTIFIER_SEARCH_QUERY_NAME = 'SearchUserIdentifiers';

export const USER_IDENTIFIER_SEARCH_QUERY = gql`
    query ${USER_IDENTIFIER_SEARCH_QUERY_NAME}($filter: UserFilterInput!) {
      tenant {
        id
        users(filter: $filter) {
          nodes {
            displayName
            id
          }
        }
      }
    }
  `;
