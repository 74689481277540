import {
  SubjectCustomFieldValue,
  TenantCustomFieldGroup,
  getCustomFieldValue
} from '@aireframe/graphql';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TabContext, TabPanel } from '@mui/lab';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  Paper,
  Tab,
  Tabs,
  Typography,
  useMediaQuery
} from '@mui/material';
import { orderBy } from 'lodash-es';
import React, { useState } from 'react';
import { Can } from '../../Authorisation';
import ExpandIconButton from '../../ExpandIconButton/ExpandIconButton';
import SanitizedHTML from '../../SanitizedHTML/SanitizedHTML';
import { useStructureContext } from '../../Structure/StructureContext';
import { theme } from '../../Theme/Theme';
import SubjectAvatar from '../Avatar/SubjectAvatar';
import { SubjectAvatarUpload } from '../Avatar/SubjectAvatarUpload';
import { useSubjectContext } from '../SubjectContext';

const ContentFieldContainer: React.FC<{
  children?: React.ReactNode;
  rowItems: number;
}> = ({ children, rowItems }) => {
  const width = Math.ceil(12 / rowItems);

  return (
    <Grid
      style={{ textAlign: 'left' }}
      container
      item
      xs={Math.max(width, 6)}
      md={Math.max(width, 4)}
      lg={Math.max(width, 3)}
      direction="column"
      alignItems="flex-start">
      {children}
    </Grid>
  );
};

const CustomFieldItem: React.FC<{
  customFieldValue: SubjectCustomFieldValue;
  rowItems: number;
}> = ({ customFieldValue, rowItems }) => {
  return (
    <ContentFieldContainer rowItems={rowItems}>
      <Grid item>
        <Typography
          color="primary"
          align="left"
          sx={{ color: theme => theme.palette.primary.main }}>
          {customFieldValue.customField.name}
        </Typography>
      </Grid>
      <Grid item>
        <Typography align="left" style={{ color: '#585858', wordBreak: 'break-word' }}>
          <SanitizedHTML html={customFieldValue.value} />
        </Typography>
      </Grid>
    </ContentFieldContainer>
  );
};

const GroupPanel = ({
  group,
  customFieldValues,
  rowItems
}: {
  group: TenantCustomFieldGroup;
  customFieldValues: Array<SubjectCustomFieldValue>;
  rowItems: number;
}) => {
  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      sx={{ padding: theme => theme.spacing(1, 2) }}
      data-testid={group.name}>
      {orderBy(group.customFields, 'positionIndex', 'asc').map(cf => {
        const fieldValue = getCustomFieldValue(customFieldValues, cf.field);

        if (!fieldValue) return null;

        return (
          <CustomFieldItem customFieldValue={fieldValue} key={cf.field.key} rowItems={rowItems} />
        );
      })}
    </Grid>
  );
};

const shouldRenderGroup = (
  group: TenantCustomFieldGroup,
  customFieldValues: Array<SubjectCustomFieldValue>
) => group.customFields.some(cf => getCustomFieldValue(customFieldValues, cf.field));

type GroupsProps = {
  rowItems: number;
  groups: Array<TenantCustomFieldGroup>;
  customFieldValues: Array<SubjectCustomFieldValue>;
};
const GroupsAccordion = ({ rowItems, groups, customFieldValues }: GroupsProps) => {
  return (
    <>
      {groups.map(group => (
        <Accordion
          defaultExpanded={group.showByDefault}
          disableGutters
          variant={'outlined'}
          elevation={0}
          key={group.key}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon fontSize="large" />}
            aria-controls={`${group.key}-content`}>
            <Typography fontWeight={'bold'}>{group.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <GroupPanel group={group} customFieldValues={customFieldValues} rowItems={rowItems} />
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

const GroupsTabs = ({ rowItems, groups, customFieldValues }: GroupsProps) => {
  const [selectedTab, setSelectedTab] = useState<string>(
    groups.find(group => group.showByDefault)?.key ?? ''
  );

  return (
    <TabContext value={selectedTab}>
      <Tabs value={selectedTab} onChange={(_, tab) => setSelectedTab(tab)}>
        {groups.map(group => (
          <Tab
            label={group.name}
            value={group.key}
            key={group.key}
            style={{
              textTransform: 'none',
              fontWeight: 'regular',
              borderBottom: 1,
              borderColor: 'divider'
            }}
          />
        ))}
      </Tabs>
      {groups.map(group => (
        <TabPanel value={group.key} style={{ padding: 0 }} key={group.key}>
          <GroupPanel group={group} customFieldValues={customFieldValues} rowItems={rowItems} />
        </TabPanel>
      ))}
    </TabContext>
  );
};

const SubjectBanner: React.FC<{
  rowItems: number;
  initiallyExpanded?: boolean;
  onManageProfileClicked?: (subject: ReturnType<typeof useSubjectContext>['subject']) => void;
}> = ({ rowItems, initiallyExpanded = true, onManageProfileClicked }) => {
  const { subject } = useSubjectContext();
  const { activeTenant } = useStructureContext();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [expanded, setExpanded] = useState<boolean>(initiallyExpanded);

  if (!activeTenant?.customFields) return null;
  const { groups, primaryField } = activeTenant.customFields;

  const groupsToShow = orderBy(groups, 'bannerPositionIndex', 'asc').filter(group =>
    shouldRenderGroup(group, subject.customFieldValues)
  );
  return (
    <Grid container item xs={12} direction="column" data-testid="Subject Banner">
      <Paper>
        <Grid
          container
          item
          xs={12}
          direction="row"
          alignItems="center"
          justifyContent="center"
          sx={{ padding: theme => theme.spacing(1), position: 'relative' }}>
          <Grid
            container
            item
            xs={12}
            spacing={1}
            display="flex"
            alignItems="center"
            sx={{ paddingLeft: theme => `${theme.spacing(1)} !important` }}>
            {!expanded && activeTenant.avatarIsEnabled && (
              <Grid item>
                <SubjectAvatar size="small" subject={subject} />
              </Grid>
            )}
            <Grid item>
              {primaryField && (
                <Typography align="left" color="primary" variant="h4" key={primaryField.field.key}>
                  <SanitizedHTML
                    html={getCustomFieldValue(subject.customFieldValues, primaryField.field)?.value}
                  />
                </Typography>
              )}
            </Grid>
          </Grid>
          {groupsToShow.length > 0 && (
            <Grid item style={{ position: 'absolute', right: 10, top: 12 }}>
              <ExpandIconButton
                expanded={expanded}
                setExpanded={setExpanded}
                aria-label={expanded ? 'Collapse Banner' : 'Expand Banner'}
              />
            </Grid>
          )}
        </Grid>
        {expanded && (
          <>
            <Divider />
            <Grid container spacing={1}>
              {activeTenant.avatarIsEnabled && (
                <Grid item display="flex" flexGrow={isSmallScreen ? 1 : 0} margin={1}>
                  <Grid
                    item
                    paddingX={1}
                    display="flex"
                    flexGrow={isSmallScreen ? 1 : 0}
                    flexDirection="column"
                    alignItems="center">
                    <SubjectAvatarUpload subject={subject} />
                    {!isSmallScreen && onManageProfileClicked && (
                      <Can I="edit" a="Subject">
                        <Button
                          size="small"
                          variant={'outlined'}
                          endIcon={<CallMadeIcon />}
                          sx={{ textTransform: 'none', borderRadius: 8 }}
                          onClick={() => onManageProfileClicked(subject)}>
                          Manage Profile
                        </Button>
                      </Can>
                    )}
                  </Grid>
                  {!isSmallScreen && <Divider orientation={'vertical'} />}
                </Grid>
              )}
              <Grid item flexGrow={1} sm={12} md={true}>
                {groupsToShow.length > 0 && (
                  <>
                    {groupsToShow.length === 1 ? (
                      <GroupPanel
                        group={groupsToShow[0]}
                        customFieldValues={subject.customFieldValues}
                        rowItems={rowItems}
                      />
                    ) : isSmallScreen ? (
                      <GroupsAccordion
                        rowItems={rowItems}
                        groups={groupsToShow}
                        customFieldValues={subject.customFieldValues}
                      />
                    ) : (
                      <GroupsTabs
                        rowItems={rowItems}
                        groups={groupsToShow}
                        customFieldValues={subject.customFieldValues}
                      />
                    )}
                  </>
                )}
              </Grid>
            </Grid>
          </>
        )}
      </Paper>
    </Grid>
  );
};

export default SubjectBanner;
