import { InputField, isMultipleChoiceInputFieldType } from '@aireframe/graphql';
import { FormControl, FormHelperText, InputLabel, Select, TextField } from '@mui/material';
import { Fragment } from 'react';
import { Controller, FieldError, FieldErrors, useFormContext, useFormState } from 'react-hook-form';
import CustomDateField from './CustomDateField';
import PhoneNumberField from './PhoneNumberField';
import UserIdentifierLookUpField from './UserIdentifier/UserIdentifierLookupField';

interface CustomFieldProps {
  disabled?: boolean;
  customField: Omit<InputField, '__typename'>;
  subjectId?: string;
  inputNamePrefix?: string;
}

const getNestedError = (
  fullKey: string,
  errors: FieldErrors | undefined
): FieldError | undefined => {
  function isFieldError(errors: FieldErrors | FieldError): errors is FieldError {
    return (errors as FieldError).message !== undefined;
  }

  let currentError = errors;

  for (const key of fullKey.split('.')) {
    if (currentError === undefined || currentError[key] === undefined) {
      break;
    }

    if (isFieldError(currentError[key])) {
      return currentError[key];
    }

    currentError = currentError[key];
  }

  return undefined;
};

export default function CustomFieldInput({
  disabled,
  customField,
  subjectId,
  inputNamePrefix
}: CustomFieldProps): JSX.Element {
  const { key, name, type, required } = customField;
  const fullKey = `${inputNamePrefix ?? ''}${key}`;

  const { register, control } = useFormContext();
  const { errors } = useFormState({
    control,
    name: fullKey
  });

  if (type.key === 'phoneNumber') {
    return (
      <PhoneNumberField
        required={customField.required}
        label={customField.name}
        fieldName={fullKey}
        disabled={disabled}
      />
    );
  }

  if (type.key === 'userIdentifier') {
    return (
      <Controller
        control={control}
        name={fullKey}
        render={({ field: { value, onChange }, fieldState: { error } }) => (
          <UserIdentifierLookUpField
            value={value}
            onChange={onChange}
            autoCompleteProps={{
              fullWidth: true,
              label: name,
              helperText: error?.message,
              required,
              disabled,
              error: error !== undefined
            }}
            includeInactive={false}
            withAccessToSubjectId={subjectId}
          />
        )}
      />
    );
  }

  const error = getNestedError(fullKey, errors);
  const props = {
    name: key,
    error: error !== undefined,
    fullWidth: true,
    required,
    disabled
  };

  if (isMultipleChoiceInputFieldType(type)) {
    return (
      <FormControl {...props} variant="standard">
        <InputLabel>{name}</InputLabel>
        <Controller
          render={({ field }) => (
            <Select native {...props} inputProps={{ 'aria-label': name }} {...field}>
              <Fragment>
                {<option value=""></option>}
                {type.options.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </Fragment>
            </Select>
          )}
          name={fullKey}
          control={control}
        />
        <FormHelperText>{error?.message}</FormHelperText>
      </FormControl>
    );
  }

  const textFieldProps = {
    ...props,
    helperText: error?.message,
    InputLabelProps: {
      required,
      shrink: true
    },
    inputProps: {
      ...register(fullKey)
    },
    label: name
  };

  switch (type.key) {
    case 'text':
    case 'email':
      return <TextField {...textFieldProps} />;
    case 'number':
      return <TextField {...textFieldProps} type="number" />;
    case 'date':
      return (
        <TextField
          type="date"
          {...textFieldProps}
          slotProps={{
            input: { inputComponent: CustomDateField },
            htmlInput: { ...textFieldProps.inputProps, 'aria-label': name }
          }}
        />
      );
    case 'time':
      return (
        <TextField
          type="time"
          {...textFieldProps}
          slotProps={{
            htmlInput: { ...textFieldProps.inputProps, 'aria-label': name }
          }}
        />
      );
    case 'dateTime':
      return (
        <TextField
          type="datetime-local"
          {...textFieldProps}
          slotProps={{
            htmlInput: { ...textFieldProps.inputProps, 'aria-label': name }
          }}
        />
      );
    default:
      throw new Error(`Custom field with key ${type.key} not available`);
  }
}
