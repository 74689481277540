import { DisplayAction, Section } from '@aireframe/graphql';
import { RightSideOverlay } from '../../../Layout';
import { SubjectContextProvider } from '../../../Subject';
import IFrameWrapper from '../../../IFrameWrapper/IFrameWrapper';
import { useVisualisationContext } from '../VisualisationContext';

interface Props {
  onClose: () => void;
  subjectId: string;
  action: DisplayAction;
}

const DisplayActionContainer: React.FC<Props> = ({ subjectId, action, onClose }) => {
  const { section } = useVisualisationContext();

  if (section === Section.LOCATION || section === Section.SUBJECT_LIST) {
    return (
      <RightSideOverlay onClose={onClose} openFullScreen>
        <SubjectContextProvider id={subjectId}>
          <IFrameWrapper
            url={action.url}
            showSubjectLink
            onClosed={onClose}
            sendToken={action.sendToken}
          />
        </SubjectContextProvider>
      </RightSideOverlay>
    );
  }

  return (
    <RightSideOverlay onClose={onClose} openFullScreen>
      <IFrameWrapper
        url={action.url}
        showSubjectLink={section === Section.SUBJECT_PREVIEW}
        onClosed={onClose}
        sendToken={action.sendToken}
      />
    </RightSideOverlay>
  );
};

export default DisplayActionContainer;
