import {
  convertDataPointsFilterToInput,
  convertWidgetContextToInput,
  useVisualisationDataChangedSubscription,
  VISUALISATION_DATA_POINT_NODES_QUERY,
  VisualisationDataPointNodesQueryData,
  VisualisationDataPointsQueryVars
} from '@aireframe/graphql';
import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { mapDataPoints } from '../DataVisualisationHelpers';
import { useVisualisationContext } from '../VisualisationContext';
import dayjs from 'dayjs';

export const useChartDataExtract = () => {
  const {
    definition,
    widgetContext,
    connectionQueryVariables,
    setConnectionInfo,
    filter,
    setIsUpdatePending,
    setLastPossibleUpdate
  } = useVisualisationContext();

  const { data, error, loading, refetch } = useQuery<
    VisualisationDataPointNodesQueryData,
    VisualisationDataPointsQueryVars
  >(VISUALISATION_DATA_POINT_NODES_QUERY, {
    variables: {
      dataVisualisationId: definition.id,
      getDataPointsInput: {
        context: convertWidgetContextToInput(widgetContext),
        filter: convertDataPointsFilterToInput(filter.toFilterInput()),
        orderBy: connectionQueryVariables.orderBy,
        orderDirection: connectionQueryVariables.orderDirection
      }
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setLastPossibleUpdate(dayjs(data.visualisation.dataPoints.lastPossibleUpdate));
      setIsUpdatePending(data.visualisation.dataPoints.updatePending);
    }
  });

  useVisualisationDataChangedSubscription(definition, widgetContext, refetch);

  useEffect(() => {
    setConnectionInfo({
      pageInfo: {
        hasNextPage: false,
        hasPreviousPage: false,
        endCursor: null,
        startCursor: null
      },
      totalCount: data?.visualisation.dataPoints.dataPoints.totalCount ?? 0
    });
  }, [data, setConnectionInfo]);

  const mappedData = useMemo(
    () => (data ? mapDataPoints(data.visualisation.dataPoints.dataPoints.nodes) : undefined),
    [data]
  );

  return { data: mappedData, loading, error };
};
