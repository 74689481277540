import { IFrameRenderType } from '@aireframe/shared-types';
import { Card, Divider } from '@mui/material';
import { Fragment, useRef, useState } from 'react';
import IFrameWrapper, { IFrameWrapperRef } from '../../IFrameWrapper/IFrameWrapper';
import { RightSideOverlay } from '../../Layout';
import IFrameHeader from './IFrameHeader';

type IFrameContainerProps = {
  definition: IFrameRenderType;
};

const IFrameContainer: React.FC<IFrameContainerProps> = ({ definition }) => {
  const [expanded, setExpanded] = useState<boolean>(true);
  const [secondaryUrl, setSecondaryUrl] = useState<string>();
  const iframeRef = useRef<IFrameWrapperRef>(null);

  const eventHandler = (e: MessageEvent) => {
    if (e.data.eventType === 'url-opened') {
      setSecondaryUrl(e.data.url);
    }
  };

  return (
    <>
      {secondaryUrl && (
        <RightSideOverlay>
          <IFrameWrapper
            isInline
            onClosed={() => setSecondaryUrl(undefined)}
            onReceiveMessage={e => {
              if (iframeRef.current) {
                iframeRef.current.postMessage(e.data);
              }
            }}
            url={secondaryUrl}
            sendToken={definition.sendBearerAsAQueryParameter}
          />
        </RightSideOverlay>
      )}
      <Card aria-label={definition.title}>
        <IFrameHeader definition={definition} expanded={expanded} setExpanded={setExpanded} />
        <Divider />
        {expanded && (
          <Fragment>
            <IFrameWrapper
              url={definition.renderedUri}
              onClosed={() => {
                return;
              }}
              onReceiveMessage={eventHandler}
              ref={iframeRef}
              showSubjectBanner={false}
              isInline={false}
              sendToken={definition.sendBearerAsAQueryParameter}
            />
          </Fragment>
        )}
      </Card>
    </>
  );
};

export default IFrameContainer;
