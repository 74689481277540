import { UserIdentifierType } from './DataType';
import { NumericComparator, StringComparator } from './Filters';
import * as zod from 'zod';

const fieldFilterInputSchema = zod.object({
  fieldKey: zod.string(),
  visualisationId: zod.string().uuid().nullable().optional(),
  filter: zod.union([
    zod.object({
      booleanValue: zod.union([zod.boolean(), zod.null()])
    }),
    zod.object({
      stringValue: zod.union([zod.string(), zod.null()]),
      stringComparator: zod.nativeEnum(StringComparator)
    }),
    zod.object({
      integerValue: zod.union([zod.number(), zod.null()]),
      numericComparator: zod.nativeEnum(NumericComparator)
    }),
    zod.object({
      decimalValue: zod.union([zod.number(), zod.null()]),
      numericComparator: zod.nativeEnum(NumericComparator)
    }),
    zod.object({
      timeValue: zod.union([zod.string(), zod.null()]),
      numericComparator: zod.nativeEnum(NumericComparator)
    }),
    zod.object({
      dateValue: zod.union([zod.string(), zod.null()]),
      numericComparator: zod.nativeEnum(NumericComparator)
    }),
    zod.object({
      dateTimeValue: zod.union([zod.string(), zod.null()]),
      numericComparator: zod.nativeEnum(NumericComparator)
    }),
    zod.object({
      userIdentifierValue: zod.union([
        zod.object({
          identifier: zod.string(),
          userIdentifierType: zod.nativeEnum(UserIdentifierType)
        }),
        zod.null()
      ])
    })
  ])
});

export const dataPointsFilterInputSchema = zod.object({
  and: zod.array(
    zod.object({
      or: zod.array(fieldFilterInputSchema)
    })
  )
});

export type FieldFilterInput = zod.infer<typeof fieldFilterInputSchema>;
export type DataPointsFilter = zod.infer<typeof dataPointsFilterInputSchema>;
