export function getEnumKeyByValue<T extends { [s: string]: unknown }>(
  type: T,
  value: T[keyof T]
): keyof T {
  const indexOfS = Object.values(type).indexOf(value);
  if (indexOfS === -1) {
    throw new Error(`Value ${value} not in enum ${type.name}`);
  }

  return Object.keys(type)[indexOfS];
}
