import { StructuralEntity } from '../Structure';
import { Subject } from '../Subject';

export type WidgetSubjectContext = {
  subject: Pick<Subject, 'id' | 'customFieldValues' | 'avatarHash' | '__typename'>;
};

export type WidgetStructuralEntityContext = {
  structuralEntity: StructuralEntity;
};

export type WidgetContext =
  | WidgetSubjectContext
  | WidgetStructuralEntityContext
  | Record<string, never>;

export function isWidgetSubjectContext(context: WidgetContext): context is WidgetSubjectContext {
  return !!(context as WidgetSubjectContext).subject;
}

export function isWidgetStructuralEntityContext(
  context: WidgetContext
): context is WidgetStructuralEntityContext {
  return !!(context as WidgetStructuralEntityContext).structuralEntity;
}

export type WidgetSubjectContextInput = {
  subjectId: string;
};

export type WidgetStructuralEntityContextInput = {
  structuralEntityKey: string;
};

export type WidgetContextInput =
  | WidgetSubjectContextInput
  | WidgetStructuralEntityContextInput
  | Record<string, never>;

export function convertWidgetContextToInput(context: WidgetContext): WidgetContextInput {
  if (isWidgetSubjectContext(context)) {
    return {
      subjectId: context.subject.id
    };
  }

  if (isWidgetStructuralEntityContext(context)) {
    return {
      structuralEntityKey: context.structuralEntity?.key
    };
  }

  if (Object.keys(context).length === 0) {
    return {};
  }

  throw new Error('Invalid context');
}
