import { gql } from '@apollo/client';
import { DataSourceCapability, DataSourceConfigurationOption } from './DataSource';
import {
  BundledDataSourceInstanceInput,
  CustomDataSourceInstanceInput
} from './AddDataSourceInstanceMutation';

export interface DataSourceBasicMetadataQueryVariables {
  endpoint: string;
  clientId: string;
  clientSecret: string;
  scope: string;
}

export interface DataSourceBasicMetadataQueryResult {
  dataSourceMetadata: {
    __typename: string;
    name: string;
    configurationOptions: Array<DataSourceConfigurationOption>;
  };
}

export const DATA_SOURCE_BASIC_METADATA_QUERY_NAME = 'DataSourceBasicMetadata';
export const DATA_SOURCE_BASIC_METADATA_QUERY = gql`
query ${DATA_SOURCE_BASIC_METADATA_QUERY_NAME}($endpoint: URL!, $clientId: String!, $clientSecret: String!, $scope: String!) {
    dataSourceMetadata(endpoint: $endpoint, clientId: $clientId, clientSecret: $clientSecret, scope: $scope) {
        name
        configurationOptions {
          key 
          isRequired
          isSensitive
        }
    }
}`;

export interface CustomDataSourceCapabilitiesQueryVariables {
  input: CustomDataSourceInstanceInput;
  refreshCache: boolean;
}

export interface DataSourceCapabilitiesQueryResult {
  dataSourceCapabilities: Array<DataSourceCapability>;
}

export const CUSTOM_DATA_SOURCE_CAPABILITIES_QUERY_NAME = 'CustomDataSourceCapabilities';
export const CUSTOM_DATA_SOURCE_CAPABILITIES_QUERY = gql`
query ${CUSTOM_DATA_SOURCE_CAPABILITIES_QUERY_NAME}($input: CustomDataSourceInstanceInput!, $refreshCache: Boolean!) {
    dataSourceCapabilities: customDataSourceCapabilities(input: $input, refreshCache: $refreshCache)
}`;

export interface BundledDataSourceCapabilitiesQueryVariables {
  input: BundledDataSourceInstanceInput;
  refreshCache: boolean;
}

export const BUNDLED_DATA_SOURCE_CAPABILITIES_QUERY_NAME = 'BundledDataSourceCapabilities';
export const BUNDLED_DATA_SOURCE_CAPABILITIES_QUERY = gql`
query ${BUNDLED_DATA_SOURCE_CAPABILITIES_QUERY_NAME}($input: BundledDataSourceInstanceInput!, $refreshCache: Boolean!) {
    dataSourceCapabilities: bundledDataSourceCapabilities(input: $input, refreshCache: $refreshCache)
}`;
