import gql from 'graphql-tag';
import { Dashboard, Section } from '../Dashboard';
import { ITenant } from '../Tenant';

export type SectionConfigurationQueryVars = {
  section: keyof typeof Section;
};

export type SectionConfiguration = {
  __typename: 'SectionConfiguration';
  section: keyof typeof Section;
  dashboards: Array<Dashboard>;
  defaultDashboard: Pick<Dashboard, '__typename' | 'id'> | null;
};

export type SectionConfigurationQueryData = {
  tenant: Pick<ITenant, 'id' | '__typename'> & {
    sectionConfiguration: SectionConfiguration;
  };
};

export const SECTION_CONFIGURATION_FRAGMENT_NAME = 'SectionConfiguration';
export const SECTION_CONFIGURATION_FRAGMENT = gql`
  fragment ${SECTION_CONFIGURATION_FRAGMENT_NAME} on SectionConfiguration {
    section
    dashboards {
      id
      title
      items {
        widget {
          id
          title
          ... on Visualisation {
            type
          }
        }
        positionIndex
      }
    }
    defaultDashboard {
      id
    }
  }`;

export const SECTION_CONFIGURATION_QUERY_NAME = 'DashboardsBySectionQuery';
export const SECTION_CONFIGURATION_QUERY = gql`
  ${SECTION_CONFIGURATION_FRAGMENT}
  query ${SECTION_CONFIGURATION_QUERY_NAME}($section: Section!) {
    tenant {
      id
      sectionConfiguration(section: $section) {
        ...${SECTION_CONFIGURATION_FRAGMENT_NAME}
      }
    }
  }
`;
