import gql from 'graphql-tag';
import { StructureType } from '../Structure';
import { StructureTypeInput } from './CreateStructureTypeMutation';
import {
  STRUCTURE_TYPE_FIELDS,
  STRUCTURE_TYPE_FIELDS_FRAGMENT_NAME
} from './StructureTypeFragment';

export interface UpdateStructureTypeMutationVars {
  structureType: StructureTypeInput;
}

export interface UpdateStructureTypeMutationData {
  updateStructureType: StructureType & { isUsed: boolean };
}

export const UPDATE_STRUCTURE_TYPE_MUTATION = gql`
  ${STRUCTURE_TYPE_FIELDS}
  mutation UpdateStructureType($structureType: StructureTypeInput!) {
    updateStructureType(input: $structureType) {
      ...${STRUCTURE_TYPE_FIELDS_FRAGMENT_NAME}
      isUsed
    }
  }
`;
