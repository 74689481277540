import { createFileRoute } from '@tanstack/react-router';
import zod from 'zod';

const targetUrlSchema = zod.object({
  targetUrl: zod.string().optional()
});

export const Route = createFileRoute('/login/')({
  validateSearch: targetUrlSchema
});
