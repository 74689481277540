import gql from 'graphql-tag';
import { ParseResult } from './Schema/Types';

// Schema

export interface StructuredDataProviderJsonSchemaQueryVars {
  dataSourceId: string;
}

export interface StructuredDataProviderJsonSchemaQueryData {
  tenant: {
    dataSourceInstance: {
      id: string;
      structuredDataProvider: {
        jsonSchema: object;
      };
    };
  };
}

export const STRUCTURED_DATA_PROVIDER_JSON_SCHEMA_QUERY = gql`
  query StructuredDataProvider($dataSourceId: ID!) {
    tenant {
      id
      dataSourceInstance(id: $dataSourceId) {
        id
        structuredDataProvider {
          jsonSchema
        }
      }
    }
  }
`;

// Available Fields
export interface JSONSchemaAvailableFieldsQueryVars {
  dataSourceId: string;
  jsonDocument: string;
}

export interface JSONSchemaAvailableFieldsQueryData {
  tenant: {
    dataSourceInstance: {
      id: string;
      structuredDataProvider: {
        parseProviderJson: ParseResult;
      };
    };
  };
}

export const JSON_SCHEMA_AVAILABLE_FIELDS_QUERY = gql`
  query JsonSchemaAvailableFields($dataSourceId: ID!, $jsonDocument: String!) {
    tenant {
      id
      dataSourceInstance(id: $dataSourceId) {
        id
        structuredDataProvider {
          parseProviderJson(jsonDocument: $jsonDocument) {
            isValid

            ... on ValidSchemaParseResult {
              expectedSubjectIdentifier
              availableFields {
                key
                dataType
              }
            }
          }
        }
      }
    }
  }
`;
