import { DataType } from '@aireframe/shared-types';

export enum FieldType {
  TEXT = 'text',
  NUMBER = 'number',
  DATE = 'date',
  DATETIME = 'dateTime',
  TIME = 'time',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  USER_IDENTIFIER = 'userIdentifier',
  SEX = 'sex',
  CONSTRAINED_VALUES = 'constrainedValues',
  COMPUTED = 'computed'
}

export type SimpleInputFieldTypes = Extract<
  FieldType,
  | FieldType.TEXT
  | FieldType.NUMBER
  | FieldType.DATE
  | FieldType.DATETIME
  | FieldType.TIME
  | FieldType.EMAIL
  | FieldType.PHONE_NUMBER
  | FieldType.USER_IDENTIFIER
>;
export type MultipleChoiceFieldTypes = Extract<
  FieldType,
  FieldType.SEX | FieldType.CONSTRAINED_VALUES
>;

export const SimpleInputFieldTypeTypename = 'SimpleFieldType';
export type SimpleInputFieldType<T extends SimpleInputFieldTypes = SimpleInputFieldTypes> = {
  __typename: typeof SimpleInputFieldTypeTypename;
  name: string;
  key: T;
  dataType: DataType;
};

export const MultipleChoiceInputFieldTypeTypename = 'MultipleChoiceFieldType';
export type MultipleChoiceInputFieldType = {
  __typename: typeof MultipleChoiceInputFieldTypeTypename;
  name: string;
  key: MultipleChoiceFieldTypes;
  options: Array<string>;
  dataType: DataType;
};

export type InputFieldType = SimpleInputFieldType | MultipleChoiceInputFieldType;

export const InputFieldTypename = 'InputField';
export type InputField = {
  __typename: typeof InputFieldTypename;
  key: string;
  name: string;
  required: boolean;
  type: InputFieldType;
};

export type ComputedField = {
  __typename: 'ComputedField';
  key: string;
  name: string;
  liquidMapping: string;
};

export type CustomField = InputField | ComputedField;

export type FormField = InputField & {
  unique: boolean;
  formPositionIndex: number;
};

export type FormFieldInput = Omit<FormField, '__typename' | 'type'> & {
  typeKey: string;
  mapping?: string;
  options?: string[] | undefined;
};

export type SubjectInputField<ExtraCustomFieldProperties extends object = Record<string, never>> =
  Omit<FormField, '__typename'> & {
    __typename: 'SubjectInputField';
    id: string;
  } & (ExtraCustomFieldProperties extends Record<string, never>
      ? // eslint-disable-next-line @typescript-eslint/no-empty-object-type
        {}
      : ExtraCustomFieldProperties);

export type SubjectComputedField<
  ExtraCustomFieldProperties extends object = Record<string, never>
> = Omit<ComputedField, '__typename'> & {
  __typename: 'SubjectComputedField';
  id: string;
} & (ExtraCustomFieldProperties extends Record<string, never>
    ? // eslint-disable-next-line @typescript-eslint/no-empty-object-type
      {}
    : ExtraCustomFieldProperties);

export type SubjectCustomField<ExtraCustomFieldProperties extends object = Record<string, never>> =
  | SubjectInputField<ExtraCustomFieldProperties>
  | SubjectComputedField<ExtraCustomFieldProperties>;

export interface SubjectCustomFieldValue<
  ExtraCustomFieldProperties extends object = Record<string, never>
> {
  __typename: 'CustomFieldValue';
  customField: SubjectCustomField<ExtraCustomFieldProperties>;
  value: string | null;
}

export type SubjectBannerField<ExtraCustomFieldProperties extends object = Record<string, never>> =
  {
    __typename: 'SubjectCustomField';
    field: SubjectCustomField<ExtraCustomFieldProperties>;
    positionIndex: number | null;
  };
