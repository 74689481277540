import {
  DataType,
  FieldFilter,
  isDateFieldFilter,
  isDateTimeFieldFilter,
  isDecimalFieldFilter,
  isIntegerFieldFilter,
  isTimeFieldFilter,
  NumericComparator
} from '@aireframe/shared-types';
import { Grid, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material';
import FilterHeader from '../FilterHeader';
import { DateFilter } from './DateFilter';
import { DecimalFilter } from './DecimalFilter';
import { IntegerFilter } from './IntegerFilter';
import { TimeFilter } from './TimeFilter';
import { DateTimeFilter } from './DateTimeFilter';

type PossibleDataType =
  | DataType.INTEGER
  | DataType.DECIMAL
  | DataType.DATE
  | DataType.DATETIME
  | DataType.TIME;
type Props<DT extends PossibleDataType> = {
  title: string;
  value: FieldFilter<DT>;
  onChange: (value: FieldFilter<DT>) => void;
  onRemove: () => void;
};

const formatNumericComparatorButtonText = (comparator: NumericComparator) => {
  switch (comparator) {
    case NumericComparator.Equals:
      return '=';
    case NumericComparator.LessThan:
      return '<';
    case NumericComparator.LessThanOrEqual:
      return '<=';
    case NumericComparator.GreaterThan:
      return '>';
    case NumericComparator.GreaterThanOrEqual:
      return '>=';
  }
};

const formatNumericComparatorButtonLabel = (comparator: NumericComparator) => {
  switch (comparator) {
    case NumericComparator.Equals:
      return 'Equals';
    case NumericComparator.LessThan:
      return 'Less Than';
    case NumericComparator.LessThanOrEqual:
      return 'Less Than or Equal';
    case NumericComparator.GreaterThan:
      return 'Greater Than';
    case NumericComparator.GreaterThanOrEqual:
      return 'Greater Than or Equal';
  }
};

export function NumericComparatorFilter<T extends PossibleDataType>({
  title,
  value: filter,
  onChange,
  onRemove
}: Props<T>) {
  const handleClear = () => {
    onRemove();
  };

  const getFilter = () => {
    const baseProps = {
      fullWidth: true,
      name: title,
      variant: 'outlined' as const,
      required: filter.comparator !== NumericComparator.Equals,
      'aria-label': title,
      inputProps: {
        'aria-label': title
      }
    };

    if (isIntegerFieldFilter(filter)) {
      return (
        <IntegerFilter
          {...baseProps}
          label="Value"
          value={filter.value.integerValue}
          onChange={nextValue => {
            onChange({
              ...filter,
              value: { dataType: DataType.INTEGER, integerValue: nextValue },
              comparator: nextValue === null ? NumericComparator.Equals : filter.comparator
            });
          }}
        />
      );
    }

    if (isDecimalFieldFilter(filter)) {
      return (
        <DecimalFilter
          {...baseProps}
          label="Value"
          value={filter.value.decimalValue}
          onChange={nextValue => {
            onChange({
              ...filter,
              value: { dataType: DataType.DECIMAL, decimalValue: nextValue },
              comparator: nextValue === null ? NumericComparator.Equals : filter.comparator
            });
          }}
        />
      );
    }

    if (isDateFieldFilter(filter)) {
      return (
        <DateFilter
          {...baseProps}
          value={filter.value.dateValue}
          onChange={nextValue => {
            onChange({
              ...filter,
              value: { dataType: DataType.DATE, dateValue: nextValue },
              comparator: nextValue === null ? NumericComparator.Equals : filter.comparator
            });
          }}
        />
      );
    }

    if (isTimeFieldFilter(filter)) {
      return (
        <TimeFilter
          {...baseProps}
          value={filter.value.timeValue}
          onChange={nextValue => {
            onChange({
              ...filter,
              value: { dataType: DataType.TIME, timeValue: nextValue },
              comparator: nextValue === null ? NumericComparator.Equals : filter.comparator
            });
          }}
        />
      );
    }

    if (isDateTimeFieldFilter(filter)) {
      return (
        <DateTimeFilter
          {...baseProps}
          value={filter.value.dateTimeValue}
          onChange={nextValue => {
            onChange({
              ...filter,
              value: { dataType: DataType.DATETIME, dateTimeValue: nextValue },
              comparator: nextValue === null ? NumericComparator.Equals : filter.comparator
            });
          }}
        />
      );
    }
  };

  return (
    <Grid container direction="column" spacing={1} sx={{ p: 1 }}>
      <Grid item>
        <FilterHeader title={title} onClear={handleClear} />
      </Grid>
      <Grid item>
        <ToggleButtonGroup
          value={filter.comparator}
          aria-label="Filter comparator type"
          exclusive
          size="small"
          onChange={(_, newComparator: NumericComparator) => {
            onChange({ ...filter, comparator: newComparator });
          }}
          fullWidth>
          {Object.values(NumericComparator).map(comparator => (
            <Tooltip title={formatNumericComparatorButtonLabel(comparator)} key={comparator}>
              <ToggleButton
                size="small"
                value={comparator}
                aria-label={formatNumericComparatorButtonLabel(comparator)}>
                <Typography fontWeight="bold">
                  {formatNumericComparatorButtonText(comparator)}
                </Typography>
              </ToggleButton>
            </Tooltip>
          ))}
        </ToggleButtonGroup>
      </Grid>

      <Grid item>{getFilter()}</Grid>
    </Grid>
  );
}
