import gql from 'graphql-tag';

export interface DeleteStructuralEntityMutationVars {
  structuralEntityKey: string;
}

export interface DeleteStructuralEntityMutationData {
  deleteStructuralEntity: boolean;
}

export const DELETE_STRUCTURAL_ENTITY_MUTATION = gql`
  mutation DeleteStructuralEntity($structuralEntityKey: ID!) {
    deleteStructuralEntity(structuralEntityKey: $structuralEntityKey)
  }
`;
