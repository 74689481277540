import gql from 'graphql-tag';
import { Group } from '@aireframe/shared-types';
import { NodesConnection } from '../Paging';

export interface GroupsQueryData {
  tenant: { id: string; groups: NodesConnection<Group> };
}

export const GROUPS_QUERY = gql`
  query Roles {
    tenant {
      id
      groups {
        nodes {
          key
          name
        }
      }
    }
  }
`;
