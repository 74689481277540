import { Group, User } from '@aireframe/shared-types';
import gql from 'graphql-tag';
import { Subject } from '../Subject';
import { ITenant } from '../Tenant';
import { NodesConnection } from '../Paging';

export interface UserQueryVars {
  id: string;
  withAccessToSubjectId: string;
  includeHasAccessToSubject: boolean;
}

export interface UserQueryData {
  tenant: Pick<ITenant, '__typename' | 'id'> & {
    user:
      | (Omit<User, 'permissions'> & {
          groups: NodesConnection<Group>;
          linkedSubject: Pick<Subject, 'id' | 'customFieldValues'> | null;
          hasAccessToSubject?: boolean;
        })
      | null;
  };
}

export const USER_QUERY_NAME = 'User';
export const USER_QUERY = gql`
  query ${USER_QUERY_NAME}($id: ID!, $includeHasAccessToSubject: Boolean!, $withAccessToSubjectId: ID!) {
    tenant {
      id
      user(userId: $id) {
        id
        displayName
        email
        isEnabled
        linkedSubjectId
        linkedSubject {
          id
          customFieldValues {
            value
            customField {
              id 
              key
              name
            }
          }
        }
        groups {
          nodes {
            key
            name
          }
        }
        hasAccessToSubject(subjectId: $withAccessToSubjectId) @include(if: $includeHasAccessToSubject)
      }
    }
  }
`;
