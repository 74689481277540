import {
  Axis,
  AxisType,
  ChartType,
  DataType,
  Series,
  Visualisation,
  VisualisationType,
  AggregateType,
  PipelineDefinition,
  TypedField
} from '@aireframe/shared-types';

export enum ExtendedVisualisationTypes {
  LIQUID = 'Liquid',
  TABLE = 'Table',
  GRAPH = 'Graph',
  PIE_CHART = 'Pie Chart'
}

const GraphableDataTypes = [
  DataType.INTEGER,
  DataType.DECIMAL,
  DataType.DATETIME,
  DataType.DATE,
  DataType.TIME
];

const NumericDataTypes = [DataType.INTEGER, DataType.DECIMAL];

export const getValidVisualisationTypes = (
  pipelineDefinition: PipelineDefinition,
  inputFields: Array<TypedField>
): Array<ExtendedVisualisationTypes> => {
  const validVisualisationTypes: Array<ExtendedVisualisationTypes> = [
    ExtendedVisualisationTypes.TABLE
  ];

  if (
    pipelineDefinition.aggregators.length > 0 &&
    pipelineDefinition.aggregators.every(a => a.type !== AggregateType.MODE)
  ) {
    validVisualisationTypes.push(ExtendedVisualisationTypes.LIQUID);
  }

  if (inputFields.some(x => GraphableDataTypes.includes(x.dataType))) {
    validVisualisationTypes.push(
      ExtendedVisualisationTypes.PIE_CHART,
      ExtendedVisualisationTypes.GRAPH
    );
  }

  return validVisualisationTypes;
};

export const isFieldValidForXAxis = (field: TypedField) =>
  field.dataType !== DataType.USERIDENTIFIER;

export const getValidFieldsForXAxis = (inputFields: Array<TypedField>) =>
  inputFields.filter(isFieldValidForXAxis);

export const isFieldValidForYAxis = (field: TypedField) =>
  NumericDataTypes.includes(field.dataType);

export const getValidFieldsForYAxis = (inputFields: Array<TypedField>) =>
  inputFields.filter(isFieldValidForYAxis);

export const Y_AXIS_VALID_CHART_TYPES = [
  ChartType.BAR,
  ChartType.LINE,
  ChartType.SCATTER,
  ChartType.STACKED_BAR
];

export const getExtendedVisualisationType = (
  visualisationType: VisualisationType,
  series: Array<Series>
): ExtendedVisualisationTypes => {
  if (visualisationType === VisualisationType.TABLE) {
    return ExtendedVisualisationTypes.TABLE;
  }

  if (visualisationType === VisualisationType.LIQUID) {
    return ExtendedVisualisationTypes.LIQUID;
  }

  if (visualisationType === VisualisationType.PIE_CHART) {
    return ExtendedVisualisationTypes.PIE_CHART;
  }

  if (visualisationType === VisualisationType.GRAPH) {
    if (series.some(s => s.chartType === ChartType.PIE)) {
      return ExtendedVisualisationTypes.PIE_CHART;
    }

    return ExtendedVisualisationTypes.GRAPH;
  }

  throw new Error(`Unknown visualisation type: ${visualisationType}`);
};

export const getNormalisedVisualisationType = (
  type: ExtendedVisualisationTypes
): VisualisationType => {
  switch (type) {
    case ExtendedVisualisationTypes.TABLE:
      return VisualisationType.TABLE;
    case ExtendedVisualisationTypes.PIE_CHART:
      return VisualisationType.PIE_CHART;
    case ExtendedVisualisationTypes.GRAPH:
      return VisualisationType.GRAPH;
    case ExtendedVisualisationTypes.LIQUID:
      return VisualisationType.LIQUID;
    default:
      throw new Error(`Unknown type ${type}}`);
  }
};

export const isDateTimeSeries = (series: Pick<Series, 'dataType'>) =>
  [DataType.DATETIME, DataType.DATE, DataType.TIME].includes(series.dataType);

export const getXAxisSeries = ({ axes, series }: Pick<Visualisation, 'axes' | 'series'>) => {
  const xAxes = axes.filter(x => x.type === AxisType.X);
  if (xAxes.length === 0) {
    return [];
  }

  return series.filter(x => xAxes.some(xAxis => xAxis.series.some(s => s.key === x.key)));
};

export const shouldShowOrdering = (
  visualisation: Pick<Visualisation, 'axes' | 'series'> & {
    type: Visualisation['type'] | ExtendedVisualisationTypes;
  }
) => {
  if (
    visualisation.type === ExtendedVisualisationTypes.LIQUID ||
    visualisation.type === VisualisationType.LIQUID
  ) {
    return false;
  }

  const xAxisSeries = getXAxisSeries(visualisation);
  if (xAxisSeries.length === 0) {
    return true;
  }

  return !xAxisSeries.some(isDateTimeSeries);
};

export const orderAxisSeries = (
  series: Axis['series'],
  newSeries: Axis['series'][number],
  destinationIndex: number
): Axis['series'] => {
  if (destinationIndex === 0) {
    return [newSeries, ...series];
  }

  return [...series.slice(0, destinationIndex), newSeries, ...series.slice(destinationIndex)];
};

export const isSeriesSortable = (series: Series) => {
  return series.dataType !== DataType.USERIDENTIFIER;
};

export const seriesSupportsLiquidFormatter = (series: Series) => {
  return series.dataType !== DataType.USERIDENTIFIER;
};

export const shouldShowFiltering = (
  visualisation: Pick<Visualisation, 'type' | 'series'> & { isCacheable: boolean }
) => {
  return (
    visualisation.isCacheable &&
    visualisation.type !== VisualisationType.LIQUID &&
    visualisation.series.length > 0
  );
};
