import { OidcUser } from '@aireframe/shared-types';
import { createContext, useContext } from 'react';

export interface IAuthenticationContext {
  user: OidcUser | null;
  isLoggedIn: boolean;
  signIn: (targetUrl?: string) => void;
  signOut: () => void;
  getBearerToken: () => Promise<string | null>;
}

export const AuthenticationContext = createContext<IAuthenticationContext | undefined>(undefined);

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  if (!context) throw new Error(`${AuthenticationContext.displayName} has not been registered`);
  return context;
};
