import { Tooltip } from '@mui/material';
import React, { ReactElement, useEffect, useRef, useState } from 'react';

interface IProps {
  children: ReactElement;
}

export const OverflowTooltip: React.FC<IProps> = ({ children }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTextEllipsed, setIsTextEllipsed] = useState<boolean>(false);

  const checkIfEllipsed = () => {
    const element = textRef.current;
    if (element) {
      setIsTextEllipsed(element.offsetWidth < element.scrollWidth);
    }
  };

  useEffect(() => {
    checkIfEllipsed();
  });

  // check on window resize
  useEffect(() => {
    window.addEventListener('resize', checkIfEllipsed);
    return () => {
      window.removeEventListener('resize', checkIfEllipsed);
    };
  }, []);

  const cloneChildren = React.cloneElement(children, { ref: textRef });

  return (
    <Tooltip title={children.props.children as string} disableHoverListener={!isTextEllipsed}>
      {cloneChildren}
    </Tooltip>
  );
};
