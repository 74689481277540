import {
  assertUnreachable,
  DataType,
  FieldFilter,
  FilterableField,
  isBooleanFieldFilter,
  isDateFieldFilter,
  isDateTimeFieldFilter,
  isDecimalFieldFilter,
  isIntegerFieldFilter,
  isStringFieldFilter,
  isTimeFieldFilter,
  isUserIdentifierFieldFilter,
  NumericComparator,
  StringComparator
} from '@aireframe/shared-types';
import AddIcon from '@mui/icons-material/Add';
import { Button, ButtonGroup, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { sortBy } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';
import { PopupMenu } from '../PopupMenu';
import { BooleanFilter } from './Boolean/BooleanFilter';
import { NumericComparatorFilter } from './Comparative/NumericComparatorFilter';
import { FieldsFilter } from './FieldsFilter';
import StringFilter from './String/StringFilter';
import UserIdentifierFilter from './UserIdentifier/UserIdentifierFilter';
import FilterListIcon from '@mui/icons-material/FilterAlt';

type Props = {
  title?: string;
  noFiltersText?: string;
  fields: FilterableField[];
  value: FieldsFilter;
  onChange: (newValue: FieldsFilter) => void;
} & ({ controlled?: false; cancelText: string; onCancel: () => void } | { controlled: true });

const createFilter = (id: number, key: string, dataType: DataType): Array<FieldFilter> => {
  const baseFields = { id, key };

  // Boolean works in the reverse way where all options are selected by default (due it being OR)
  // Boolean ids are negative to keep them at the top of the list
  switch (dataType) {
    case DataType.BOOLEAN:
      return [
        {
          ...baseFields,
          id: -1,
          value: { dataType, booleanValue: true }
        },
        {
          ...baseFields,
          id: -2,
          value: { dataType, booleanValue: false }
        },
        {
          ...baseFields,
          id: -3,
          value: { dataType, booleanValue: null }
        }
      ];
    case DataType.INTEGER:
      return [
        {
          ...baseFields,
          value: { dataType, integerValue: null },
          comparator: NumericComparator.Equals
        }
      ];
    case DataType.DECIMAL:
      return [
        {
          ...baseFields,
          value: { dataType, decimalValue: null },
          comparator: NumericComparator.Equals
        }
      ];
    case DataType.DATE:
      return [
        {
          ...baseFields,
          value: { dataType, dateValue: null },
          comparator: NumericComparator.Equals
        }
      ];
    case DataType.TIME:
      return [
        {
          ...baseFields,
          value: { dataType, timeValue: null },
          comparator: NumericComparator.Equals
        }
      ];
    case DataType.DATETIME:
      return [
        {
          ...baseFields,
          value: { dataType, dateTimeValue: null },
          comparator: NumericComparator.Equals
        }
      ];
    case DataType.STRING:
      return [
        {
          ...baseFields,
          value: { dataType, stringValue: null },
          comparator: StringComparator.Contains
        }
      ];
    case DataType.USERIDENTIFIER:
      return [
        {
          ...baseFields,
          value: { dataType: DataType.USERIDENTIFIER, userIdentifierValue: null }
        }
      ];
    default:
      assertUnreachable(dataType);
  }
};

const ActiveFilter = ({
  filterKey: key,
  title,
  filters,
  filterState,
  setFilterState
}: {
  filterKey: string;
  title: string;
  filters: ReadonlyArray<FieldFilter>;
  filterState: FieldsFilter;
  setFilterState: (fieldsFilter: FieldsFilter) => void;
}) => {
  if (filters.every(isBooleanFieldFilter)) {
    // Boolean is the specical case as it is the only one that gets applied as OR (multiple values)
    return (
      <BooleanFilter
        title={title}
        onChange={values => {
          filterState.removeFiltersByFieldKey(key);
          filterState.andAny(
            values.map((v, idx) => ({
              id: -1 * idx,
              key: key,
              value: { dataType: DataType.BOOLEAN, booleanValue: v }
            }))
          );
          setFilterState(filterState);
        }}
        values={filters.map(fitler => fitler.value.booleanValue)}
      />
    );
  }

  const filter = filters[0];

  const onChange = (value: FieldFilter) => {
    filterState.and(value);
    setFilterState(filterState);
  };

  const onRemove = () => {
    filterState.removeFilterById(filter.id);
    setFilterState(filterState);
  };

  if (
    isIntegerFieldFilter(filter) ||
    isDecimalFieldFilter(filter) ||
    isDateTimeFieldFilter(filter) ||
    isDateFieldFilter(filter) ||
    isTimeFieldFilter(filter)
  ) {
    return (
      <NumericComparatorFilter
        title={title}
        onChange={onChange}
        onRemove={onRemove}
        value={filter}
      />
    );
  }

  const textFieldProps = {
    name: title,
    inputProps: { 'aria-label': title },
    fullWidth: true,
    label: 'Value'
  };

  if (isStringFieldFilter(filter)) {
    return (
      <StringFilter
        title={title}
        value={filter}
        textFieldProps={{
          ...textFieldProps,
          required: filter.comparator !== StringComparator.Equals
        }}
        onChange={onChange}
        onRemove={onRemove}
      />
    );
  }

  if (isUserIdentifierFieldFilter(filter)) {
    return (
      <UserIdentifierFilter
        title={title}
        value={filter}
        onChange={onChange}
        onRemove={onRemove}
        textFieldProps={textFieldProps}
      />
    );
  }

  if (isBooleanFieldFilter(filter)) {
    return null; // Handled above
  }

  assertUnreachable(filter);
};

export const FiltersList = ({
  fields,
  value,
  onChange,
  title = 'Filter',
  noFiltersText = 'Add a filter to get started.',
  ...props
}: Props) => {
  const [showOptions, setShowOptions] = useState(false);
  const [nextFilter, setNextFilter] = useState(value.clone());
  const anchorRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    setNextFilter(value.clone());
  }, [value]);

  const newFilterOptions = useMemo(() => {
    return fields.filter(
      field => !(field.dataType === DataType.BOOLEAN && nextFilter.hasFieldFilter(field.key))
    );
  }, [fields, nextFilter]);

  const activeFilters = useMemo(() => {
    return fields.flatMap(field => {
      const filtersForKey = nextFilter.getFiltersByFieldKey(field.key);
      if (filtersForKey.XAND.length > 0) {
        return filtersForKey.XAND.map(f => ({ ...field, id: f.id, filters: [f] }));
      }

      if (filtersForKey.OR.length > 0) {
        return [
          {
            ...field,
            id: Math.min(...filtersForKey.OR.map(f => f.id)),
            filters: filtersForKey.OR
          }
        ];
      }

      return [];
    });
  }, [fields, nextFilter]);

  return (
    <>
      {showOptions && (
        <PopupMenu
          onClose={() => setShowOptions(false)}
          title="Add"
          showTitle={false}
          popoverProps={{ anchorEl: anchorRef.current }}>
          <ButtonGroup sx={{ p: 1 }} orientation="vertical" variant="outlined">
            {newFilterOptions.map(field => (
              <Button
                key={field.key}
                fullWidth
                style={{ textTransform: 'none' }}
                onClick={() => {
                  nextFilter.andAny(
                    createFilter(nextFilter.filterCount() + 1, field.key, field.dataType)
                  );

                  if (props.controlled) {
                    onChange(nextFilter);
                  } else {
                    setNextFilter(nextFilter.clone());
                  }

                  setShowOptions(false);
                }}>
                {field.title}
              </Button>
            ))}
          </ButtonGroup>
        </PopupMenu>
      )}
      <Grid container spacing={1} direction="column" sx={{ px: 1, pb: 1 }}>
        <Grid
          container
          item
          style={{ position: 'sticky' }}
          direction="column"
          spacing={2}
          sx={{ mb: 1 }}>
          <Grid
            container
            item
            style={{ position: 'relative' }}
            direction="row"
            justifyContent="center">
            <Grid display="flex" item alignItems="center">
              <FilterListIcon
                style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: '120%' }}
                sx={{ mr: 1 }}
              />
              <Typography variant="h6">{title}</Typography>
            </Grid>

            <Tooltip title="Add Filter">
              <IconButton
                onClick={() => setShowOptions(true)}
                ref={anchorRef}
                aria-label="Add Filter"
                style={{ position: 'absolute', right: 0, top: '30%' }}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Grid item>
            <Divider />
          </Grid>
        </Grid>

        {activeFilters.length === 0 && (
          <Grid item>
            <Typography style={{ textAlign: 'center' }}>{noFiltersText}</Typography>
          </Grid>
        )}
        {sortBy(activeFilters, f => f.id).map(field => (
          <Grid item key={field.id}>
            <ActiveFilter
              filterKey={field.key}
              title={field.title}
              filters={field.filters}
              filterState={nextFilter}
              setFilterState={value => {
                if (props.controlled) {
                  onChange(value);
                } else {
                  setNextFilter(value.clone());
                }
              }}
            />
            <Divider />
          </Grid>
        ))}

        {!props.controlled && (
          <UncontrolledChangeStateButtons
            oldValue={value}
            newValue={nextFilter}
            onCancel={props.onCancel}
            cancelText={props.cancelText}
            onChange={onChange}
          />
        )}
      </Grid>
    </>
  );
};

const UncontrolledChangeStateButtons = ({
  oldValue,
  newValue,
  onCancel,
  cancelText,
  onChange
}: {
  oldValue: FieldsFilter;
  newValue: FieldsFilter;
  onCancel: () => void;
  cancelText: string;
  onChange: (filter: FieldsFilter) => void;
}) => {
  const filterHasChanged = useMemo(() => !oldValue.equals(newValue), [oldValue, newValue]);
  const hasFilter = useMemo(() => newValue.filterCount() > 0, [newValue]);

  return (
    <Grid container item justifyContent="space-between">
      <Grid item>
        {hasFilter && (
          <Button onClick={onCancel} color="error">
            {cancelText}
          </Button>
        )}
      </Grid>

      <Grid item>
        {(hasFilter || filterHasChanged) && (
          <Button color="primary" onClick={() => onChange(newValue)} disabled={!filterHasChanged}>
            Apply
          </Button>
        )}
      </Grid>
    </Grid>
  );
};
