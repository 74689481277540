import { IconButton, IconButtonProps, styled } from '@mui/material';
import ShowIcon from '@mui/icons-material/ExpandMore';
import HideIcon from '@mui/icons-material/KeyboardArrowUp';

interface Props extends Omit<IconButtonProps, 'onClick' | 'size'> {
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  size?: 'small' | 'large';
}
const StyledIconButton = styled(IconButton)({
  backgroundColor: '#7b7b7b',
  color: '#fff',
  '& :hover': {
    backgroundColor: '#7b7b7b !important',
    color: '#fff'
  }
});
const ExpandIconButton: React.FC<Props> = ({ expanded, setExpanded, size, ...buttonProps }) => {
  if (expanded) {
    return (
      <StyledIconButton
        onClick={() => setExpanded(false)}
        size="small"
        aria-label="Collapse"
        {...buttonProps}>
        <HideIcon fontSize={size} />
      </StyledIconButton>
    );
  } else {
    return (
      <StyledIconButton
        onClick={() => setExpanded(true)}
        size="small"
        aria-label="Expand"
        {...buttonProps}>
        <ShowIcon fontSize={size} />
      </StyledIconButton>
    );
  }
};

export default ExpandIconButton;
