import {
  AbilityContextProvider,
  Content,
  ErrorBoundary,
  lazyRetry,
  Loading,
  Root,
  StructureContextProvider,
  StyledToolbar,
  theme,
  useAppContext,
  useAuthentication
} from '@aireframe/components';
import { ApolloWrapper } from '@aireframe/graphql';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider } from '@mui/system';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { Suspense } from 'react';
import { RouterContext } from '../router';

const Navigation = lazyRetry(() =>
  import('../components/Navigation/Navigation').then(module => ({ default: module.Navigation }))
);

const Layout = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn } = useAuthentication();

  return (
    <Root>
      {isLoggedIn && <Navigation />}
      <Content role="main">
        <StyledToolbar />
        {children}
      </Content>
    </Root>
  );
};

const RootComponent = () => {
  const { apiHost, webSocketHost } = useAppContext();
  const { getBearerToken, signOut } = useAuthentication();

  return (
    <Suspense fallback={<Loading fullscreen />}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ApolloWrapper
            httpServerHost={apiHost}
            wsServerHost={webSocketHost}
            getBearerToken={getBearerToken}
            onUnauthenticatedResponse={signOut}>
            <StructureContextProvider>
              <AbilityContextProvider>
                <Layout>
                  <ErrorBoundary
                    context="App"
                    message="Sorry, something went wrong. Please check your configuration.">
                    <Outlet />
                  </ErrorBoundary>
                </Layout>
              </AbilityContextProvider>
            </StructureContextProvider>
          </ApolloWrapper>
        </ThemeProvider>
      </StyledEngineProvider>
    </Suspense>
  );
};

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent
});
