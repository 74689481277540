import { TextField, TextFieldProps } from '@mui/material';

export const IntegerFilter = ({
  value,
  onChange,
  ...props
}: {
  value: number | null;
  onChange: (value: number | null) => void;
} & Omit<TextFieldProps, 'type' | 'defaultValue' | 'onBlur' | 'value' | 'onChange'>) => {
  return (
    <TextField
      {...props}
      type="number"
      defaultValue={value?.toString() ?? ''}
      onBlur={event =>
        event.target.value === '' ? onChange(null) : onChange(parseInt(event.target.value))
      }
      slotProps={{
        htmlInput: { ...props.inputProps, step: 1 }
      }}
    />
  );
};
