import { Button, Grid, Typography } from '@mui/material';

interface Props {
  title: string;
  onClear: () => void;
}

const FilterHeader = ({ title, onClear }: Props) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center" direction="row">
      <Grid item>
        <Typography variant="subtitle1" color="primary" component="h6">
          {title}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={onClear}
          color="info"
          size="small"
          style={{ minWidth: 0 }}
          sx={{ py: 0.5, px: 0.25 }}>
          Remove
        </Button>
      </Grid>
    </Grid>
  );
};

export default FilterHeader;
