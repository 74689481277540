import { ReferencedContext } from '@aireframe/shared-types';
import gql from 'graphql-tag';
import {
  VISUALISATION_RENDERED_FRAGMENT,
  VISUALISATION_RENDERED_FRAGMENT_NAME
} from '../Fragments';
import { WidgetContextInput } from '../Widgets';
import { Dashboard, Section } from './Dashboard';
import { DASHBOARD_FRAGMENT, DASHBOARD_FRAGMENT_NAME } from './DashboardFragment';

export type DashboardQueryVariables = {
  filter?: {
    forPortal?: boolean;
  };
};

export interface DashboardWithConfiguration extends Omit<Dashboard, 'items'> {
  widgetContextReferences: ReferencedContext[];
}

export interface DashboardsQueryData {
  tenant: {
    __typename: 'Tenant';
    id: string;
    dashboards: DashboardWithConfiguration[];
  };
}

export const GET_DASHBOARDS_QUERY_NAME = 'GetDashboards';

export const GET_DASHBOARDS_QUERY = gql`
  ${DASHBOARD_FRAGMENT}
  query ${GET_DASHBOARDS_QUERY_NAME}($filter: DashboardFilterInput) {
    tenant {
      id
      dashboards(filter: $filter) {
       ...${DASHBOARD_FRAGMENT_NAME}
       widgetContextReferences
      }
    }
  }
`;

export interface DashboardQueryData {
  dashboard: (Dashboard & { assignedSections: Section[] }) | null;
}

export interface DashboardQueryVars {
  id: string;
  context: WidgetContextInput;
}

export const GET_DASHBOARD_BY_ID_NAME = 'DashboardById';
export const GET_DASHBOARD_BY_ID = gql`
${VISUALISATION_RENDERED_FRAGMENT}
  query ${GET_DASHBOARD_BY_ID_NAME}($id: ID!, $context: WidgetContextInput!) {
    dashboard(id: $id) {
      id
      key
      title
      forPortal
      assignedSections
      items {
        widget {
          id
          title
          key
          forPortal
          ... on IFrameWidget {
            uri
            renderedUri(context: $context)
            sendBearerAsAQueryParameter,
            referencedContexts
          }
          ...${VISUALISATION_RENDERED_FRAGMENT_NAME}
        }          
        positionIndex
      }
    }
  }
`;
