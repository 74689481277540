import { useMutation } from '@apollo/client';
import { Visualisation } from '@aireframe/shared-types';
import {
  ActionableDataPoint,
  PerformActionResponse,
  PERFORM_ACTION_MUTATION,
  PerformActionMutationData,
  PerformActionMutationVars,
  PerformActionResult
} from '@aireframe/graphql';

interface UsePerformActionValue {
  loading: boolean;
  result: PerformActionResult | null;
  reset: () => void;
  performAction: (dataPoint: ActionableDataPoint, action: PerformActionResponse) => void;
}

export const ErrorMessage = 'An error occurred, please try again later.';

const usePerformAction = (
  dataVisualisation: Pick<Visualisation, 'id'>,
  options?: {
    onSuccess?: (data: PerformActionMutationData) => void;
    onError?: () => void;
  }
): UsePerformActionValue => {
  const [mutate, { data, loading, error, reset }] = useMutation<
    PerformActionMutationData,
    PerformActionMutationVars
  >(PERFORM_ACTION_MUTATION, {
    onError: options?.onError,
    onCompleted: options?.onSuccess
  });

  const performActionCallback = (dataPoint: ActionableDataPoint, action: PerformActionResponse) => {
    mutate({
      variables: {
        dataVisualisationId: dataVisualisation.id,
        dataPointId: dataPoint.sourceDataPointId,
        subjectId: dataPoint.subjectId,
        action
      }
    });
  };

  let result: PerformActionResult | null = null;

  if (error) {
    result = {
      success: false,
      message: ErrorMessage
    };
  } else if (data) {
    result = data.performDataPointAction;
  }

  return {
    loading,
    result,
    reset,
    performAction: performActionCallback
  };
};

export default usePerformAction;
