import { $, query } from '../../api.gen';

export const PageGroupsQuery = query('PageGroups', q => [
  q.tenant(t => [
    t.__typename,
    t.id,
    t.groups(
      {
        after: $('after'),
        first: $('first'),
        before: $('before'),
        last: $('last'),
        orderBy: $('orderBy'),
        orderDirection: $('orderDirection'),
        filter: $('filter')
      },
      g => [
        g.__typename,
        g.edges(e => [e.__typename, e.node(n => [n.__typename, n.key, n.name]), e.cursor]),
        g.totalCount,
        g.pageInfo(pi => [
          pi.__typename,
          pi.startCursor,
          pi.endCursor,
          pi.hasNextPage,
          pi.hasPreviousPage
        ])
      ]
    )
  ])
]);
