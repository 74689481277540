import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { IconButton, Grid, Drawer, GridProps, Button } from '@mui/material';
import Close from '@mui/icons-material/Close';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import HeightIcon from '@mui/icons-material/Height';
import { DRAWER_WIDTH } from '../Theme/Theme';
import { debounce } from 'lodash-es';

interface RightSideOverlayProps {
  onClose?: () => void;
  headerElement?: JSX.Element;
  containerProps?: GridProps;
  openFullScreen?: boolean;
  showResizeButton?: boolean;
  children?: React.ReactNode;
}

const RightSideOverlay: React.FC<RightSideOverlayProps> = ({
  onClose,
  headerElement,
  containerProps = {},
  openFullScreen = false,
  showResizeButton = true,
  children
}) => {
  const calculateWidth = useCallback(
    (x: number) => x * (openFullScreen ? 1 : 0.4),
    [openFullScreen]
  );

  const fullWidth = document.body.clientWidth - DRAWER_WIDTH;
  const halfWidth = fullWidth / 2;

  const [isResizing, setIsResizing] = useState(false);
  const [width, setWidth] = useState(calculateWidth(fullWidth));
  const observer = useRef<ResizeObserver | null>(null);

  const handleMouseDown = () => {
    setIsResizing(true);
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isResizing) {
      const offsetRight = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
      const minWidth = 200;
      const maxWidth = document.body.clientWidth;
      if (offsetRight > minWidth && offsetRight < maxWidth) {
        setWidth(offsetRight);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  });

  useEffect(() => {
    const debounceSetWidth = debounce(() => {
      setWidth(prevWidth => {
        const previousOffset = fullWidth - prevWidth;
        const newOffset = document.body.clientWidth - DRAWER_WIDTH - prevWidth;

        return prevWidth - (previousOffset - newOffset);
      });
    }, 5);

    observer.current = new ResizeObserver(() => debounceSetWidth());
    observer.current.observe(document.body);

    return () => observer.current?.unobserve(document.body);
  }, [fullWidth]);

  const resizeLabel = useMemo(() => {
    switch (width) {
      case fullWidth:
        return (
          <>
            100% <ArrowRightIcon />
          </>
        );
      case halfWidth:
        return (
          <>
            <ArrowLeftIcon /> 50%
          </>
        );
      default:
        return <HeightIcon fontSize="large" style={{ rotate: '90deg' }} />;
    }
  }, [width, fullWidth, halfWidth]);

  const toggleWidth = () =>
    setWidth(width < halfWidth ? halfWidth : width === fullWidth ? halfWidth : fullWidth);

  return (
    <Drawer
      open
      anchor={'right'}
      variant="persistent"
      sx={{ zIndex: theme => theme.zIndex.drawer + 2, position: 'absolute' }}
      data-testid="right-side-overlay"
      PaperProps={{ style: { width } }}>
      <div
        role="presentation"
        onMouseDown={handleMouseDown}
        style={{
          position: 'absolute',
          width: '5px',
          padding: '4px 0 0',
          top: 0,
          left: 0,
          bottom: 0,
          zIndex: 100,
          cursor: 'ew-resize'
        }}
      />
      <div
        style={{
          maxHeight: '100%',
          height: '100%',
          overflowY: 'auto',
          overflowX: 'hidden',
          position: 'relative'
        }}>
        <Grid
          container
          item
          alignItems="center"
          sx={{
            padding: theme => theme.spacing(1),
            marginBottom: theme => theme.spacing(2),
            borderBottom: theme =>
              onClose || headerElement ? `1px solid ${theme.palette.primary.main}` : undefined
          }}
          style={{
            paddingLeft: '0.3rem',
            zIndex: 99,
            display: 'flex',
            top: 0,
            left: 0,
            right: 0
          }}>
          {onClose && (
            <Grid item>
              <IconButton onClick={() => onClose()} aria-label="Close overlay">
                <Close />
              </IconButton>

              {showResizeButton && (
                <Button
                  aria-label="Resize"
                  sx={{ cursor: 'ew-resize', p: 0.5 }}
                  onMouseDown={handleMouseDown}
                  onClick={() => toggleWidth()}>
                  {resizeLabel}
                </Button>
              )}
            </Grid>
          )}
          <Grid item flexGrow={1}>
            {headerElement}
          </Grid>
        </Grid>
        <Grid
          {...containerProps}
          style={{
            height: '93%',
            pointerEvents: isResizing ? 'none' : 'auto'
          }}>
          {children}
        </Grid>
      </div>
    </Drawer>
  );
};

export default RightSideOverlay;
