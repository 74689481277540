import { apolloCache } from '@aireframe/graphql';
import { FetchError, OidcUser, appendPath, clearLocalStorage } from '@aireframe/shared-types';
import { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { ErrorMessage, Loading } from '../../index';
import {
  getSecondsUnitlExpiry as getSecondsUntilExpiry,
  retrieveAccessToken,
  retrieveOidcUserClaims
} from './AuthenticationHelpers';
import { AuthenticationContext } from './useAuthentication';

const LoginPath = '/bff/login';
const UserProfilePath = '/bff/user';

const buildBffUrl = (
  clientHost: string,
  path?: string,
  values?: { [key: string]: string | undefined }
) => {
  // Ensure there's exactly one slash between base URL and path
  let urlObj: URL;
  if (path !== undefined) {
    urlObj = new URL(appendPath(clientHost, path));
  } else {
    urlObj = new URL(clientHost);
  }

  if (values) {
    Object.keys(values).forEach(key => {
      if (values[key]) {
        urlObj.searchParams.append(key, values[key] as string);
      }
    });
  }

  return urlObj.toString();
};

const isUnauthorisedError = (error: unknown) =>
  error instanceof FetchError && (error.status === 401 || error.status === 403);

type Props = {
  settings: {
    clientHost: string;
    extraQueryParams: { [key: string]: string | number | boolean };
  };
};

export const AuthenticationContextProvider = ({ children, settings }: PropsWithChildren<Props>) => {
  const [initializing, setInitializing] = useState(true);
  const [userData, setUserData] = useState<OidcUser | null>(null);
  const [authError, setAuthError] = useState(false);
  const bearerTokenRef = useRef<string | null>(null);

  const signIn = (searchTargetUrl?: string) => {
    const targetUrl = searchTargetUrl
      ? appendPath(settings.clientHost, searchTargetUrl)
      : settings.clientHost;

    window.location.href = buildBffUrl(settings.clientHost, LoginPath, { targetUrl });
  };

  const signOut = useCallback(() => {
    setUserData(null);
    bearerTokenRef.current = null;

    clearLocalStorage();
    apolloCache.reset({ discardWatches: true });

    if (!userData?.logoutUrl) return;
    window.location.href = `${userData.logoutUrl}&targetUrl=${encodeURIComponent(settings.clientHost)}`;
  }, [settings.clientHost, userData]);

  useEffect(() => {
    const init = async () => {
      if (!userData) {
        try {
          const user = await retrieveOidcUserClaims(
            buildBffUrl(settings.clientHost, UserProfilePath)
          );
          setUserData(user);
        } catch (error) {
          if (isUnauthorisedError(error)) {
            return;
          }
          setAuthError(true);
        } finally {
          setInitializing(false);
        }
      }
    };
    init();
  }, [userData, settings.clientHost, setUserData, setInitializing, setAuthError]);

  const getBearerToken = useCallback(async () => {
    if (!userData) return null;

    if (bearerTokenRef.current && getSecondsUntilExpiry(bearerTokenRef.current) > 5 * 60) {
      return bearerTokenRef.current;
    }

    bearerTokenRef.current = await retrieveAccessToken();
    return bearerTokenRef.current;
  }, [userData]);

  if (initializing) return <Loading fullscreen />;

  if (authError)
    return <ErrorMessage message="There was an error initialising the authentication context." />;

  return (
    <AuthenticationContext.Provider
      value={{
        user: userData,
        isLoggedIn: userData !== null,
        signIn,
        signOut,
        getBearerToken
      }}>
      {children}
    </AuthenticationContext.Provider>
  );
};
