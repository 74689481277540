import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  styled,
  TextField,
  Typography
} from '@mui/material';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Accept, useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';

const DropTarget = styled('div', { shouldForwardProp: prop => prop !== 'state' })<{
  state?: 'active' | 'reject' | 'accept';
}>(({ theme, state }) => {
  let borderColor: string;
  switch (state) {
    case 'accept':
      borderColor = '#00e676';
      break;
    case 'reject':
      borderColor = '#ff1744';
      break;
    case 'active':
      borderColor = '#2196f3';
      break;
    default:
      borderColor = '#eeeeee';
      break;
  }

  return {
    flex: 1,
    display: 'flex',
    minWidth: '350px',
    alignItems: 'center',
    padding: theme.spacing(2),
    borderWidth: 2,
    borderRadius: 2,
    borderColor: borderColor,
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
  };
});

interface UploadFileProps {
  open: boolean;
  file: File | undefined;
  error?: string;
  additionalContent?: React.ReactNode;
  setFile: (file: File | undefined) => void;
  acceptedFileTypes: Accept;
  onUpload: (fileUpload: File) => void;
  onCancel: () => void;
}

type UploadFileValues = {
  title: string;
};

const UploadFile = ({
  open,
  file,
  error,
  setFile,
  additionalContent,
  acceptedFileTypes,
  onUpload,
  onCancel
}: UploadFileProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm<UploadFileValues>();

  const [isFileRejected] = useState(false);

  const onDrop = useCallback(
    (files: File[]) => {
      if (files.length === 1) {
        setFile(files[0]);
        setValue('title', files[0].name.split('.')[0]);
      }
    },
    [setValue, setFile]
  );

  const onAdd = async () => {
    if (file && onUpload) {
      onUpload(file);
    }
  };

  const fileTypes = Object.values(acceptedFileTypes).join(', ');

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    multiple: false,
    accept: acceptedFileTypes
  });

  useEffect(() => {
    if (!open) {
      reset();
      setFile(undefined);
    }
  }, [open, reset, setFile]);

  return (
    <Dialog open={open}>
      <DialogTitle id="form-dialog-title">Upload File</DialogTitle>
      <DialogContent aria-label="Upload File Dialog">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>This upload only accepts {fileTypes} files</Typography>
          </Grid>
          <Grid item xs={12}>
            {additionalContent}
          </Grid>
          {file ? (
            <Fragment>
              <Grid item md={12}>
                <p>{file.name}</p>
              </Grid>
              <Grid item md={12}>
                <TextField
                  label="Title"
                  error={errors.title !== undefined}
                  helperText={errors.title?.message}
                  fullWidth
                  slotProps={{
                    htmlInput: {
                      'aria-label': 'Title',
                      ...register('title', { required: 'Title is required' })
                    }
                  }}
                />
              </Grid>
              {error && (
                <Grid item md={12}>
                  <Typography color={'red'}>{`An Error Occurred: ${error}`}</Typography>
                </Grid>
              )}
            </Fragment>
          ) : (
            <Grid item xs={12}>
              {isFileRejected && <p>This file is not a valid type.</p>}
              <DropTarget
                aria-label="Drop Target"
                style={{ justifyContent: 'center' }}
                state={
                  isDragReject || isFileRejected
                    ? 'reject'
                    : isDragAccept
                      ? 'accept'
                      : isDragActive
                        ? 'active'
                        : undefined
                }
                {...getRootProps()}>
                <input {...getInputProps()} data-testid="attachment-upload" />
                {isDragActive ? (
                  <p>Drop the file here ...</p>
                ) : (
                  <p>Drag and drop a file here, or click to select a file</p>
                )}
              </DropTarget>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button onClick={handleSubmit(onAdd)} color="primary" disabled={!file}>
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UploadFile;
