import { createTheme, Theme } from '@mui/material/styles';
import { createBreakpoints } from '@mui/system';
import type {} from '@mui/lab/themeAugmentation';

const breakpoints = createBreakpoints({});

const mainPurple = '#560B8C';

export const DRAWER_WIDTH = 69;

export const theme: Theme = createTheme({
  components: {
    MuiAccordion: {
      defaultProps: {
        elevation: 1,
        TransitionProps: {
          unmountOnExit: true
        }
      },
      styleOverrides: {
        root: {
          marginBottom: 0
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderBottom: '1px solid rgba(0, 0, 0, 0.12)'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 600
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          [breakpoints.up('md')]: {
            width: DRAWER_WIDTH
          }
        },
        paper: {
          [breakpoints.up('md')]: {
            width: DRAWER_WIDTH
          }
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&disabled': {
            color: 'grey'
          }
        }
      }
    },

    MuiPaper: {
      defaultProps: {
        elevation: 3
      },
      styleOverrides: {
        rounded: {
          borderRadius: 10,
          marginBottom: 5
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#340754',
          textDecoration: 'none'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 0
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          border: `2px solid ${mainPurple}`
        }
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard'
      }
    },
    MuiTabPanel: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: 8
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'standard'
      }
    }
  },
  palette: {
    primary: {
      light: '#783ca3',
      main: mainPurple,
      dark: '#340754',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#ffffff',
      main: '#fefefe',
      dark: '#F2F2F2',
      contrastText: mainPurple
    },
    background: {
      default: ' #F4F7FF'
    }
  },
  mixins: {
    toolbar: {
      minHeight: 70,
      borderBottomWidth: 1
    }
  },
  typography: {
    fontFamily: 'Noto Sans, sans-serif',
    body1: {
      textTransform: 'none'
    }
  }
});
