import { DataTypeValue, DataTypeValueTypeNames } from '@aireframe/shared-types';
import { InputField } from '../CustomField';
import StructureType from './StructureType';

export interface StructuralEntityMinimal {
  key: string;
  displayName: string;
}

export interface StructuralEntity extends StructuralEntityMinimal {
  depth: number;
}

export const StructuralEntityTypename = 'StructuralEntity';
export interface SubTenantStructuralEntity extends StructuralEntity {
  __typename: typeof StructuralEntityTypename;
  parentKey: string | null;
  structureType: Pick<StructureType, '__typename' | 'key' | 'subjectAssignable'>;
  customFieldValues: Array<{
    field: InputField;
    value: DataTypeValue & {
      __typename: (typeof DataTypeValueTypeNames)[keyof typeof DataTypeValueTypeNames];
    };
  }>;
}
