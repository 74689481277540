import React, { useContext, useEffect, useState } from 'react';
import { AppService } from './AppService';
import Loading from '../Loading/Loading';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

interface IAppContext {
  tenantKey: string | null;
  apiHost: string;
  webSocketHost: string;
  clientRootHost: string;
  authorityHost: string;
  aireSuiteHost: string;
}

export const AppContext = React.createContext<IAppContext | undefined>(undefined);

type AppContextProviderState = IAppContext | { hasError: boolean };

function appContextHasError(state: AppContextProviderState): state is { hasError: boolean } {
  return (state as { hasError: boolean }).hasError !== undefined;
}

export const AppContextProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const [appContextState, setAppContextState] = useState<AppContextProviderState | null>(null);

  useEffect(() => {
    const setup = async () => {
      try {
        const result = await AppService.getClientConfiguration();

        let tenantKey: string | null = null;
        if (result.clientHost) {
          const tenantSubdomain = window.location.hostname.match(
            new RegExp(`^(.*).${result.clientHost}`)
          );
          tenantKey = tenantSubdomain ? tenantSubdomain[1] : null;
        }

        setAppContextState(a => ({
          ...a,
          ...result,
          tenantKey,
          clientRootHost: `${window.location.protocol}//${window.location.hostname}${
            window.location.port ? `:${window.location.port}` : ''
          }`
        }));
      } catch {
        setAppContextState({ hasError: true });
      }
    };

    setup();
  }, []);

  if (!appContextState) return <Loading fullscreen />;
  if (appContextHasError(appContextState)) return <ErrorMessage />;

  return <AppContext.Provider value={appContextState}>{children}</AppContext.Provider>;
};

export const useAppContext = () => {
  const context = useContext(AppContext);
  if (context) return context;

  throw Error('App Context Provider was not registered.');
};
