import {
  convertWidgetContextToInput,
  DashboardQueryData,
  DashboardQueryVars,
  GET_DASHBOARD_BY_ID,
  WidgetContext
} from '@aireframe/graphql';
import { useLazyQuery } from '@apollo/client';
import { useDeepEqualsEffect } from '../Hooks';

export const useDashboardQuery = (context: WidgetContext, dashboardId?: string) => {
  const [getDashboardItems, { data, loading, error, refetch }] = useLazyQuery<
    DashboardQueryData,
    DashboardQueryVars
  >(GET_DASHBOARD_BY_ID, {
    errorPolicy: 'ignore'
  });

  useDeepEqualsEffect(() => {
    if (dashboardId) {
      getDashboardItems({
        variables: { id: dashboardId, context: convertWidgetContextToInput(context) }
      });
    }
  }, [dashboardId, getDashboardItems, context]);

  return {
    dashboard: dashboardId ? data?.dashboard : undefined,
    loading,
    error,
    refetch
  };
};
