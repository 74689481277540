import gql from 'graphql-tag';
import { DataSourceInstance } from './DataSource';

export interface DataSourceInstanceQueryVars {
  dataSourceInstanceId: string;
}

export interface DataSourceInstanceQueryData {
  tenant: {
    __typename: string;
    id: string;
    dataSourceInstance: DataSourceInstance & { isUsed: boolean };
  };
}

export const DATA_SOURCE_INSTANCE_QUERY_NAME = 'DataSourceInstance';
export const DATA_SOURCE_INSTANCE_QUERY = gql`
query ${DATA_SOURCE_INSTANCE_QUERY_NAME}($dataSourceInstanceId: ID!) {
    tenant {
        id
        dataSourceInstance(id: $dataSourceInstanceId) {
            id
            key
            name
            isUsed
            configuration {
                key 
                value {
                  urn
                }
            }
            dataSource {
                type
                key
                ... on CustomDataSource {
                  connectionConfiguration {
                    endpoint
                    clientId
                    clientSecret
                    scope
                  }
                }
            }
        }
    }
}`;
