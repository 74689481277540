import gql from 'graphql-tag';

export const VISUALISATION_FRAGMENT_NAME = 'Visualisation';
export const VISUALISATION_FRAGMENT = gql`
  fragment ${VISUALISATION_FRAGMENT_NAME} on Visualisation {
    id
    type            
    defaultOrdering {
        key
        orderDirection
    }
    axes {
        id
        type
        label
        series {
            key
            dataType
            chartType
        }
    }
    series {
        key
        name
        dataType
        liquidFormatter
        order
        chartType
        colour
        hidden 
        ...on SeriesWithReferenceValue {
            referenceValues {
                value
                label
            }
        }
    }
    isCategorical
    displayOptions {
        ...on TableDisplayOption {
            showColumnHeadings
        }
        ... on LiquidDisplayOption {
            liquidHtml
        }
        ...on PieChartDisplayOption {
            style
            showLabel
            showLegend
            liquidColourFormatting {
                key
                value
            }
        }
        ...on GraphChartDisplayOption {
            liquidColourFormatting {
                key
                value
            }
        }
    }
    defaultFilter {
        and {
            or {
                fieldKey
                filter {
                    ... on BooleanDataTypeValueFilter {
                        booleanValue
                    }
                    ... on StringDataTypeValueFilter {
                        stringComparator
                        stringValue
                    }
                    ... on IntegerDataTypeValueFilter {
                        numericComparator
                        integerValue
                    }
                    ... on DecimalDataTypeValueFilter {
                        numericComparator
                        decimalValue
                    }
                    ... on DateTimeDataTypeValueFilter {
                        numericComparator
                        dateTimeValue
                    }
                    ... on TimeDataTypeValueFilter {
                        numericComparator
                        timeValue
                    }
                    ... on DateDataTypeValueFilter {
                        numericComparator
                        dateValue
                    }
                    ... on UserIdentifierDataTypeValueFilter {
                        userIdentifierValue {
                            identifier
                            userIdentifierType
                        }
                    }
                }
            }
        } 
    }
    referencedContexts
  }
`;

export const VISUALISATION_RENDERED_FRAGMENT_NAME = 'VisualisationRendered';
export const VISUALISATION_RENDERED_FRAGMENT = gql`
  ${VISUALISATION_FRAGMENT}
  fragment ${VISUALISATION_RENDERED_FRAGMENT_NAME} on Visualisation {
    ...${VISUALISATION_FRAGMENT_NAME}
    series {
        renderedName
    }
    isCacheable
  }
`;
