import { UseSubjectResult } from '../Subject/useSubject';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { useStructureContext } from '../Structure/StructureContext';

export const SubjectErrorMessage: React.FC<{
  error: Exclude<UseSubjectResult['error'], undefined>;
}> = ({ error }) => {
  const { activeTenant } = useStructureContext();

  if (error.cannotAccess) {
    return (
      <ErrorMessage
        message={`Sorry, you do not have access to this ${activeTenant?.subjectName}`}
      />
    );
  }

  return <ErrorMessage />;
};
