import { Grid, useMediaQuery, styled, Paper } from '@mui/material';
import { theme } from '../Theme/Theme';
import { Section } from '@aireframe/graphql';
import useDashboardSelectorQuery from '../DashboardSelector/useDashboardSelectorQuery';
import Loading from '../Loading/Loading';
import DashboardSelector from '../DashboardSelector/DashboardSelector';
import React from 'react';

const StyledDashboardBarPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(0.5),
  position: 'fixed',
  top: theme.mixins.toolbar.minHeight,
  right: 0,
  zIndex: 999,
  borderRadius: 0
}));

const Offset = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
  minHeight: '50px'
}));

interface Props {
  section: Section;
  dashboardId: string | undefined;
  handleDashboardChange: (dashboardId?: string | undefined) => void;
  children?: React.ReactNode;
}

export const DashboardBar = ({ section, dashboardId, handleDashboardChange, children }: Props) => {
  const { data, loading, error } = useDashboardSelectorQuery(section, handleDashboardChange);

  if (loading) return <Loading showImage={false} variant="body1" />;
  if (!data || error) return <Wrapper outerChildren={children} />;

  const showSelector = data.tenant.sectionConfiguration.dashboards.length > 1;
  if (!children && !showSelector) return null;

  return (
    <DashboardSelector
      section={section}
      handleChange={handleDashboardChange}
      dashboardId={dashboardId}
      wrapper={<Wrapper outerChildren={children} />}
    />
  );
};

const Wrapper: React.FC<React.PropsWithChildren<{ outerChildren: React.ReactNode }>> = ({
  outerChildren,
  children
}) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <>
      <StyledDashboardBarPaper
        style={{ left: isSmallScreen ? 0 : 69 }}
        elevation={1}
        aria-label="Dashboard Bar">
        <Grid item container spacing={2} alignItems="center" justifyContent={'space-between'}>
          <Grid item md={10}>
            {outerChildren}
          </Grid>
          <Grid item md={2}>
            {children}
          </Grid>
        </Grid>
      </StyledDashboardBarPaper>
      <Offset />
    </>
  );
};
