import { makeVar, useReactiveVar } from '@apollo/client';
import { Button, Grid, Paper, Popper, PopperProps, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { usePersistedState, TUTORIAL_KEY_PREFIX } from '@aireframe/shared-types';
import { PopperArrow, ArrowPlacementProps } from '../PopperMenu';

interface InformationPromptProps {
  id: string;
  body: JSX.Element;
  anchorEl: PopperProps['anchorEl'];
  arrowPlacement?: ArrowPlacementProps;
  children?: React.ReactNode;
}

const Backdrop = styled('div')({
  background: 'rgba(0, 0, 0, 0.3)',
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  zIndex: 9999
});

const instancesDictionary = makeVar<{ [key: string]: number }>({});
export const InformationPrompt: React.FC<InformationPromptProps> = ({
  id,
  body,
  anchorEl,
  children,
  arrowPlacement
}) => {
  const [previouslyViewed, setPreviouslyViewed] = usePersistedState(
    false,
    `${TUTORIAL_KEY_PREFIX}${id}`
  );
  const instances = useReactiveVar(instancesDictionary);
  const [instanceId] = useState(Math.random());
  const arrowRef = useRef(null);

  useEffect(() => {
    if (!(id in instances)) {
      instancesDictionary({ ...instances, [id]: instanceId });
    }
  }, [id, instanceId, instances]);

  if (previouslyViewed || instances[id] !== instanceId || !anchorEl) return <>{children}</>;

  return (
    <>
      <Backdrop />
      {children}
      <Popper
        modifiers={[
          {
            name: 'arrow',
            enabled: true,
            options: {
              ref: arrowRef
            }
          }
        ]}
        open
        anchorEl={anchorEl}
        style={{ zIndex: 99999 }}>
        <PopperArrow {...(arrowPlacement ?? {})} ref={arrowRef} />
        <Paper
          sx={{
            padding: theme => theme.spacing(1),
            border: theme => `1px solid ${theme.palette.primary.main}`
          }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {body}
            </Grid>
            <Grid container item xs={12} justifyContent="flex-end">
              <Grid item xs={3}>
                <Button fullWidth variant="contained" onClick={() => setPreviouslyViewed(true)}>
                  Ok
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Popper>
    </>
  );
};
