import gql from 'graphql-tag';
import { Widget } from '@aireframe/shared-types';
import { EdgesConnection, OrderableConnectionQueryVars } from '../Paging';

export interface WidgetDefinitionsQueryData {
  tenant: {
    __typename: string;
    id: string;
    widgets: EdgesConnection<Widget, 'Widgets', Widget['__typename']>;
  };
}

export interface WidgetDefinitionsQueryVariables extends OrderableConnectionQueryVars {
  filter?: {
    searchTerm?: string | null;
    forPortal?: boolean | null;
  };
}

export const WIDGET_DEFINITIONS_QUERY_NAME = 'WidgetDefinitionsQuery';
export const WIDGET_DEFINITIONS_QUERY = gql`
  query ${WIDGET_DEFINITIONS_QUERY_NAME}(
    $after: String
    $first: Int
    $before: String
    $last: Int
    $orderBy: String
    $orderDirection: ListSortDirection
    $filter: WidgetFilterInput
  ) {
    tenant {
      id
      widgets ( 
        after: $after
        first: $first
        before: $before
        last: $last
        orderBy: $orderBy
        orderDirection: $orderDirection
        filter: $filter
      ) {
        edges {
          node {
            id
            title
            key
            forPortal
            __typename
            ... on Visualisation {
              type
              referencedContexts
            }
            ... on IFrameWidget {
              referencedContexts
            }
          }
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;
