import gql from 'graphql-tag';
import { INPUT_FIELD_TYPE_FRAGMENT, INPUT_FIELD_TYPE_FRAGMENT_NAME } from './InputFieldFragment';

export const CUSTOM_FIELD_FRAGMENT_NAME = 'CustomFieldData';
export const CUSTOM_FIELD_FRAGMENT = gql`
  ${INPUT_FIELD_TYPE_FRAGMENT}
  fragment ${CUSTOM_FIELD_FRAGMENT_NAME} on ISubjectCustomField {
    id
    key
    name

    ... on SubjectInputField {
      type {
        ...${INPUT_FIELD_TYPE_FRAGMENT_NAME}
      }
      required
      unique
      formPositionIndex
    }

    ... on SubjectComputedField {
      liquidMapping
    }
  }
`;
