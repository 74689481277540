import {
  FieldFunctionOptions,
  FieldPolicy,
  InMemoryCache,
  InMemoryCacheConfig,
  Reference
} from '@apollo/client';

import {
  DataType,
  DataTypeValueInterfaceTypename,
  DataTypeValueTypeNames,
  GroupTypename
} from '@aireframe/shared-types';
import { constants } from './Constants';
import {
  InputFieldTypename,
  MultipleChoiceInputFieldTypeTypename,
  SimpleInputFieldTypeTypename
} from './CustomField';
import {
  StructuralEntity,
  StructuralEntityTypename,
  StructureTypeTypename,
  TenantUserTypename
} from './Structure';
import { TenantCustomFieldGroupTypename } from './Tenant';

type StringKeys<T> = Extract<
  { [K in keyof T]: T[K] extends string ? (K extends '__typename' ? never : K) : never }[keyof T],
  string
>;
function nodesMerge<T extends object>(keyField: StringKeys<T>): FieldPolicy {
  return {
    keyArgs: false,
    merge(
      existing: { nodes: Array<T | Reference> },
      incoming: { nodes: Array<T | Reference> },
      { isReference, readField }: FieldFunctionOptions
    ): { nodes: Array<T | Reference> } {
      if (!incoming) return existing;
      if (!existing) return incoming;

      const newNodes = new Map<string, T | Reference>();
      const handleNode = (node: T | Reference) => {
        if (isReference(node)) {
          const id = readField<string>(keyField, node);
          if (id && !(id in newNodes)) {
            newNodes.set(id, node);
          }

          return;
        }

        const id = node[keyField];
        if (typeof id !== 'string') {
          console.warn(`Key field ${keyField} is not a string in node ${node}`);
        } else if (!(id in newNodes)) {
          newNodes.set(id, node);
        }
      };

      (existing.nodes ?? []).forEach(handleNode);
      (incoming.nodes ?? []).forEach(handleNode);

      return { ...incoming, nodes: Array.from(newNodes.values()) };
    }
  };
}

export const apolloCacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        appConfig: {
          merge: true
        }
      }
    },
    DataSourceInstance: {
      fields: {
        structuredDataProvider: {
          merge(existing, incoming) {
            return { ...existing, ...incoming };
          }
        }
      }
    },
    StructuredDataProviderType: {
      keyFields: false
    },
    [constants.TenantSubjectProviderTypename]: {
      keyFields: false
    },
    [constants.VisualisationDataPointTypename]: {
      keyFields: false
    },
    [constants.FormattedDataPointTypename]: {
      keyFields: false
    },
    [constants.FormattedDataPointItemTypename]: {
      keyFields: false
    },
    DataExtractsConnection: {
      fields: {
        edges(existingEdges: Array<Reference>, { readField }) {
          return existingEdges.filter(existingEdge => {
            return (
              readField<string | undefined>('id', readField<Reference>('node', existingEdge)) !==
              undefined
            );
          });
        }
      }
    },
    IDataSource: {
      keyFields: ['key']
    },
    [TenantUserTypename]: {
      fields: {
        accessibleStructuralEntities: nodesMerge<StructuralEntity>('key')
      }
    },
    Tenant: {
      fields: {
        structuralEntities: nodesMerge<StructuralEntity>('key')
      }
    },
    RepositorySettings: {
      keyFields: false
    },
    SectionConfiguration: {
      keyFields: ['section']
    },
    PerformActionResult: {
      keyFields: false
    },
    TenantFeature: {
      keyFields: ['featureKey']
    },
    [StructuralEntityTypename]: {
      keyFields: ['key'],
      fields: {
        multiSubjectView: {
          merge(_, incoming) {
            return incoming;
          }
        }
      }
    },
    [StructureTypeTypename]: {
      keyFields: ['key']
    },
    [GroupTypename]: {
      keyFields: ['key']
    },
    [TenantCustomFieldGroupTypename]: {
      keyFields: ['key']
    },
    [DataTypeValueInterfaceTypename]: {
      keyFields: false
    },
    [InputFieldTypename]: {
      keyFields: ['key']
    },
    [constants.FieldTypeInterfaceTypename]: {
      keyFields: false
    },
    [constants.TenantCustomFieldInterfaceTypename]: {
      keyFields: ['key']
    },
    IDisplayOptions: {
      keyFields: false
    },
    GetRenderedHtmlResult: {
      keyFields: false
    },
    SubjectAccessInviteStatus: {
      keyFields: false
    }
  },
  possibleTypes: {
    [constants.FieldTypeInterfaceTypename]: [
      SimpleInputFieldTypeTypename,
      MultipleChoiceInputFieldTypeTypename
    ],
    [constants.TenantCustomFieldInterfaceTypename]: [
      constants.TenantComputedFieldTypename,
      constants.TenantInputFieldTypename
    ],
    [constants.DataVisualisationInterfaceTypename]: [
      constants.ChartTypename,
      constants.TableTypename
    ],
    [DataTypeValueInterfaceTypename]: [
      DataTypeValueTypeNames[DataType.STRING],
      DataTypeValueTypeNames[DataType.INTEGER],
      DataTypeValueTypeNames[DataType.DECIMAL],
      DataTypeValueTypeNames[DataType.BOOLEAN],
      DataTypeValueTypeNames[DataType.DATETIME],
      DataTypeValueTypeNames[DataType.DATE],
      DataTypeValueTypeNames[DataType.TIME],
      DataTypeValueTypeNames[DataType.USERIDENTIFIER]
    ]
  }
};

const cache = new InMemoryCache(apolloCacheConfig);

import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';

if (process.env.NODE_ENV === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

export default cache;
