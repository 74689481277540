import { Avatar, styled } from '@mui/material';
import { useAppContext } from '../../AppContext';
import { appendPath } from '@aireframe/shared-types';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import { Subject } from 'graphql/src/lib/Subject';

const StyledAvatar = styled(Avatar)({
  backgroundColor: 'lightgrey',
  border: '2px solid',
  borderColor: '#DCDFE3'
});

interface Props {
  size?: 'small' | 'large';
  subject: Pick<Subject, 'id' | 'avatarHash'>;
}

const SubjectAvatar = ({ size = 'small', subject }: Props) => {
  const { apiHost } = useAppContext();

  const diameter = size === 'small' ? 40 : 120;
  const source = appendPath(apiHost, `api/v1/subjects/${subject.id}/avatar?${subject.avatarHash}`);

  return (
    <StyledAvatar
      aria-label="Subject Avatar"
      sx={{ width: diameter, height: diameter, backgroundColor: 'lightgrey' }}
      src={subject.avatarHash ? source : undefined}>
      <PersonOutlinedIcon color="action" fontSize="large" />
    </StyledAvatar>
  );
};

export default SubjectAvatar;
