import { useQuery } from '@apollo/client';
import {
  FeatureKey,
  FeatureSet,
  FeatureTogglesQueryData,
  FEATURE_SET_QUERY
} from './FeatureTogglesQuery';
import { useAuthentication } from '../Authentication';

export const DefaultEnabledFeatures: FeatureSet = [];
export const DefaultDisabledFeatures: FeatureSet = [];

export interface IFeatureSetController {
  isEnabled: (featureKey: FeatureKey) => boolean;
}

class FeatureSetController implements IFeatureSetController {
  private enabledSet: Set<FeatureKey>;

  constructor(
    enabledFeatures: FeatureSet = DefaultEnabledFeatures,
    disabledFeatures: FeatureSet = DefaultDisabledFeatures
  ) {
    this.enabledSet = new Set(
      enabledFeatures.filter(enabled => !disabledFeatures.includes(enabled))
    );
  }

  isEnabled = (featureKey: FeatureKey): boolean => {
    return this.enabledSet.has(featureKey);
  };
}

export function useFeatureSetController(): IFeatureSetController {
  const { isLoggedIn } = useAuthentication();
  const { data } = useQuery<FeatureTogglesQueryData>(FEATURE_SET_QUERY, { skip: !isLoggedIn });

  if (!data) {
    return { isEnabled: () => false };
  }

  const featureSetController = new FeatureSetController(
    data.appConfig.featureSet.enabledFeatures,
    data.appConfig.featureSet.disabledFeatures
  );

  return featureSetController;
}
