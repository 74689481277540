import {
  Section,
  UPDATE_USER_DEFAULT_DASHBOARD,
  UpdateUserDefaultDashboardMutationData,
  UpdateUserDefaultSectionDashboardMutationVars
} from '@aireframe/graphql';
import { getEnumKeyByValue, isUndefinedOrEmptyOrWhitespace } from '@aireframe/shared-types';
import { useMutation } from '@apollo/client';
import { MenuItem, Select, Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useCurrentUser } from '../Authorisation';
import ErrorMessage from '../ErrorMessage/ErrorMessage';
import Loading from '../Loading/Loading';
import useDashboardSelectorQuery from './useDashboardSelectorQuery';

interface Props {
  section: Section;
  handleChange: (dashboardId?: string) => void;
  dashboardId?: string;
  wrapper?: JSX.Element;
}

const DashboardSelector = ({
  section,
  handleChange,
  dashboardId: collectionId,
  wrapper: Wrapper
}: Props) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const { data, loading, called } = useDashboardSelectorQuery(section, handleChange, collectionId);

  const [updateUserDefaultDashboard] = useMutation<
    UpdateUserDefaultDashboardMutationData,
    UpdateUserDefaultSectionDashboardMutationVars
  >(UPDATE_USER_DEFAULT_DASHBOARD);

  const { activeUser } = useCurrentUser();

  const callback = async (dashboardId: string) => {
    if (!activeUser) return;

    await updateUserDefaultDashboard({
      variables: {
        userId: activeUser.id,
        dashboardId: isUndefinedOrEmptyOrWhitespace(dashboardId) ? null : dashboardId,
        section: getEnumKeyByValue(Section, section)
      }
    });

    handleChange(dashboardId);
  };

  const selectedDashboard = data?.tenant.sectionConfiguration.dashboards.find(
    d => d.id === collectionId
  );

  return React.cloneElement(Wrapper ?? <></>, {
    children:
      loading || !called ? (
        <Loading showImage={false} variant="body1" />
      ) : !data ? (
        <ErrorMessage />
      ) : (
        data.tenant.sectionConfiguration.dashboards.length > 0 && (
          <Tooltip title="Select Dashboard" arrow open={tooltipOpen}>
            <Select
              sx={{ p: 1 }}
              label="Select Dashboard"
              inputProps={{ 'aria-label': 'Select Dashboard' }}
              value={selectedDashboard?.id || ''}
              onChange={e => callback(e.target.value as string)}
              onMouseEnter={() => {
                setTooltipOpen(true);
              }}
              onMouseLeave={() => {
                setTooltipOpen(false);
              }}
              onOpen={() => {
                setTooltipOpen(false);
              }}
              renderValue={() => {
                if (!selectedDashboard) {
                  return 'Select Dashboard';
                }

                return selectedDashboard.title;
              }}
              displayEmpty
              fullWidth>
              {data.tenant.sectionConfiguration.dashboards.map(dashboard => (
                <MenuItem key={dashboard.id} value={dashboard.id}>
                  {dashboard.title}
                </MenuItem>
              ))}
            </Select>
          </Tooltip>
        )
      )
  });
};

export default DashboardSelector;
