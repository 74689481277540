import { CustomFields } from './CustomFields';
import { TenantFeature } from './TenantFeature';

export default interface ITenant<
  ExtraCustomFieldProperties extends object = Record<string, never>
> {
  __typename: 'Tenant';
  id: string;
  key: string;
  displayName: string;
  environmentKey: string;
  subjectName: string;
  locationName: string;
  showRoles: boolean;
  avatarIsEnabled: boolean;
  features: Array<TenantFeature>;
  customFields: CustomFields<ExtraCustomFieldProperties>;
  subjectNotLinkedErrorMessage: string;
}

export const isFeatureEnabled = (tenant: ITenant | undefined | null, featureKey: string) =>
  tenant?.features?.some(feature => feature.featureKey === featureKey && feature.isEnabled) ??
  false;
