import { useState } from 'react';
import { Alert, Box, IconButton, Typography } from '@mui/material';
import UploadFile from '../../UploadFile/UploadFile';
import SubjectAvatar from './SubjectAvatar';
import { useAppContext } from '../../AppContext';
import { appendPath } from '@aireframe/shared-types';
import { useApolloClient } from '@apollo/client';
import { Subject } from 'graphql/src/lib/Subject';
import { useStructureContext } from '../../Structure';
import { TenantFeatureKey, isFeatureEnabled } from '@aireframe/graphql';
import { useAbility } from '../../Authorisation';

interface Props {
  subject: Pick<Subject, 'id' | '__typename' | 'avatarHash'>;
}

export const SubjectAvatarUpload = ({ subject }: Props) => {
  const client = useApolloClient();
  const abilities = useAbility();
  const { apiHost } = useAppContext();
  const { activeTenant } = useStructureContext();

  const [file, setFile] = useState<File | undefined>(undefined);
  const [open, setOpen] = useState(false);
  const [uploadError, setUploadError] = useState<string | undefined>();

  const canUpload =
    abilities.can('edit', 'Subject') &&
    isFeatureEnabled(activeTenant, TenantFeatureKey.SubjectWrite);

  const handleUpload = async (newFile: File) => {
    const data = new FormData();
    data.append('file', newFile);

    await fetch(appendPath(apiHost, `api/v1/subjects/${subject.id}/avatar`), {
      method: 'post',
      body: data,
      credentials: 'same-origin',
      headers: {
        'x-csrf': '1'
      }
    })
      .then(async response => {
        if (!response.ok) {
          return await response.json().then(json => {
            setUploadError(json.title as string);
          });
        }

        client.cache.modify({
          id: client.cache.identify(subject),
          fields: {
            avatarHash(_, { DELETE }) {
              return DELETE;
            }
          }
        });
        setOpen(false);
      })
      .catch(reason => {
        setUploadError(reason);
      });
  };

  return (
    <Box aria-label={'Subject Avatar Upload'}>
      <IconButton disabled={!canUpload} onClick={() => setOpen(true)} aria-label="upload">
        <SubjectAvatar size="large" subject={subject} />
      </IconButton>
      {canUpload && (
        <UploadFile
          open={open}
          file={file}
          error={uploadError}
          setFile={file => {
            setFile(file);
            setUploadError(undefined);
          }}
          acceptedFileTypes={{
            'image/jpeg': ['.jpeg'],
            'image/png': ['.png']
          }}
          additionalContent={
            <>
              <Alert severity="warning">
                <Typography variant="caption">
                  Rectangular images may appear to be cropped. Please use the recommended dimensions
                  ratio of 1:1.
                </Typography>
              </Alert>
              <Typography>Max file size: 0.75MB</Typography>
            </>
          }
          onCancel={() => setOpen(false)}
          onUpload={handleUpload}
        />
      )}
    </Box>
  );
};
