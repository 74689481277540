import gql from 'graphql-tag';
import { DataExtractMinimal } from './DataExtract';

export type DataExtractQueryVars = {
  id: string;
};

export interface DataExtractQueryData {
  tenant: {
    __typename: string;
    id: string;
    dataExtract: DataExtractMinimal & {
      description: string | null;
      extractJSON: object | null;
      subjectIdentifierConfiguration: {
        useInternalId: boolean;
        useExternalId: boolean;
        customFieldKey: string | null;
      } | null;
    };
  };
}

export const DATA_EXTRACT_QUERY_NAME = 'DataExtract';
export const DATA_EXTRACT_QUERY = gql`
query ${DATA_EXTRACT_QUERY_NAME}($id: ID!) {
    tenant {
      id
      dataExtract(id: $id) {
        id
        key
        title
        description
        extractJSON
        tenantDataSource {
          id
          name
        }
        subjectIdentifierConfiguration {
          useInternalId
          useExternalId
          customFieldKey
        }
      }
    }
}
`;
