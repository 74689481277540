import gql from 'graphql-tag';

export interface DeleteDataExtractMutationVars {
  dataExtractId: string;
}

export interface DeleteDataExtractMutationData {
  deleteDataExtract: boolean;
}

export const DELETE_DATA_EXTRACT_MUTATION_NAME = 'DeleteDataExtract';
export const DELETE_DATA_EXTRACT_MUTATION = gql`
  mutation ${DELETE_DATA_EXTRACT_MUTATION_NAME}($dataExtractId: ID!) {
    deleteDataExtract(dataExtractId: $dataExtractId)
  }
`;
