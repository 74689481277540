import { useQuery } from '@apollo/client';
import gql from 'graphql-tag';
import { InputField } from '../CustomField';
import { INPUT_FIELD_FRAGMENT, INPUT_FIELD_FRAGMENT_NAME } from '../Fragments/InputFieldFragment';
import { StructureType } from '../Structure';
import {
  STRUCTURE_TYPE_FIELDS,
  STRUCTURE_TYPE_FIELDS_FRAGMENT_NAME
} from '../StructureType/StructureTypeFragment';
import { ITenant } from '../Tenant';
import { NodesConnection } from '../Paging';

export interface StructureTypesWithFieldsQueryData {
  tenant: Pick<ITenant, '__typename' | 'id'> & {
    structureTypes: NodesConnection<StructureType & { customFields: Array<InputField> }>;
  };
}

export const STRUCTURE_TYPES_WITH_FIELDS_QUERY_NAME = 'StructureTypes';
export const STRUCTURE_TYPES_WITH_FIELDS_QUERY = gql`
  ${STRUCTURE_TYPE_FIELDS}
  ${INPUT_FIELD_FRAGMENT}
  query ${STRUCTURE_TYPES_WITH_FIELDS_QUERY_NAME} {
    tenant {
      id
      structureTypes {
        nodes {
          ...${STRUCTURE_TYPE_FIELDS_FRAGMENT_NAME}
          customFields {
              ...${INPUT_FIELD_FRAGMENT_NAME}
          }
        }
      }
    }
  }
`;

export const useGetStructureTypesWithCustomFields = () => {
  const { data, ...queryResult } = useQuery<StructureTypesWithFieldsQueryData>(
    STRUCTURE_TYPES_WITH_FIELDS_QUERY,
    {
      fetchPolicy: 'cache-first'
    }
  );

  return { ...queryResult, data: data?.tenant.structureTypes.nodes };
};
