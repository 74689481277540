import {
  ClickAwayListener,
  Dialog,
  DialogContent,
  Paper,
  PaperProps,
  Popper,
  PopperProps,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles/';
import React, { forwardRef, useRef } from 'react';
import { ArrowPlacementProps, PopperArrow } from './PopperArrow';

export interface PopperMenuProps extends PopperProps {
  open: boolean;
  onClose: () => void;
  'data-testid'?: string;
  arrowPlacement?: ArrowPlacementProps;
  arrowEnabled?: boolean;
  paperProps?: PaperProps;
  children?: React.ReactNode;
}

const PopperMenu = forwardRef<HTMLDivElement, PopperMenuProps>(
  (
    {
      open,
      onClose,
      'data-testid': dataTestId,
      arrowPlacement,
      arrowEnabled,
      paperProps,
      children,
      ...popperProps
    },
    ref
  ) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const arrowRef = useRef(null);

    if (isSmallScreen) {
      return (
        <Dialog open={open} onClose={onClose}>
          <DialogContent>{children}</DialogContent>
        </Dialog>
      );
    } else {
      return (
        <Popper
          ref={ref}
          open={open}
          {...popperProps}
          style={{ zIndex: 10000 }}
          data-testid={dataTestId}
          modifiers={[
            {
              name: 'arrow',
              enabled: true,
              options: {
                ref: arrowRef
              }
            },
            {
              name: 'flip',
              enabled: false
            },
            ...(popperProps.modifiers ?? [])
          ]}
          disablePortal={true}>
          {arrowEnabled && <PopperArrow {...(arrowPlacement ?? {})} ref={arrowRef} />}
          <ClickAwayListener onClickAway={onClose}>
            <Paper
              style={{
                border: `2px solid ${theme.palette.secondary.dark}`,
                borderRadius: 5,
                boxShadow: `${theme.shadows[10]}`
              }}
              {...paperProps}>
              {children}
            </Paper>
          </ClickAwayListener>
        </Popper>
      );
    }
  }
);

PopperMenu.displayName = 'PopperMenu';
export default PopperMenu;
