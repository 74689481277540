import gql from 'graphql-tag';
import ITenant from '../ITenant';
import {
  TENANT_FIELDS_FRAGMENT,
  TENANT_FIELDS_FRAGMENT_NAME
} from '../Fragments/TenantFieldsFragment';
import { RecursiveAddTypename } from '../../Paging';

export interface TenantQueryData {
  tenant: RecursiveAddTypename<ITenant>;
}

export const TENANT_QUERY_NAME = 'Tenant';
export const TENANT_QUERY = gql`
  ${TENANT_FIELDS_FRAGMENT}

  query ${TENANT_QUERY_NAME} {
    tenant {
      ...${TENANT_FIELDS_FRAGMENT_NAME}
    }
  }
`;
