import { ApolloClient, ApolloProvider, createHttpLink, ServerError, split } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import cache from './ApolloCache';

import { DocumentNode, Kind, OperationTypeNode } from 'graphql';
import { appendPath } from '@aireframe/shared-types';

const isSubscription = (query: DocumentNode) => {
  const definition = getMainDefinition(query);
  return (
    definition.kind === Kind.OPERATION_DEFINITION &&
    definition.operation === OperationTypeNode.SUBSCRIPTION
  );
};

const ApolloWrapper: React.FC<{
  children?: React.ReactNode;
  httpServerHost: string;
  wsServerHost: string;
  getBearerToken: () => Promise<string | null>;
  onUnauthenticatedResponse: () => void;
}> = ({ children, httpServerHost, wsServerHost, getBearerToken, onUnauthenticatedResponse }) => {
  const httpLink = createHttpLink({
    uri: appendPath(httpServerHost, 'graphql'),
    credentials: 'same-origin',
    headers: {
      'x-csrf': '1'
    }
  });
  const wsLink = new GraphQLWsLink(
    createClient({
      url: appendPath(wsServerHost, 'graphql'),
      connectionParams: async () => {
        const bearerToken = await getBearerToken();
        if (bearerToken) {
          return {
            authToken: `Bearer ${bearerToken}`
          };
        }

        return {};
      }
    })
  );

  const unauthorisedRequestHandlerLink = onError(({ networkError }) => {
    if (onUnauthenticatedResponse && (networkError as ServerError)?.statusCode === 401) {
      onUnauthenticatedResponse();
    }
  });

  const transportLink = split(
    ({ query }) => isSubscription(query),
    wsLink,
    unauthorisedRequestHandlerLink.concat(httpLink)
  );

  const apolloClient = new ApolloClient({
    link: transportLink,
    cache,
    connectToDevTools: import.meta.env.DEV
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
