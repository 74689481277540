import { DataType } from '@aireframe/shared-types';
import { gql } from '@apollo/client';

const ValidLiquidParseResultTypename = 'ValidLiquidParseResult';
const InvalidLiquidParseResultTypename = 'InvalidLiquidParseResult';

type ValidLiquidParseResult = {
  __typename: typeof ValidLiquidParseResultTypename;
  inputArguments: Array<string>;
  isValid: true;
};

type InvalidLiquidParseResult = {
  __typename: typeof InvalidLiquidParseResultTypename;
  errorMessage: string;
  isValid: false;
};

export const VALIDATE_LIQUID_QUERY = gql`
  query validateLiquidExpression(
    $expression: String!
    $inputs: [KeyValuePairOfStringAndNestedKeyValuePairValueOfStringAndDataTypeWrapperInput!]
  ) {
    validateLiquidExpression(expression: $expression, inputs: $inputs) {
      isValid
      ... on ${ValidLiquidParseResultTypename} {
        inputArguments
      }
      ... on ${InvalidLiquidParseResultTypename} {
        errorMessage
      }
    }
  }
`;

export type ValidateLiquidExpressionQueryVariables = {
  expression: string;
  inputs?: Array<{
    key: string;
    value:
      | { nestedValue: ValidateLiquidExpressionQueryVariables['inputs'] }
      | { value: { dataType: DataType } };
  }> | null;
};

export type ValidateLiquidExpressionQueryData = {
  validateLiquidExpression: ValidLiquidParseResult | InvalidLiquidParseResult;
};

export function isValidLiquidParseResult(
  result: ValidLiquidParseResult | InvalidLiquidParseResult
): result is ValidLiquidParseResult {
  return result.__typename === ValidLiquidParseResultTypename;
}

export function isInvalidLiquidParseResult(
  result: ValidLiquidParseResult | InvalidLiquidParseResult
): result is InvalidLiquidParseResult {
  return result.__typename === InvalidLiquidParseResultTypename;
}
