import {
  ConnectionPageInfo,
  OrderableConnectionQueryVars,
  Section,
  WidgetContext
} from '@aireframe/graphql';
import { VisualisationRenderType } from '@aireframe/shared-types';
import React, { useContext } from 'react';
import { FieldsFilter } from '../../Filter/FieldsFilter';
import { Dayjs } from 'dayjs';

export interface IVisualisationContext {
  widgetContext: WidgetContext;
  definition: VisualisationRenderType;
  section: Section;
  setConnectionInfo: (connectionState: {
    pageInfo: Omit<ConnectionPageInfo, '__typename'>;
    totalCount: number;
  }) => void;
  connectionQueryVariables: OrderableConnectionQueryVars;
  totalCount: number;
  filter: FieldsFilter;
  setFilter: (filter: FieldsFilter) => void;
  setIsUpdatePending: (updatePending: boolean) => void;
  setLastPossibleUpdate: (lastPossibleUpdate: Dayjs) => void;
}

export const VisualisationContext = React.createContext<IVisualisationContext | undefined>(
  undefined
);

export const useVisualisationContext = () => {
  const dataVisualisationContext = useContext(VisualisationContext);
  if (!dataVisualisationContext) throw new Error(`VisualisationContext has not been registered`);

  return dataVisualisationContext;
};
