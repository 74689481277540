import { Section, WidgetContext } from '@aireframe/graphql';
import { Grid } from '@mui/material';
import { sortBy } from 'lodash-es';
import React, { useMemo } from 'react';
import { useDashboardQuery } from '../../Dashboard';
import ErrorMessage from '../../ErrorMessage/ErrorMessage';
import { useIsLargeScreen } from '../../Hooks';
import Loading from '../../Loading/Loading';
import { IFrameContainer } from '../IFrame';
import { DataVisualisationContainer } from '../Visualisations';
import { isIFrameType, isVisualisationRenderType } from '../WidgetHelpers';

export type WidgetGridProps = {
  context: WidgetContext;
  view: Section;
  numColumns?: number;
  collectionId: string;
};

const WidgetGrid: React.FC<WidgetGridProps> = ({ view, numColumns = 2, collectionId, context }) => {
  const isLargeScreen = useIsLargeScreen();

  const { dashboard, loading, error } = useDashboardQuery(context, collectionId);

  const iFrameWidgets = useMemo(() => {
    if (!dashboard) return null;

    return dashboard.items
      .map(item => item.widget)
      .filter(widget => isIFrameType(widget))
      .map(widget => (
        <Grid item xs={12} key={widget.id}>
          <IFrameContainer definition={widget} />
        </Grid>
      ));
  }, [dashboard]);

  const visualisations = useMemo(() => {
    if (!dashboard) return null;

    return sortBy(dashboard.items, item => item.positionIndex)
      .map(item => item.widget)
      .filter(item => isVisualisationRenderType(item))
      .map(widget => {
        return (
          <Grid item xs={12} key={widget.id}>
            <DataVisualisationContainer
              dataVisualisationDefinition={widget}
              section={view}
              widgetContext={context}
            />
          </Grid>
        );
      });
  }, [dashboard, view, context]);

  if (loading) return <Loading />;
  if (dashboard === null)
    return <ErrorMessage message="This dashboard does not exist. Please select another" />;
  if (error || !visualisations) return <ErrorMessage message="Could not load Visualisations" />;

  if (isLargeScreen) {
    const width = Math.ceil(12 / numColumns);

    return (
      <>
        {iFrameWidgets}
        <Grid container spacing={1}>
          {visualisations.map((visualisation, index) => (
            <Grid item xs={width} container key={index} spacing={1}>
              {visualisation}
            </Grid>
          ))}
        </Grid>
      </>
    );
  }

  return (
    <>
      {iFrameWidgets}
      <Grid container spacing={1}>
        <Grid container direction="row" item xs={12}>
          {visualisations}
        </Grid>
      </Grid>
    </>
  );
};

export default WidgetGrid;
