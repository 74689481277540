import { useLocation } from '@tanstack/react-router';
import React, { ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';

export interface NavigationAction {
  name: string;
  callback: () => void;
  isMain?: boolean;
  icon: ReactElement;
}

export interface NavigationContextState {
  currentRoute?: string;
  mainAction?: NavigationAction;
  secondaryActions?: NavigationAction[];
}

export interface INavigationContext extends NavigationContextState {
  setActions: (actions: NavigationAction[]) => void;
}

export const NavigationContext = React.createContext<INavigationContext>({
  setActions: () => {
    return;
  }
});

export const NavigationContextProvider: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const { pathname } = useLocation();
  const [navigationContextState, setNavigationContextState] = useState<NavigationContextState>({
    currentRoute: pathname
  });
  const timer = useRef<NodeJS.Timeout | null>(
    null
  ) as React.MutableRefObject<NodeJS.Timeout | null>;

  useEffect(() => {
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, []);

  useEffect(() => {
    setNavigationContextState(state => {
      if (pathname !== state.currentRoute) {
        return {
          currentRoute: pathname,
          mainAction: undefined,
          secondaryActions: undefined
        };
      }
      return state;
    });
  }, [pathname]);

  const setActionsCallback = useCallback((actions: NavigationAction[]) => {
    timer.current = setTimeout(() => {
      setNavigationContextState(state => ({
        ...state,
        mainAction: actions.find(action => action.isMain),
        secondaryActions: actions.filter(action => !action.isMain)
      }));
    }, 100);
  }, []);

  const context: INavigationContext = {
    ...navigationContextState,
    setActions: setActionsCallback
  };

  return <NavigationContext.Provider value={context}>{children}</NavigationContext.Provider>;
};

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);
  if (context) return context;

  throw Error('Navigation Context Provider was not registered.');
};
