import { ApolloError, ServerError, ServerParseError } from '@apollo/client';
import { GraphQLError, GraphQLFormattedError } from 'graphql';

type ErrorBase = {
  message: string;
  key: string;
};

export type UniqueError = ErrorBase & {
  key: 'Unique';
  properties: { duplicateFieldKeys?: Array<string> };
};

export type AuthError = ErrorBase & {
  key: 'Auth';
};

export type DataSourceConnectionError = ErrorBase & {
  key: 'DataSourceConnection';
};

export type ValidationError = ErrorBase & {
  key: 'Validation';
  properties: { [key: string]: string };
};

export type DataExtractFetchError = ErrorBase & {
  key: 'DataExtractFetch';
  properties: { dataExtractKey: string };
};

export type TransformError = ErrorBase & {
  key: 'Transform';
  properties: { visualisationKey: string };
};

export type ComputedFieldError = ErrorBase & {
  key: 'ComputedFieldLiquidParseException';
  properties: { fieldName: string };
};

export type DataNotReadyError = ErrorBase & {
  key: 'DataNotReady';
};

export type GraphQLParsedError =
  | UniqueError
  | AuthError
  | DataSourceConnectionError
  | ValidationError
  | DataExtractFetchError
  | TransformError
  | ComputedFieldError
  | DataNotReadyError;

export default class ErrorParser {
  public static Codes = {
    UNIQUE_CONSTRAINT_EXCEPTION: 'UniqueConstraintViolation',
    AUTH_NOT_AUTHORIZED: 'AUTH_NOT_AUTHORIZED',
    DATA_SOURCE_CONNECTION_EXCEPTION: 'DataSourceConnectionIssue',
    VALIDATION_EXCEPTION: 'ValidationException',
    DATA_SET_FETCH_EXCEPTION: 'DataExtractFetchException',
    TRANSFORM_EXCEPTION: 'TransformException',
    COMPUTED_EXCEPTION: 'ComputedFieldLiquidParseException',
    DATA_NOT_READY: 'DataNotReady'
  };

  private static isServerError = (
    error: Error | ServerParseError | ServerError | null
  ): error is ServerError => {
    return (error as ServerError)?.result !== undefined;
  };

  private static parseError({
    extensions,
    message
  }: GraphQLError | GraphQLFormattedError): GraphQLParsedError | undefined {
    if (extensions) {
      const { code, ...properties } = extensions;
      switch (code) {
        case this.Codes.AUTH_NOT_AUTHORIZED:
          return {
            key: 'Auth',
            message: 'Unauthorized'
          };
        case this.Codes.UNIQUE_CONSTRAINT_EXCEPTION:
          return {
            key: 'Unique',
            message: 'Value must be unique',
            properties: properties as { duplicateFieldKeys?: Array<string> }
          };
        case this.Codes.DATA_SOURCE_CONNECTION_EXCEPTION:
          return {
            key: 'DataSourceConnection',
            message: 'Error connecting to the data source'
          };
        case this.Codes.VALIDATION_EXCEPTION:
          return {
            key: 'Validation',
            message: 'Validation Error',
            properties: properties as { [key: string]: string }
          };
        case this.Codes.DATA_SET_FETCH_EXCEPTION:
          return {
            key: 'DataExtractFetch',
            message: 'Error fetching data set',
            properties: properties as { dataExtractKey: string }
          };
        case this.Codes.TRANSFORM_EXCEPTION:
          return {
            key: 'Transform',
            message: 'Error transforming data',
            properties: properties as { visualisationKey: string }
          };
        case this.Codes.COMPUTED_EXCEPTION:
          return {
            key: 'ComputedFieldLiquidParseException',
            message,
            properties: properties as { fieldName: string }
          };
        case this.Codes.DATA_NOT_READY:
          return {
            key: 'DataNotReady',
            message
          };
      }
    }
  }

  public static parse(error: ApolloError): Array<GraphQLParsedError>;
  public static parse(error: ApolloError | undefined): Array<GraphQLParsedError> | undefined;
  public static parse(error: ApolloError | undefined): Array<GraphQLParsedError> | undefined {
    if (error === undefined) return undefined;
    const errors: Array<GraphQLParsedError> = [];

    if (this.isServerError(error.networkError) && typeof error.networkError.result !== 'string') {
      error.networkError.result.errors.forEach((gqlError: GraphQLError) => {
        const parsedError = this.parseError(gqlError);
        if (parsedError) errors.push(parsedError);
      });
    }

    error.graphQLErrors.forEach(gqlError => {
      const parsedError = this.parseError(gqlError);
      if (parsedError) errors.push(parsedError);
    });

    return errors;
  }

  public static isUniqueError = (error: GraphQLParsedError): error is UniqueError => {
    return error.key === 'Unique';
  };

  public static isAuthError = (error: GraphQLParsedError): error is AuthError => {
    return error.key === 'Auth';
  };

  public static isDataSourceConnectionError = (
    error: GraphQLParsedError
  ): error is DataSourceConnectionError => {
    return error.key === 'DataSourceConnection';
  };

  public static isValidationError = (error: GraphQLParsedError): error is ValidationError => {
    return error.key === 'Validation';
  };

  public static isDataExtractFetchError = (
    error: GraphQLParsedError
  ): error is DataExtractFetchError => {
    return error.key === 'DataExtractFetch';
  };

  public static isTransformError = (error: GraphQLParsedError): error is TransformError => {
    return error.key === 'Transform';
  };

  public static isComputedFieldError = (error: GraphQLParsedError): error is ComputedFieldError => {
    return error.key === 'ComputedFieldLiquidParseException';
  };

  public static isDataNotReadyError = (error: GraphQLParsedError): error is DataNotReadyError => {
    return error.key === 'DataNotReady';
  };
}
