import gql from 'graphql-tag';
import { DataSourceInstance } from '../DataSource';
import { NodesConnection } from '../Paging';

export interface SubjectDataSourcesQueryData {
  tenant: {
    __typename: 'Tenant';
    id: string;
    dataSourceInstances: NodesConnection<
      SubjectProviderDataSource & {
        // This is null if AireFrame cannot connect to the data
        subjectProvider: SubjectProviderDataSource['subjectProvider'] | null;
      },
      'DataSourceInstance'
    >;
  };
}

export interface SubjectProviderDataSource
  extends Pick<DataSourceInstance, '__typename' | 'id' | 'name' | 'key'> {
  subjectProvider: {
    __typename: 'SubjectProviderType';
    requiresSubjectFieldMapping: boolean;
  };
}

export const SUBJECT_DATA_SOURCES_QUERY_NAME = 'SubjectDataSources';
export const SUBJECT_DATA_SOURCES_QUERY = gql`
  query ${SUBJECT_DATA_SOURCES_QUERY_NAME} {
    tenant {
      id
      dataSourceInstances(capabilities: [SUBJECT_READ]) {
        nodes {
          id
          name
          subjectProvider {
            requiresSubjectFieldMapping
          }
        }
      }
    }
  }
`;
