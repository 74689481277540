import { USER_QUERY, UserQueryData, UserQueryVars } from '@aireframe/graphql';
import { useQuery } from '@apollo/client';

export const useGetUser = (
  id?: string | null,
  options: { withAccessToSubjectId?: string; skip: boolean } = { skip: false }
) => {
  return useQuery<UserQueryData, UserQueryVars>(USER_QUERY, {
    variables: {
      id: id as string,
      withAccessToSubjectId: options.withAccessToSubjectId ?? '',
      includeHasAccessToSubject: !!options.withAccessToSubjectId
    },
    skip: !id || options.skip
  });
};
