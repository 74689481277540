import { ErrorParser, GraphQLParsedError } from '@aireframe/graphql';
import ErrorMessage from '../../../ErrorMessage/ErrorMessage';
import plurazlize from 'pluralize';
import { Grid, Typography } from '@mui/material';
import HourglassIcon from '@mui/icons-material/HourglassBottom';

export const WidgetErrorMessage: React.FC<{ errors: Array<GraphQLParsedError> }> = ({ errors }) => {
  const failedDataExtracts = errors
    .filter(ErrorParser.isDataExtractFetchError)
    .map(e => e.properties.dataExtractKey);

  if (failedDataExtracts.length > 0) {
    return (
      <ErrorMessage
        message={`The data ${plurazlize('set', failedDataExtracts.length)} ${failedDataExtracts.join(', ')} failed to load.`}
      />
    );
  }

  if (errors.some(ErrorParser.isTransformError)) {
    return <ErrorMessage message="The transformation failed - check your configuration." />;
  }

  if (errors.some(ErrorParser.isDataNotReadyError)) {
    return (
      <Grid container justifyContent="center" alignItems="center" sx={{ p: 1 }}>
        <HourglassIcon /> <Typography>Data will be available soon.</Typography>
      </Grid>
    );
  }

  return <ErrorMessage />;
};
