export function removeTypename<T extends object>(obj: T): Omit<T, '__typename'>;
export function removeTypename(obj: null): null;
export function removeTypename(obj: undefined): undefined;
export function removeTypename<T extends object>(
  obj: T | null | undefined
): Omit<T, '__typename'> | null | undefined {
  if (obj === null) return null;
  if (obj === undefined) return undefined;

  const newObject: { [key: string | number]: object | string | number | null | undefined } = {};

  for (const [key, value] of Object.entries(obj)) {
    if (key !== '__typename') {
      if (Array.isArray(value)) {
        newObject[key] = value.map(v => (typeof v === 'object' ? removeTypename(v) : v));
      } else {
        newObject[key] = typeof value === 'object' && value ? removeTypename(value) : value;
      }
    }
  }

  return newObject as Omit<T, '__typename'>;
}
