import {
  IStructureContext,
  ITenant,
  StructuralEntity,
  StructureTree,
  SubTenantStructuralEntity,
  createStructureTree
} from '@aireframe/graphql';
import { debounce } from 'lodash-es';
import React, { useContext, useEffect, useMemo } from 'react';
import { useStructureQuery, useTenantQuery } from './StructureContext.utils';
import { useCurrentUser } from '../Authorisation';

export const StructureContext = React.createContext<IStructureContext | undefined>(undefined);

const DefaultTree: StructureTree = {
  getChildren: () => [],
  getEntities: () => [],
  getEntity: () => undefined,
  walkFromEntity: () => [],
  getParent: () => undefined
};

export const StructureContextProvider: React.FC<{ children?: React.ReactNode }> = ({
  children
}) => {
  const { activeUser } = useCurrentUser();
  const { query: tenantQuery, data: tenantData, loading: tenantLoading } = useTenantQuery();
  const {
    query: structureQuery,
    data: structureData,
    loading: structureLoading,
    fetchMore
  } = useStructureQuery();

  useEffect(() => {
    if (activeUser) {
      tenantQuery();
      structureQuery({
        variables: {
          depth: 1
        }
      });
    }
  }, [tenantQuery, structureQuery, activeUser]);

  const structureTenant = useMemo<(ITenant & { depth: 0 }) | undefined>(
    () => (tenantData ? { ...tenantData, depth: 0 } : undefined),
    [tenantData]
  );

  const structureTree = useMemo(() => {
    if (tenantData) {
      return createStructureTree(
        tenantData,
        structureData?.accessibleStructuralEntities?.nodes ?? []
      );
    }
    return DefaultTree;
  }, [tenantData, structureData]);

  useEffect(() => {
    if (!structureData?.accessibleStructuralEntities) return;

    const nodesByKey = structureData.accessibleStructuralEntities.nodes.reduce(
      (acc, node) => {
        acc[node.key] = node;
        return acc;
      },
      {} as Record<string, SubTenantStructuralEntity>
    );

    // Ensure that the tree is complete
    structureData.accessibleStructuralEntities.nodes.forEach(entity => {
      if (!entity.parentKey || !entity.parent?.parentKey) return;
      if (nodesByKey[entity.parentKey]) return;

      fetchMore({
        variables: {
          parentKey: entity.parent.parentKey,
          depth: 1
        }
      });
    });
  }, [structureData, fetchMore]);

  const debouncedSearch = useMemo(
    () =>
      debounce(
        (parent: Pick<StructuralEntity, 'key'> | undefined, depth?: number, searchTerm?: string) =>
          fetchMore({
            variables: {
              depth: depth,
              searchTerm: searchTerm,
              parentKey: parent?.key
            }
          }),
        500,
        { trailing: true }
      ),
    [fetchMore]
  );

  const fetch = (options?: {
    parent?: Pick<StructuralEntity, 'key'>;
    depth?: number;
    searchTerm?: string;
  }) => {
    if (options?.searchTerm) {
      debouncedSearch(options?.parent, options?.depth, options?.searchTerm);
    } else {
      fetchMore({
        variables: {
          depth: options?.depth,
          searchTerm: options?.searchTerm,
          parentKey: options?.parent?.key
        }
      });
    }
  };

  let value: IStructureContext;
  if (activeUser) {
    value = {
      activeTenant: structureTenant,
      loading: tenantLoading || structureLoading || structureTree === DefaultTree,
      fetch,
      tree: structureTree
    };
  } else {
    value = {
      activeTenant: undefined,
      loading: false,
      fetch: () => {
        return;
      },
      tree: DefaultTree
    };
  }

  return <StructureContext.Provider value={value}>{children}</StructureContext.Provider>;
};

export const useStructureContext = () => {
  const context = useContext(StructureContext);
  if (context) return context;

  throw Error('Structure Context Provider was not registered.');
};
