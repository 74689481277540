import gql from 'graphql-tag';
import { SubTenantStructuralEntity } from '../../Structure';
import {
  CORE_STRUCTURAL_ENTITY_FIELDS,
  CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME
} from '../../Fragments/StructuralEntityFragment';
import { NodesConnection } from '../../Paging';

export interface GetSubjectLocationsQueryVars {
  id: string;
}

export interface GetSubjectLocationsQueryData {
  subject: {
    __typename: 'Subject';
    hasHiddenLocations: boolean;
    id: string;
    locations: NodesConnection<SubTenantStructuralEntity>;
  };
}

export const SUBJECT_LOCATIONS_QUERY_NAME = 'SubjectLocations';
export const SUBJECT_LOCATIONS_QUERY = gql`
  ${CORE_STRUCTURAL_ENTITY_FIELDS}

  query ${SUBJECT_LOCATIONS_QUERY_NAME}($id: ID!) {
    subject(input: { id: $id }) {
      hasHiddenLocations
      id
      locations {
        nodes {
          ...${CORE_STRUCTURAL_ENTITY_FIELDS_FRAGMENT_NAME}
        }
      }
    }
  }
`;
