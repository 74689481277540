import {
  convertDataPointsFilterToInput,
  convertWidgetContextToInput,
  useVisualisationDataChangedSubscription,
  VISUALISATION_DATA_POINT_EDGES_QUERY,
  VisualisationDataPointEdgesQueryData,
  VisualisationDataPointsQueryVars
} from '@aireframe/graphql';
import { useQuery } from '@apollo/client';
import { useEffect, useMemo } from 'react';
import { mapDataPoints } from '../DataVisualisationHelpers';
import { useVisualisationContext } from '../VisualisationContext';
import dayjs from 'dayjs';

export const useTableDataExtract = () => {
  const {
    widgetContext,
    definition,
    connectionQueryVariables,
    setConnectionInfo,
    filter,
    setIsUpdatePending,
    setLastPossibleUpdate
  } = useVisualisationContext();

  const { data, error, loading, refetch } = useQuery<
    VisualisationDataPointEdgesQueryData,
    VisualisationDataPointsQueryVars
  >(VISUALISATION_DATA_POINT_EDGES_QUERY, {
    variables: {
      dataVisualisationId: definition.id,
      getDataPointsInput: {
        context: convertWidgetContextToInput(widgetContext),
        filter: convertDataPointsFilterToInput(filter.toFilterInput()),
        ...connectionQueryVariables
      }
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      setLastPossibleUpdate(dayjs(data.visualisation.dataPoints.lastPossibleUpdate));
      setIsUpdatePending(data.visualisation.dataPoints.updatePending);
    }
  });

  useVisualisationDataChangedSubscription(definition, widgetContext, refetch);

  useEffect(() => {
    if (loading) {
      return;
    } else if (data) {
      const { totalCount, pageInfo } = data.visualisation.dataPoints.dataPoints;
      setConnectionInfo({ pageInfo, totalCount });
    } else {
      setConnectionInfo({
        pageInfo: {
          hasNextPage: false,
          hasPreviousPage: false,
          endCursor: null,
          startCursor: null
        },
        totalCount: 0
      });
    }
  }, [data, loading, setConnectionInfo]);

  const mappedData = useMemo(
    () =>
      data
        ? mapDataPoints(data.visualisation.dataPoints.dataPoints.edges.map(edge => edge.node))
        : undefined,
    [data]
  );

  return { data: mappedData, loading, error };
};
