import gql from 'graphql-tag';
import Subject from '../Subject';
import {
  CUSTOM_FIELD_FRAGMENT,
  CUSTOM_FIELD_FRAGMENT_NAME
} from '../../Fragments/CustomFieldsFragment';

export interface GetSubjectQueryVars {
  input: { id: string } | { externalId: string };
}

export interface GetSubjectQueryData {
  subject: Pick<Subject, 'id' | 'customFieldValues' | 'avatarHash' | '__typename'> | null;
}

export const SUBJECT_QUERY_NAME = 'Subject';
export const SUBJECT_QUERY = gql`
  ${CUSTOM_FIELD_FRAGMENT}

  query ${SUBJECT_QUERY_NAME}($input: GetSubjectInput!) {
    subject(input: $input) {
      id
      customFieldValues {
        value
        customField {
          ...${CUSTOM_FIELD_FRAGMENT_NAME}
        }
      }
      avatarHash
    }
  }
`;
