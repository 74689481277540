import { VisualisationRenderType } from '@aireframe/shared-types';
import { gql, useSubscription } from '@apollo/client';
import { convertWidgetContextToInput, WidgetContext, WidgetContextInput } from '../Widgets';

type VisualisationDataUpdatedSubscriptionVariables = {
  visualisationId: string;
  context: WidgetContextInput;
};

type VisualisationDataUpdatedSubscriptionData = {
  visualisationDataUpdated: {
    visualisationId: string;
  };
};

const VISUALISATION_DATA_CHANGED_SUBSCRIPTION = gql`
  subscription VisualisationDataChanged($visualisationId: ID!, $context: WidgetContextInput!) {
    visualisationDataUpdated(visualisationId: $visualisationId, context: $context) {
      visualisationId
    }
  }
`;

export const useVisualisationDataChangedSubscription = (
  visualisation: VisualisationRenderType,
  context: WidgetContext,
  onUpdate: () => void
) => {
  useSubscription<
    VisualisationDataUpdatedSubscriptionData,
    VisualisationDataUpdatedSubscriptionVariables
  >(VISUALISATION_DATA_CHANGED_SUBSCRIPTION, {
    onData: ({ data: { data } }) => {
      if (data?.visualisationDataUpdated) {
        onUpdate();
      }
    },
    variables: {
      visualisationId: visualisation.id,
      context: convertWidgetContextToInput(context)
    },
    skip: !visualisation.isCacheable
  });
};
